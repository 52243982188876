import React from "react";
import { Router } from "@reach/router";
import JobTemplateEditor from "./editor";
import JobTemplatesOverview from "./overview";
import { useRemoteCollection } from "../../remote-collection/hook";
import useConfigEditorState from "../details/queue-process/state";
import { Helmet } from "react-helmet";

import { useRemoteCollectionMessages } from "../../remote-collection/hook/use-messages";

const JobTemplates = ({ org, env, location }) => {
	const collection = useRemoteCollection({
		endpoint: `/${org}/environments/${env}/templates`,
		itemDescription: "job template"
	});

	useRemoteCollectionMessages({
		...collection,
		location,
		description: "job template",
		nameKey: "id"
	});

	const configEditor = useConfigEditorState({
		org,
		env,
		populateConfigFromProcess: false
	});

	const editorProps = {
		org,
		env,
		...collection,
		configEditor
	};

	return (
		<>
			<Helmet titleTemplate={"%s | Job Templates"} />
			<Router>
				<JobTemplatesOverview path="/" collection={collection} />
				<JobTemplateEditor path="new" {...editorProps} isNew />
				<JobTemplateEditor path=":id" {...editorProps} />
			</Router>
		</>
	);
};

export default JobTemplates;
