import React, { useMemo, useState, useCallback } from "react";
import { List, TextField, Box } from "@material-ui/core";

import { useFetchPlans } from "./api";
import BillingPlan from "./plan";

import EmptyMessage from "../../empty-message";
import FakeSuspense from "../../fake-suspense";

import { debounce } from "lodash";

const BillingPlans = ({ org, noFree, ...props }) => {
	const [promoCode, setPromoCode] = useState("");
	const handlePromoCodeChange = useCallback(code => {
		setPromoCode(code);
	}, []);

	const req = useFetchPlans(org, promoCode);

	const plans = useMemo(
		() =>
			noFree && !promoCode ? req.body?.filter(p => p.price > 0) : req.body,
		[noFree, promoCode, req.body]
	);

	return (
		<>
			<FakeSuspense
				description="billing plans"
				req={req}
				dimOnFetch
				emptyChildren={
					<EmptyMessage>
						{
							<>
								<em>{promoCode}</em> does not appear to be a valid promotional
								code.
							</>
						}
					</EmptyMessage>
				}
			>
				<BillingPlansRoot org={org} {...props} plans={plans} />
			</FakeSuspense>

			<PromoCode value={promoCode} onChange={handlePromoCodeChange} />
		</>
	);
};

const BillingPlansRoot = ({ plans, org, onSelect, to }) => (
	<List>
		{plans.map(p => (
			<BillingPlan key={p.id} org={org} onSelect={onSelect} to={to} plan={p} />
		))}
	</List>
);

const PromoCode = ({ value, onChange }) => {
	const [undebouncedValue, setUndebouncedValue] = useState(value);

	const onChangDebounced = useMemo(() => debounce(onChange, 250), [onChange]);

	const handleChange = useCallback(
		ev => {
			setUndebouncedValue(ev.target.value);
			onChangDebounced(ev.target.value);
		},
		[onChangDebounced]
	);

	return (
		<Box textAlign="right" mt={3} p={2}>
			<TextField
				id="promo-code"
				label="Promotion Code"
				value={undebouncedValue}
				onChange={handleChange}
			/>
		</Box>
	);
};

export default BillingPlans;
