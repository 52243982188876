import React from "react";
import { ListItemIcon } from "@material-ui/core";
import OnlineBadge from "../online-badge";
import { ReactComponent as NodeIcon } from "../assets/node-material-system.svg";

const NodeAvatar = ({ machine, isOnline, shutdownRequestedAt }) => {
	if (!machine) return null;

	return (
		<ListItemIcon>
			<OnlineBadge {...{ isOnline, shutdownRequestedAt }}>
				<NodeIcon style={{ width: "3em", height: "3em" }} />
			</OnlineBadge>
		</ListItemIcon>
	);
};

export default NodeAvatar;
