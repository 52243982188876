import React, { useCallback } from "react";

import {
	Card,
	DialogActions,
	CardContent,
	Button,
	TextField,
	CardHeader
} from "@material-ui/core";

import EnvProductionFlagEditor from "../../../editors/production-flag";
import FetchButton from "../../../../busy-button/fetch-button";

const EnvEditSettings = ({
	environment,
	onChange,
	onCancelEdit,
	onSave,
	envState
}) => {
	const onChangeDescription = useCallback(
		evt => onChange("description", evt.target.value),
		[onChange]
	);
	const onChangeIsProduction = useCallback(
		val => onChange("isProduction", val),
		[onChange]
	);

	return (
		<Card data-testid="env-settings-edit">
			<form
				onSubmit={ev => {
					onSave();
					ev.preventDefault();
				}}
			>
				<CardHeader title={`Edit ${environment.id} settings`} />
				<CardContent>
					<TextField
						id="env-description"
						label="Description"
						value={environment.description}
						onChange={onChangeDescription}
						multiline
						fullWidth
					/>
					<br />
					<br />
					<EnvProductionFlagEditor
						value={environment.isProduction}
						onChange={onChangeIsProduction}
					/>
				</CardContent>
				<DialogActions>
					<Button
						disabled={envState.patchReq.isFetching}
						onClick={onCancelEdit}
					>
						Don't save
					</Button>
					<FetchButton
						type="submit"
						variant="contained"
						color="primary"
						data-testid="save-btn"
						req={envState.patchReq}
						busyChildren={<>Saving…</>}
					>
						Save environment settings
					</FetchButton>
				</DialogActions>
			</form>
		</Card>
	);
};

export default EnvEditSettings;
