import React, { useState, useCallback } from "react";
import {
	FormControlLabel,
	Checkbox,
	FormControl,
	FormHelperText
} from "@material-ui/core";

export const useAllowRestrictedAppsEditor = configEditor => {
	const [value, setValue] = useState(false);
	const handleValueChange = useCallback(
		e => {
			configEditor.setIsDirty(true);
			setValue(e.target.checked);
		},
		[configEditor]
	);

	return {
		value,
		setValue,
		handleValueChange
	};
};
export const AllowRestrictedApps = ({ value, handleValueChange }) => {
	return (
		<FormControl>
			<FormControlLabel
				label="Allow restricted apps"
				control={<Checkbox checked={value} onChange={handleValueChange} />}
			/>
			<FormHelperText>
				Allow this template to be run from JavaScript clients
			</FormHelperText>
		</FormControl>
	);
};
