import { useCallback, useEffect, useState } from "react";

import semver from "semver";
import { find, uniqueId } from "lodash";

import { useFetchJobDetails } from "../../../../job/api";
import { useSuccessTransition } from "../../../../mutation-handler";

// const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

// TODO: deepClone schema,
//  find keys with format: guid
// and transform them to something like
// 	"type": "string",
// 	"pattern": guidPattern,
// 	"message": {
// 		"pattern": "Expected a guid/uuid "
// 	}

const useProcessPicker = ({
	org,
	setIsDirty,
	setValue,
	_monaco,
	instance,
	populateConfigFromProcess
}) => {
	const [selectedProcess, setSelectedProcess] = useState({});

	const [selectedVersion, setSelectedVersion] = useState({});
	useEffect(() => {
		if (selectedProcess && selectedProcess.versions) {
			const version = semver.maxSatisfying(
				selectedProcess.versions.map(v => v.version),
				"*"
			);

			if (version) {
				const selectedVersion = find(selectedProcess.versions, { version });

				if (selectedVersion) {
					setSelectedVersion(selectedVersion);
				}
			}
		}
	}, [selectedProcess]);
	const jobDeetsReq = useFetchJobDetails(org, selectedVersion?.id);

	const justFetchedJobDeets = useSuccessTransition(jobDeetsReq);
	useEffect(() => {
		if (justFetchedJobDeets && populateConfigFromProcess && _monaco) {
			const value = JSON.stringify(jobDeetsReq.body.defaultConfig, null, 2);

			setIsDirty(false);
			setValue(value);

			if (jobDeetsReq.body.schema) {
				// TODO: figure out how to use getModel to reuse the model.
				// Attempts thus far have not been fruitful
				let modelUri = _monaco.Uri.parse(
					`a://b/${selectedVersion?.id}-${uniqueId()}`
				);

				let model = _monaco.editor.createModel(value, "json", modelUri);

				_monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
					validate: true,
					schemas: [
						{
							uri: `http://foo/${selectedVersion?.id}.json`, // id of the first schema
							fileMatch: [modelUri.toString()], // associate with our model
							schema: jobDeetsReq.body.schema
						}
					]
				});

				instance.setModel(model);
			} else {
				_monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
					validate: true,
					schemas: []
				});
			}
		}
	}, [
		populateConfigFromProcess,
		_monaco,
		instance,
		jobDeetsReq.body,
		justFetchedJobDeets,
		selectedVersion,
		setIsDirty,
		setValue
	]);

	const onProcessPickerChange = useCallback((_evt, newValue) => {
		setSelectedProcess(newValue);
	}, []);

	const onVersionPickerChange = useCallback((_evt, newValue) => {
		setSelectedVersion(newValue);
	}, []);

	return {
		selectedProcess,
		setSelectedProcess,
		selectedVersion,
		setSelectedVersion,
		onProcessPickerChange,
		onVersionPickerChange
	};
};

export default useProcessPicker;
