import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { AllowRestrictedApps } from "./restricted-apps";
import SlugEditor from "../../../slug/editor";
import VersionPicker from "../../../job/picker/version";
import ProcessPicker from "../../../job/picker";
import ScheduleEditor from "./schedule";

export const JobTemplateEditorToolbar = ({
	id,
	setId,
	isNew,
	org,
	configEditor,
	requests,
	restrictedAppsState,
	scheduleEditor
}) => {
	const isFetching = requests.getItem?.isFetching;
	const idEditor = (
		<SlugEditor
			disabled={isFetching}
			value={id}
			onChange={setId}
			fullWidth
			margin="dense"
		/>
	);
	return (
		<Grid container spacing={1}>
			<Grid container item md spacing={1}>
				<Grid item xs={12} sm>
					<AllowRestrictedApps disabled={isFetching} {...restrictedAppsState} />
				</Grid>
				<Grid item xs={12} sm>
					<ScheduleEditor disabled={isFetching} {...scheduleEditor} />
				</Grid>
			</Grid>
			<Grid container item md spacing={1}>
				<Grid item xs={12} sm>
					{idEditor}
				</Grid>
				<Grid item xs={12} sm>
					{isNew ? (
						<ProcessPicker
							org={org}
							value={configEditor.selectedProcess}
							onChange={configEditor.onProcessPickerChange}
						/>
					) : (
						<TextField
							disabled
							value={requests.getItem.body?.job?.type}
							label="Job"
							variant="outlined"
							fullWidth
						/>
					)}
				</Grid>
				<Grid item xs={12} sm>
					{isNew ? (
						<VersionPicker
							process={configEditor.selectedProcess}
							value={
								isNew
									? configEditor.selectedVersion
									: requests.getItem.body?.job?.version
							}
							onChange={configEditor.onVersionPickerChange}
						/>
					) : (
						<TextField
							disabled
							value={requests.getItem.body?.job?.version}
							label="Version"
							variant="outlined"
							fullWidth
						/>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};
