import { useCallback, useState, useEffect, useRef } from "react";

export const useEditorUiState = () => {
	const [fabExited, setFabExited] = useState(false);

	const onFabExited = useCallback(() => {
		setFabExited(true);
	}, []);

	const [fabContainerEntered, setFabContainerEntered] = useState(true);
	const onFabContainerEntered = useCallback(() => {
		setFabContainerEntered(true);
	}, []);

	const onFabContainerExited = useCallback(() => {
		setFabExited(false);
		setFabContainerEntered(false);
	}, []);

	const newAppField = useRef();

	const [entered, setEntered] = useState(false);
	const onEntered = useCallback(() => {
		setEntered(true);
	}, []);

	const onExit = useCallback(() => {
		setEntered(false);
	}, []);

	useEffect(() => {
		if (entered) {
			newAppField.current?.focus?.();
		}
	}, [entered, newAppField]);

	return {
		fabExited,
		onFabExited,
		fabContainerEntered,
		onFabContainerEntered,
		onFabContainerExited,
		newAppField,
		onEntered,
		onExit
	};
};
