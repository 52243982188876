import React from "react";
import { Card, CardHeader, Fab, Box, Tooltip } from "@material-ui/core";
import JobTemplateListItem from "../list-item";
import { Helmet } from "react-helmet";
import { Link } from "@runly/gatsby-theme";
import { Add as AddIcon } from "@material-ui/icons";
import RemoteCollectionList from "../../../remote-collection/list";

const JobTemplatesOverview = ({ org, env, collection }) => {
	return (
		<>
			<Helmet>
				<title>Overview</title>
			</Helmet>
			<Card style={{ overflow: "visible" }}>
				<CardHeader
					title="Job Templates"
					action={
						<Box mt={-3} mr={1}>
							<Tooltip title="Add template">
								<Fab
									color="primary"
									component={Link}
									to={`/dashboard/${org}/env/${env}/templates/new`}
								>
									<AddIcon />
								</Fab>
							</Tooltip>
						</Box>
					}
				/>

				<RemoteCollectionList
					collection={collection}
					ItemComponent={JobTemplateListItem}
					itemProps={{ org, env }}
				/>
			</Card>
		</>
	);
};
export default JobTemplatesOverview;
