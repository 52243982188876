import React, { useState, useCallback, useMemo } from "react";
import {
	Card,
	CardHeader,
	Checkbox,
	FormControlLabel
} from "@material-ui/core";
import { uniqBy } from "lodash";

import { LoadingIndicator } from "@runly/gatsby-theme";

import NodeList from "../../node/list";
import { FadeIn } from "../../skeleton/fade-in";
import { SkeletonList } from "../../skeleton/list";
import { SkeletonCardHeader } from "../../skeleton/card";

const ClusterNodes = props => {
	const [showAllSessions, setShowAllSessions] = useState(false);
	const handleChange = useCallback(
		evt => setShowAllSessions(evt.target.checked),
		[]
	);

	return (
		<Card>
			<CardHeader
				title="Nodes"
				action={
					<FormControlLabel
						label="Show all sessions"
						control={
							<Checkbox checked={showAllSessions} onChange={handleChange} />
						}
					/>
				}
			/>

			<ClusterNodeContent showAllSessions={showAllSessions} {...props} />
		</Card>
	);
};

const ClusterNodeContent = ({ showAllSessions, org, nodes: _nodes }) => {
	const nodes = useMemo(
		() =>
			_nodes
				? showAllSessions
					? _nodes
					: uniqBy(_nodes, n => `${n.machine}-${n.runningAs}`)
				: [],
		[_nodes, showAllSessions]
	);

	if (!_nodes) {
		return <LoadingIndicator />;
	}

	return <NodeList {...{ org, nodes }} />;
};

export const NodeListPlaceholder = () => (
	<FadeIn>
		<Card>
			<SkeletonCardHeader subheader={false} />
			<SkeletonList cols={3} />
		</Card>
	</FadeIn>
);

export default ClusterNodes;
