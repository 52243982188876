import React from "react";
import UserProfile from "../../../user-profile";
// Node failed to execute job

const PendingEvent = ({ itemId }) => <>{itemId}</>;

const EnqueuedEvent = ({ user }) => (
	<>
		Queued by <UserProfile variant="Link" userId={user} />
	</>
);

const AssignedEvent = ({ user }) => <>Assigned to {user}</>;

const AcquiredEvent = () => <>Node acquired run</>;

const ApplicationStartedEvent = ({ user, pid }) => (
	<>
		Node executed job as {user} (PID {pid})
	</>
);

const ProcessStartedEvent = () => <>Job started</>;

const ApplicationFailedEvent = ({ applicationFailDetails }) => (
	<>Node failed to execute job: {applicationFailDetails}</>
);

const ApplicationExitedEvent = ({ exitCode }) => (
	<>Node confirmed job exited with code {exitCode}</>
);

const CancellationRequestedEvent = ({ user }) => (
	<>
		Cancellation requested by <UserProfile variant="Link" userId={user} />
	</>
);

const MethodEvent = ({ itemId, isSuccessful }) => (
	<>
		<code>{itemId}</code> {isSuccessful ? <>ran</> : <>failed to run</>}
	</>
);

const ResultEvent = ({ itemId }) => (
	<>
		<code>{itemId}</code> failed
	</>
);

const completedDescriptions = {
	TimedOut: "timed out",
	Successful: "completed successfully",
	Failed: "failed",
	Cancelled: "cancelled"
};

const CompletedEvent = ({ disposition }) => (
	<>Job {completedDescriptions[disposition]}</>
);

export const eventDescriptions = {
	Pending: PendingEvent,
	Queued: EnqueuedEvent,
	Assigned: AssignedEvent,
	Acquired: AcquiredEvent,
	Executed: ApplicationStartedEvent,
	Started: ProcessStartedEvent,
	Method: MethodEvent,
	Result: ResultEvent,
	Error: ApplicationFailedEvent,
	Exited: ApplicationExitedEvent,
	Cancelled: CancellationRequestedEvent,
	Completed: CompletedEvent
};
