import React, { useMemo, useState, useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";

import {
	Card,
	CardHeader,
	DialogActions,
	CardContent,
	TextField
} from "@material-ui/core";

import { Button } from "@runly/gatsby-theme";

import useMutationHandler from "../../mutation-handler";
import FetchButton from "../../busy-button/fetch-button";

import { useUpdateOrgAccount } from "../api";

import { find } from "lodash";

const ChangeOrgEmail = ({ org, orgs, onChange }) => {
	org = useMemo(() => find(orgs, { id: org }), [org, orgs]);

	const [email, setEmail] = useState(org.email);
	const req = useUpdateOrgAccount(org.id);
	const save = req.fetch;

	const handleEmailChange = useCallback(ev => setEmail(ev.target.value), []);

	const handleSubmit = useCallback(
		ev => {
			ev.preventDefault();
			save({ email });
		},
		[email, save]
	);

	useEffect(() => {
		if (req.isFetched) {
			onChange(org.id, email);
		}
	}, [email, onChange, org.id, req.isFetched]);

	useMutationHandler({
		req,
		successRedirectUrl: "/dashboard/account",
		successNotification: {
			messageId: `${org.id}-email-change`,
			text: `Updated email address for ${org.name}`
		},
		errorNotification: { verb: "saving", description: "email address" }
	});

	return (
		<>
			<Helmet>
				<title>{org.name} Notification Settings</title>
			</Helmet>

			<Card>
				<CardHeader
					title={`${org.name} Notification Settings`}
					subheader={`Choose the email address where you would like to receive notifications for ${org.name}.`}
				/>

				<form onSubmit={handleSubmit}>
					<CardContent>
						<TextField
							id="org-email"
							label="Email Address"
							type="email"
							required
							value={email}
							onChange={handleEmailChange}
						/>
					</CardContent>
					<DialogActions>
						<Button to="/dashboard/account">Don't change</Button>
						<FetchButton
							type="submit"
							variant="contained"
							color="primary"
							req={req}
							busyChildren={<>Saving…</>}
						>
							Change Email
						</FetchButton>
					</DialogActions>
				</form>
			</Card>
		</>
	);
};

export default ChangeOrgEmail;
