import React from "react";
import DocLink from "./doc-link";

export const Role = () => (
	<>
		A person's role determines their permissions within the organization.
		<br />
		<DocLink href="/roles/">Learn more about roles and permissions</DocLink>.
	</>
);

export const EnvClustersTitle = ({ env }) => <>Deploy {env}</>;

export const EnvClustersDescription = ({ env }) => (
	<>
		Select which clusters {env} should be deployed to. Nodes connected to the
		selected clusters will run jobs for the environment.{" "}
		<DocLink href="/nodes-clusters/">
			Learn more about deploying environments
		</DocLink>
	</>
);

export const ClustersEnvTitle = ({ cluster }) => (
	<>Assign Environments to {cluster}</>
);

export const ClustersEnvDescription = ({ cluster }) => (
	<>
		Determine which environments the {cluster} cluster will host. A node
		connects to a cluster which is associated with one or more environments.{" "}
		<DocLink href="/nodes-clusters/">Learn more</DocLink>
	</>
);
