import React from "react";
import {
	TextField,
	Button,
	Collapse,
	Fab,
	Grow,
	Card,
	CardContent,
	DialogActions,
	Box,
	Tooltip
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";

import FetchButton from "../../../busy-button/fetch-button";

import { useEditorUiState } from "./ui-state";

export const NewAppEditor = ({ newApp, postItem }) => {
	const {
		isEditing,
		toggleIsEditing,
		handleSubmit,
		handleNameChange,
		name
	} = newApp;

	const {
		fabExited,
		onFabExited,
		fabContainerEntered,
		onFabContainerEntered,
		onFabContainerExited,
		newAppField,
		onEntered,
		onExit
	} = useEditorUiState();

	return (
		<>
			<Collapse
				in={!isEditing ? !fabExited : false}
				onEntered={onFabContainerEntered}
				onExited={onFabContainerExited}
			>
				<Box mb={2} display="flex" justifyContent="flex-end">
					<Grow
						in={!isEditing ? fabContainerEntered : false}
						onExited={onFabExited}
					>
						<Tooltip title="Add new app">
							<Fab color="primary" onClick={toggleIsEditing}>
								<AddIcon />
							</Fab>
						</Tooltip>
					</Grow>
				</Box>
			</Collapse>
			<Collapse in={isEditing} onEntered={onEntered} onExit={onExit}>
				<Box clone mb={2}>
					<form onSubmit={handleSubmit}>
						<Card elevation={8}>
							<CardContent>
								<TextField
									label="App name"
									onChange={handleNameChange}
									fullWidth
									inputProps={{ ref: newAppField }}
									disabled={postItem.isFetching}
								/>
							</CardContent>
							<DialogActions>
								<Button onClick={toggleIsEditing}>Don't create</Button>
								<FetchButton
									disabled={name?.trim?.() ? undefined : true}
									req={postItem}
									variant="contained"
									color="primary"
									type="submit"
								>
									Create App
								</FetchButton>
							</DialogActions>
						</Card>
					</form>
				</Box>
			</Collapse>
		</>
	);
};
