import React, { useCallback } from "react";
import { Helmet } from "react-helmet";
import { Router } from "@reach/router";

import { Box, Card, CardHeader } from "@material-ui/core";

import FakeSuspense from "../../fake-suspense";

import Layout from "../../layout";

import useNewEnvironmentState from "./state";
import { useSaveNewEnvironment } from "../api";

import { useFetchOrgUsage } from "../../org/api";
import UpgradeCard from "../../billing/upgrade-card";

import useMutationHandler from "../../mutation-handler";

import Wizard from "../../wizard";

import NameStep from "./name";
import AccessStep from "./access";
import ClustersStep from "./clusters";

const TITLE = "Create New Environment";
const STEPS = [
	{ label: "Choose ID", slug: "/" },
	{ label: "Set Access", slug: "access" },
	{ label: "Assign Clusters", slug: "clusters" }
];

const NewEnvironmentPage = ({ org, location }) => {
	const usageReq = useFetchOrgUsage(org);

	return (
		<Layout org={org} location={location} navTabs={false} maxWidth="md">
			<Helmet>
				<title>{TITLE}</title>
			</Helmet>

			<FakeSuspense req={usageReq} description="Usage">
				<NewEnvRoot org={org} usage={usageReq.body} />
			</FakeSuspense>
		</Layout>
	);
};

const NewEnvRoot = ({
	org,
	usage: {
		environment: { limit, current }
	}
}) => {
	if (Number.isInteger(limit) && current >= limit) {
		return <UpgradeCard org={org} entity="environment" limit={limit} />;
	}

	return <NewEnvForm org={org} />;
};

const NewEnvForm = ({ org }) => {
	const {
		state,
		onChangeId,
		onChangeDescription,
		onChangeIsProduction,
		onToggleCluster
	} = useNewEnvironmentState();

	const { fetch: saveEnv, ...envPatchReq } = useSaveNewEnvironment(org, state);

	const onSave = useCallback(() => saveEnv(), [saveEnv]);

	const newEnvId = envPatchReq?.body?.id;
	const linktoNewEnv = `/dashboard/${org}/env/${newEnvId}`;

	useMutationHandler({
		req: envPatchReq,
		successRedirectUrl: `/dashboard/${org}`,
		successNotification: {
			messageId: newEnvId,
			text: `Created environment ${newEnvId}.`,
			actionLink: linktoNewEnv,
			actionText: "View environment"
		}
	});

	return (
		<>
			<Box mb={2}>
				<Card>
					<CardHeader
						title={TITLE}
						subheader="Three easy steps to segment your infrastructure & job configuration."
					/>
				</Card>
			</Box>

			<Box>
				<Card>
					<Router>
						<Wizard path="/" steps={STEPS}>
							<NameStep
								path="/"
								{...{ state, onChangeId, onChangeDescription }}
							/>
							<AccessStep path="access" {...{ state, onChangeIsProduction }} />
							<ClustersStep
								path="clusters"
								{...{ state, onToggleCluster, envPatchReq, onSave }}
							/>
						</Wizard>
					</Router>
				</Card>
			</Box>
		</>
	);
};

export default NewEnvironmentPage;
