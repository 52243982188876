import React from "react";
import {
	ListItem,
	ListItemText,
	ListItemSecondaryAction
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const DangerItem = ({ title, children, action }) => (
	<ListItem disableGutters classes={useItemStyles()}>
		<ListItemText
			primary={title}
			secondary={children}
			classes={useTextStyles()}
		/>
		<ListItemSecondaryAction classes={useActionStyles()}>
			{action}
		</ListItemSecondaryAction>
	</ListItem>
);

const useTextStyles = makeStyles(theme => ({
	primary: theme.typography.subtitle1
}));

const useItemStyles = makeStyles(theme => ({
	container: {
		[theme.breakpoints.up("sm")]: {
			display: "flex"
		}
	},
	root: {
		display: "block"
	},
	secondaryAction: {
		paddingRight: theme.spacing(2)
	}
}));

const useActionStyles = makeStyles(theme => ({
	root: {
		whiteSpace: "nowrap",
		flex: "0 1 auto",
		position: "static",
		transform: "none",
		[theme.breakpoints.up("sm")]: { marginTop: 10, marginBottom: 10 }
	}
}));

export default DangerItem;
