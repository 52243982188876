import React from "react";
import { List } from "@material-ui/core";
import DeepNavTab from "./tab";

const DeepNav = ({ tabs = [], location }) => {
	return (
		<List disablePadding dense={false}>
			{tabs.map((t, idx) => (
				<DeepNavTab
					key={t.href}
					{...t}
					isActive={
						idx === 0
							? location.pathname === t.href
							: location.pathname.startsWith(t.href)
					}
					location={location}
				/>
			))}
		</List>
	);
};

export default DeepNav;
