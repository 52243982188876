import React from "react";
import { Typography, Box } from "@material-ui/core";

import { format } from "date-fns";
import { Flipped } from "react-flip-toolkit";
import PaymentIcon from "./icon";

const getMaskChars = (groups, groupSize, char = "\u25CF") =>
	new Array(groups).fill(new Array(groupSize).fill(char).join("")).join(" ");

const ccPlaceholder = getMaskChars(3, 4);

const CreditCard = ({
	cardType,
	brand,
	last4,
	expMonth,
	exp_month,
	expYear,
	exp_year,
	typographyProps
}) => {
	return (
		<>
			<Flipped flipId="billing-card-summary">
				<div>
					<Box display="flex">
						<Box pr={2}>
							<Typography
								variant="h3"
								component="div"
								style={{ display: "flex" }}
							>
								<PaymentIcon cardType={cardType || brand} />
							</Typography>
						</Box>
						<Box>
							<Typography {...typographyProps}>
								<span
									style={{
										fontFamily: "'Courier', monospace",
										whiteSpace: "nowrap"
									}}
								>
									<Typography
										color="textSecondary"
										component="span"
										{...typographyProps}
									>
										{ccPlaceholder}
									</Typography>{" "}
									{last4}
								</span>
							</Typography>{" "}
							<Typography variant="caption" color="textSecondary">
								Expires{" "}
								{format(
									new Date(expYear || exp_year, (expMonth || exp_month) - 1, 1),
									"MMMM yyyy"
								)}
							</Typography>
						</Box>
					</Box>
				</div>
			</Flipped>
		</>
	);
};

export default CreditCard;
