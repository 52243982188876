import React, { cloneElement } from "react";

import {
	Grid,
	Box,
	Typography,
	Paper,
	Card,
	CardHeader
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Skeleton } from "@material-ui/lab";
import { SkeletonListItem } from "../../skeleton/list";
import { FadeIn } from "../../skeleton/fade-in";

import { ProgressText } from "@runly/ui";

import RunProgressIcon from "../progress/icon";
import RunProgressBar from "../progress/bar";

import NodeCard from "../../node/card";
import JobCard from "../../job/card";

import Duration from "../../time/duration";
import RunItemMenu from "../menu";

const RunHeader = ({ org, env, location, canceler, run }) => (
	<Box component="header" mb={4}>
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Box display="flex" alignItems="center" pb={1}>
					{run ? (
						<Typography variant="h3" component="h1">
							{run.job.name}
						</Typography>
					) : (
						<Skeleton width="10em" height={48} />
					)}

					<div style={{ marginLeft: "auto" }}>
						{run && <RunItemMenu {...{ canceler, org, env, run, location }} />}
					</div>
				</Box>

				{run && !run.completedAt ? (
					<RunProgressBar
						to={`/dashboard/${org}/env/${env}/runs/${run?.id}/results`}
						total={run.progress?.total}
						success={run.progress?.success}
						failed={run.progress?.failed}
					/>
				) : null}
			</Grid>

			<HeaderCard>
				{run ? (
					<Card>
						<CardHeader
							title={run.state}
							subheader={
								<>
									{run.startedAt ? (
										<>
											<Duration
												inWords={false}
												start={run.startedAt}
												end={run.completedAt}
											/>{" "}
											-{" "}
										</>
									) : null}
									<ProgressText progress={run.progress} />
								</>
							}
							avatar={
								<RunProgressIcon
									completedAt={run.completedAt}
									{...run.progress}
									state={run.state}
								/>
							}
						/>
					</Card>
				) : (
					<CardSkeleton />
				)}
			</HeaderCard>

			<HeaderCard>
				{run ? <NodeCard org={org} node={run.node} /> : <CardSkeleton />}
			</HeaderCard>

			<HeaderCard>
				{run ? <JobCard job={run.job} /> : <CardSkeleton />}
			</HeaderCard>
		</Grid>
	</Box>
);

const HeaderCard = ({ children }) => {
	const classes = useHeaderCardStyles();

	return (
		<Grid item xs={12} md={4} className={classes.sameHeightGrid}>
			{cloneElement(children, { className: classes.sameHeightCard })}
		</Grid>
	);
};

const useHeaderCardStyles = makeStyles({
	sameHeightGrid: {
		display: "flex"
	},
	sameHeightCard: {
		width: "100%"
	}
});

const CardSkeleton = ({ py = 1.5, ...props }) => (
	<FadeIn>
		<Box clone mb={2} py={py}>
			<Paper {...props}>
				<SkeletonListItem />
			</Paper>
		</Box>
	</FadeIn>
);

export default RunHeader;
