import { useEffect } from "react";
import {
	useSuccessTransition,
	useInitRequestTransition
} from "../../mutation-handler";
import { COLLECTION_ACTIONS } from "./actions";

export const useSyncCollection = ({
	dispatch,
	selections,
	requests: { getCollection, postItem, patchItem, deleteItem }
}) => {
	useSyncReducerToGetCollectionReq({ getCollection, dispatch });

	useSyncReducerToPostItemReq({ postItem, dispatch });

	useSyncReducerToPatchItemReq({
		id: selections.getItem,
		patchItem,
		dispatch
	});

	useSyncReducerToDeleteItemReq({
		id: selections.deleteItem,
		deleteItem,
		dispatch
	});
};

const useSyncReducerToGetCollectionReq = ({ getCollection = {}, dispatch }) => {
	const isJustFetching = useInitRequestTransition(getCollection);
	const isJustFetched = useSuccessTransition(getCollection);

	useEffect(() => {
		if (isJustFetching) {
			dispatch({ type: COLLECTION_ACTIONS.FETCHING });
		}
	}, [dispatch, isJustFetching]);

	useEffect(() => {
		if (isJustFetched) {
			dispatch({ type: COLLECTION_ACTIONS.FETCHED, payload: getCollection });
		}
	}, [dispatch, getCollection, isJustFetched]);
};

const useSyncReducerToPostItemReq = ({ postItem, dispatch }) => {
	const isJustPosted = useSuccessTransition(postItem);

	useEffect(() => {
		if (isJustPosted) {
			dispatch({
				type: COLLECTION_ACTIONS.REMOTE_CREATED,
				payload: postItem.body
			});
		}
	}, [dispatch, isJustPosted, postItem.body]);
};

const useSyncReducerToPatchItemReq = ({ id, patchItem, dispatch }) => {
	const isJustPatched = useSuccessTransition(patchItem);

	useEffect(() => {
		if (isJustPatched) {
			dispatch({
				type: COLLECTION_ACTIONS.REMOTE_PATCHED,
				payload: { oldId: id, ...patchItem.body }
			});
		}
	}, [dispatch, id, isJustPatched, patchItem.body]);
};

const useSyncReducerToDeleteItemReq = ({ deleteItem = {}, id, dispatch }) => {
	const isJustDeleting = useInitRequestTransition(deleteItem);
	const isJustDeleted = useSuccessTransition(deleteItem);

	useEffect(() => {
		if (isJustDeleting) {
			dispatch({
				type: COLLECTION_ACTIONS.REMOTE_ATTEMPT_DELETE,
				payload: { id }
			});
		}
	}, [dispatch, id, isJustDeleting]);

	useEffect(() => {
		if (isJustDeleted) {
			dispatch({
				type: COLLECTION_ACTIONS.REMOTE_DELETED,
				payload: { id }
			});
		}
	}, [dispatch, id, isJustDeleted]);
};
