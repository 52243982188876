import React from "react";

import { Card, CardHeader } from "@material-ui/core";

import AddFab from "../../add-fab";
import FakeSuspense from "../../fake-suspense";

import { useFetchOrgMembers } from "../../member/api";
import MemberList from "../../member/list";
import { useOrg } from "../context";
import { SkeletonList } from "../../skeleton/list";
import { FadeIn } from "../../skeleton/fade-in";

const OrgMembersPage = ({ org }) => {
	const membersRequest = useFetchOrgMembers(org);
	const theOrg = useOrg(org);

	return (
		<Card>
			<CardHeader
				title={`${theOrg?.name || org} Members`}
				action={<AddFab title="Add Member" to={`/dashboard/${org}/invite`} />}
			/>

			<FakeSuspense
				req={membersRequest}
				placeholder={
					<FadeIn>
						<SkeletonList size={5} cols={2} secondaryAction />
					</FadeIn>
				}
				description={<>{org} members</>}
			>
				<MemberList members={membersRequest.body} org={org} />
			</FakeSuspense>
		</Card>
	);
};

export default OrgMembersPage;
