import React, { useCallback } from "react";
import { Typography, Box } from "@material-ui/core";

import UserProfile from "..";
import MemberMenu from "../../member/menu";
import RemoveMemberDialog, {
	useRemoveDialogState
} from "../../member/remove-dialog";
import { navigate } from "@reach/router";
import UserInfo from "../info";

const UserDetailsRoot = ({ org, ...props }) => {
	const member = props.body;
	const { memberToRemove, onRemoveClick, dialog } = useRemoveDialogState();

	const handleRemoveClick = useCallback(() => onRemoveClick(member), [
		member,
		onRemoveClick
	]);

	const handleRemoval = useCallback(() => {
		navigate(`/dashboard/${org}/settings/members`);
	}, [org]);

	return (
		<>
			<Box display="flex" px={2} py={2}>
				<UserProfile variant="Avatar" {...props} {...props.body} />
				<Box ml={2}>
					<Typography variant="h5" component="h1">
						{props.isSystem ? "System" : props.body.name}{" "}
						{props.body.nickname ? (
							<Typography variant="h5" color="textSecondary" component="span">
								({props.body.nickname})
							</Typography>
						) : null}
					</Typography>

					<Typography color="textSecondary" variant="h6">
						{props.body.role}
					</Typography>
				</Box>
				<div style={{ marginLeft: "auto" }}>
					<MemberMenu {...{ org, member, handleRemoveClick }} />
				</div>
			</Box>
			<UserInfo org={org} {...member} horizontal />
			<RemoveMemberDialog
				{...{ org, memberToRemove, dialog }}
				onSuccess={handleRemoval}
			/>
		</>
	);
};

export default UserDetailsRoot;
