import React from "react";
import { useLocation } from "@reach/router";

import { Card, CardHeader } from "@material-ui/core";

import NodeAvatar from "./avatar";
import RefableLink from "../refable-link";

const NodeCard = ({ org, node, ...props }) => {
	const location = useLocation();

	return (
		<Card {...props}>
			<CardHeader
				title={node ? node.machine : "Not yet assigned to node"}
				subheader={
					node ? (
						<RefableLink
							to={`/dashboard/${org}/clusters/${node.cluster}`}
							state={{
								...location.state,
								clusterReturn: { url: location.pathname, label: "Run Details" }
							}}
							color="inherit"
						>
							{node.cluster} cluster
						</RefableLink>
					) : null
				}
				avatar={<NodeAvatar {...node} />}
			/>
		</Card>
	);
};

export default NodeCard;
