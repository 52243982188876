import React, { useMemo } from "react";
import { Link } from "@runly/gatsby-theme";

import Layout from "../../layout";
import NavTabs from "../../layout/nav-tabs";

import { useCanEditEnv } from "../../org/permissions";
import RunsFilter from "../../run/history/filter";

const useDefaultNavTabs = (org, env, location) => {
	const canEditEnv = useCanEditEnv(org);
	const runSearch = location?.state?.referrerSearch || "";

	return useMemo(() => {
		const tabs = [
			{ href: `/dashboard/${org}/env/${env}`, label: "Overview" },
			{
				href: `/dashboard/${org}/env/${env}/runs${runSearch}`,
				label: "History"
			},
			{ href: `/dashboard/${org}/env/${env}/templates`, label: "Templates" }
		];

		if (canEditEnv) {
			tabs.push({
				href: `/dashboard/${org}/env/${env}/settings`,
				label: "Settings"
			});
		}

		return tabs;
	}, [canEditEnv, env, org, runSearch]);
};

const EnvironmentLayout = ({
	org,
	env,
	location,
	children,
	navTabs,
	...rest
}) => {
	const tabs = useDefaultNavTabs(org, env, location);
	navTabs = navTabs || <NavTabs tabs={tabs} location={location} />;

	return (
		<Layout
			org={org}
			location={location}
			navTabs={navTabs}
			breadCrumb={
				<Link to={`/dashboard/${org}/env/${env}`} color="inherit">
					{env}
				</Link>
			}
			{...rest}
			toolbar={
				location.pathname === `/dashboard/${org}/env/${env}/runs` ? (
					<RunsFilter org={org} location={location} />
				) : null
			}
		>
			{children}
		</Layout>
	);
};

export default EnvironmentLayout;
