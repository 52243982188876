import React from "react";
import {
	CardHeader,
	Grid,
	Box,
	DialogActions,
	Button
} from "@material-ui/core";
import FetchButton from "../../../busy-button/fetch-button";
import ProcessConfigEditor from "./editor";
import ConfigEditorToolbar from "./toolbar";
import Layout from "../../../layout";
import RefableLink from "../../../refable-link";
import useMutationHandler from "../../../mutation-handler";
import { Helmet } from "react-helmet";

const QueueProcessDialog = ({ org, env, location, runId, configEditor }) => {
	const redirectToNew = location.state?.redirectToNew;
	const { queueReq, selectedProcess } = configEditor;

	const newLink = `/dashboard/${org}/env/${env}/runs/${queueReq?.body?.id}`;
	const newProcName = selectedProcess?.name || "job";
	const queuedProcName = queueReq?.body?.process?.name;

	useMutationHandler({
		req: queueReq,
		successRedirectUrl: redirectToNew
			? newLink
			: location.state?.returnUrl || `/dashboard/${org}/env/${env}`,
		successNotification: queuedProcName
			? {
					severity: "info",
					title: "Job queued",
					text: `Queued ${queuedProcName} in ${env}.`,
					actionLink: redirectToNew ? null : newLink,
					actionText: redirectToNew ? null : "View details"
			  }
			: null
	});

	return (
		<Layout
			org={org}
			location={location}
			navTabs={false}
			toolbar={
				<CardHeader
					title={
						<Grid container spacing={3}>
							<Grid item sm={4}>
								<Box pt={1}>{runId ? "Requeue" : "Configure"} Job</Box>
							</Grid>
							<Grid item sm={8} container spacing={3}>
								{runId ? null : (
									<ConfigEditorToolbar {...{ org, configEditor }} />
								)}
							</Grid>
						</Grid>
					}
				/>
			}
		>
			<Helmet titleTemplate={`%s in ${env} | Runly`}>
				<title>
					{runId ? `Requeue ${newProcName}` : `Queue ${newProcName}`}
				</title>
			</Helmet>
			<ProcessConfigEditor {...configEditor} />
			<DialogActions>
				<Button
					size="large"
					component={RefableLink}
					underline="none"
					to={location.state?.returnUrl || `/dashboard/${org}/env/${env}`}
					state={location.state}
				>
					Don't Queue
				</Button>
				<FetchButton
					disabled={
						!configEditor?.value?.trim?.() || configEditor.hasValidationErrors
					}
					size="large"
					onClick={configEditor.handleDoQueue}
					color="primary"
					variant="contained"
					req={configEditor.queueReq}
					busyChildren={<>Queuing Job…</>}
				>
					{runId ? <>Requeue</> : <>Queue Job</>}
				</FetchButton>
			</DialogActions>
		</Layout>
	);
};

export default QueueProcessDialog;
