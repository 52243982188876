import React from "react";
import { formatMoney } from "accounting";
import { Box, Typography } from "@material-ui/core";

const FormatPrice = ({
	price,
	period,
	bigVariant = "h4",
	smallVariant = "h6"
}) => {
	return price === 0 ? (
		<Typography variant={bigVariant} component="strong">
			Free
		</Typography>
	) : (
		<Box display="inline-flex">
			<Typography variant={bigVariant} component="strong">
				{formatMoney(price, "$", 0)}
			</Typography>
			{period ? (
				<>
					<Typography variant={bigVariant} component="strong">
						/
					</Typography>
					<Typography
						variant={smallVariant}
						component="strong"
						style={{ alignSelf: "flex-end" }}
					>
						{period}
					</Typography>
				</>
			) : null}
		</Box>
	);
};
export default FormatPrice;
