import React from "react";

import { DialogActions, CardContent, Typography } from "@material-ui/core";
import { ArrowRightAlt as ArrowRightIcon } from "@material-ui/icons";
import { Button } from "@runly/gatsby-theme";

import EnvProductionFlagEditor from "../editors/production-flag";

const AccessStep = ({ org, state, onChangeIsProduction }) => {
	return (
		<>
			<CardContent>
				<Typography variant="h5" component="h2">
					Who should be able to make changes to this environment?
				</Typography>
				<Typography paragraph>
					Developers will not be able to modify or queue runs on production
					environments.
				</Typography>

				<EnvProductionFlagEditor
					value={state.isProduction}
					onChange={onChangeIsProduction}
				/>
			</CardContent>

			<DialogActions>
				<Button to={`/dashboard/${org}/env/new`}>Back</Button>
				<Button
					to={`/dashboard/${org}/env/new/clusters`}
					variant="contained"
					color="primary"
				>
					Assign Clusters
					<ArrowRightIcon />
				</Button>
			</DialogActions>
		</>
	);
};

export default AccessStep;
