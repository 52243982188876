import { useState, useCallback, useMemo } from "react";

const useDialog = () => {
	const [isOpen, setIsOpen] = useState(false);

	const show = useCallback(() => {
		setIsOpen(true);
	}, []);

	const hide = useCallback(() => {
		setIsOpen(false);
	}, []);

	const buttonProps = { onClick: show };
	const dialogProps = { open: isOpen, onClose: hide };

	const result = useMemo(
		() => ({ isOpen, setIsOpen, show, hide, buttonProps, dialogProps }),
		[buttonProps, dialogProps, hide, isOpen, show]
	);
	return result;
};

export default useDialog;
