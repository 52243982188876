import React, { useEffect, useState, useCallback } from "react";
import {
	TextField,
	InputAdornment,
	IconButton,
	Menu,
	MenuItem
} from "@material-ui/core";
import cronstrue from "cronstrue";
import { Schedule as ScheduleIcon } from "@material-ui/icons";
import { useMenu, belowPopProps } from "@runly/gatsby-theme";

export const useScheduleEditorState = (schedule = "") => {
	const [value, setValue] = useState(schedule);
	const [isValid, setIsValid] = useState(false);
	const [isDirty, setIsDirty] = useState(false);

	const [humanized, setHumanized] = useState("");

	useEffect(() => {
		let _humanized = "";
		try {
			_humanized = cronstrue.toString(value);
		} catch (e) {
			_humanized = "";
		}
		setHumanized(_humanized);
	}, [value]);
	useEffect(() => {
		if (humanized) {
			setIsValid(true);
		}
	}, [humanized]);

	const onChange = useCallback(e => {
		setValue(e.target.value);
		setIsDirty(true);
	}, []);

	return { value, onChange, setValue, isDirty, isValid, humanized };
};

const PRESET_SCHEDULES = [
	"*/5 * * * *",
	"0 * * * *",
	"30 17 * * *",
	"15/5 8-17 * * mon-fri"
];

const menuOptions = PRESET_SCHEDULES.map(s => {
	return { value: s, label: cronstrue.toString(s) };
});

const ScheduleEditor = ({
	value = "",
	onChange,
	setValue,
	humanized,
	isDirty,
	isValid
}) => {
	const { menuProps, buttonProps, onClose } = useMenu();
	const pickerButton = (
		<InputAdornment position="end">
			<IconButton {...buttonProps}>
				<ScheduleIcon />
			</IconButton>
		</InputAdornment>
	);
	return (
		<>
			<TextField
				placeholder="Cron string e.g. */5 * * * *"
				fullWidth
				error={!isValid && isDirty}
				{...{ value, onChange }}
				label="Schedule"
				helperText={humanized}
				InputProps={{ endAdornment: pickerButton }}
			/>
			<Menu
				{...belowPopProps}
				{...menuProps}
				MenuListProps={{ style: { maxWidth: "24em" } }}
			>
				{menuOptions.map(s => (
					<ScheduleMenuItem
						key={s.value}
						{...s}
						setSchedule={setValue}
						onClose={onClose}
					/>
				))}
			</Menu>
		</>
	);
};

const ScheduleMenuItem = ({ value, label, setSchedule, onClose }) => {
	const handleClick = useCallback(() => {
		setSchedule(value);
		onClose();
	}, [onClose, setSchedule, value]);
	return (
		<MenuItem style={{ whiteSpace: "normal" }} onClick={handleClick}>
			{label}
		</MenuItem>
	);
};

export default ScheduleEditor;
