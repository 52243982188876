import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../layout";
import { Grid } from "@material-ui/core";
import { Router } from "@reach/router";

import DeepNav from "../../layout/deep-nav";

import OrgMembers from "./members";
import OrgGeneralSettings from "./general";
import OrgBilling from "./billing";

const OrgSettings = ({ org, children, location }) => {
	return (
		<Layout org={org} location={location}>
			<Helmet>
				<title>Org Settings</title>
			</Helmet>

			{children}
			<Grid container spacing={3}>
				<Grid item xs={12} sm={3}>
					<DeepNav
						location={location}
						tabs={[
							{ href: `/dashboard/${org}/settings`, label: "General" },
							{
								href: `/dashboard/${org}/settings/members`,
								label: "Members"
							},

							{
								href: `/dashboard/${org}/settings/billing`,
								label: "Billing"
							}
						]}
					/>
				</Grid>
				<Grid item xs={12} sm={9}>
					<Router>
						<OrgGeneralSettings path="/" />
						<OrgMembers path="members" />
						<OrgBilling path="billing/*" />
					</Router>
				</Grid>
			</Grid>
		</Layout>
	);
};

export default OrgSettings;
