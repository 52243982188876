import React, { useState, useCallback } from "react";

import { Dialog, DialogTitle, List } from "@material-ui/core";
import KeyboardShortcut from "./keyboard-shortcut";

const KeyboardHelp = ({ shortcuts }) => {
	const helpDialog = useHelpDialogState();

	return (
		<Dialog
			keepMounted
			fullWidth
			open={helpDialog.isOpen}
			onClose={helpDialog.onClose}
		>
			<DialogTitle>Keyboard Shortcuts</DialogTitle>
			<List>
				<KeyboardShortcut
					shortcut="shift+/"
					shortcutLabel="?"
					callback={helpDialog.toggle}
				>
					Show/hide keyboard shortcut help
				</KeyboardShortcut>
				{shortcuts?.map(({ description, ...s }) => (
					<KeyboardShortcut key={s.shortcut} {...s}>
						{description}
					</KeyboardShortcut>
				))}
			</List>
		</Dialog>
	);
};

export default KeyboardHelp;

const useHelpDialogState = () => {
	const [isOpen, setIsOpen] = useState(false);
	const onClose = useCallback(() => {
		setIsOpen(false);
	}, []);
	const toggle = useCallback(() => {
		setIsOpen(isOpen => !isOpen);
	}, []);
	return { isOpen, toggle, onClose };
};
