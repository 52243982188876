import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Flipper, Flipped } from "react-flip-toolkit";
import { IconButton, Button, CircularProgress } from "@material-ui/core";

const BusyButton = ({
	isBusy = false,
	isError = false,
	children,
	busyChildren,
	errorChildren = <>Retry</>,
	disabled = isBusy,
	startIcon,
	...rest
}) => {
	const classes = useStyles({ isBusy });
	return (
		<Flipper flipKey={isBusy}>
			<Flipped flipId="busy-button-root">
				<Button
					startIcon={
						isBusy ? <CircularProgress color="inherit" size={15} /> : startIcon
					}
					className={classes.root}
					disabled={disabled}
					{...rest}
				>
					{isBusy && busyChildren
						? busyChildren
						: isError
						? errorChildren
						: children}
				</Button>
			</Flipped>
		</Flipper>
	);
};

export const BusyIconButton = ({
	isBusy = false,
	isError = false,
	children,
	busyChildren = <CircularProgress color="inherit" size={15} />,
	errorChildren = <>Retry</>,
	disabled = isBusy,
	...rest
}) => {
	const classes = useStyles({ isBusy });
	return (
		<Flipper flipKey={isBusy}>
			<Flipped flipId="busy-button-root">
				<IconButton className={classes.root} disabled={disabled} {...rest}>
					{isBusy && busyChildren
						? busyChildren
						: isError
						? errorChildren
						: children}
				</IconButton>
			</Flipped>
		</Flipper>
	);
};

const useStyles = makeStyles(() => {
	return {
		root: {
			opacity: ({ isBusy }) => (isBusy ? 0.75 : 1)
		}
	};
});

export default BusyButton;
