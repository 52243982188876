import React from "react";

import { TextField, ListItem, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";

import { useFetchJobs } from "../api";

import GrowPopper from "../../grow-popper";

import { isString } from "lodash";

const ProcessOption = ({ name, type }) => (
	<ListItem component="div" disableGutters>
		<ListItemText primary={name} secondary={<>{type}</>} />
	</ListItem>
);

const useStyles = makeStyles(theme => {
	return { popper: { minWidth: [theme.spacing(36), "!important"] } };
});

const ProcessPicker = ({ org, req, onChange, value, disabled }) => {
	const procsReq = useFetchJobs(!req ? org : null);
	const classes = useStyles();

	req = req || procsReq;

	return (
		<Autocomplete
			disabled={disabled}
			PopperComponent={GrowPopper}
			classes={classes}
			onChange={onChange}
			value={value}
			options={req.body || []}
			loading={req.isFetching}
			getOptionLabel={o => (isString(o) ? o : o.type)}
			renderOption={ProcessOption}
			renderInput={params => (
				<TextField {...params} label="Job" variant="outlined" fullWidth />
			)}
			getOptionSelected={(option, value) =>
				option.type && isString(value)
					? option.type == value
					: option.type == value.type
			}
		/>
	);
};

export default ProcessPicker;
