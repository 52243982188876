import React from "react";

import { Card, CardActions, Button, List, CardHeader } from "@material-ui/core";
import { Settings as SettingsIcon } from "@material-ui/icons";

import SettingsItem from "../../../../settings-property";

import { Link } from "@runly/gatsby-theme";

import DateTime from "../../../../time";
import Duration from "../../../../time/duration";
import UserProfile from "../../../../user-profile";
import DangerZone from "./danger-zone";

const EnvReadonlySettings = ({
	org,
	oldEnv,
	environment,
	onEdit,
	onSaveId,
	onDelete,
	onChange,
	patchReq,
	deleteReq
}) => {
	if (!environment) return null;
	const returnLabel = {
		id: environment.id,
		label: "Environment Settings"
	};

	return (
		<>
			<Card data-testid="env-settings-readonly">
				<CardHeader title="General" id="#" />
				<List>
					<SettingsItem title="ID">{environment.id}</SettingsItem>
					<SettingsItem title="Description">
						{environment.description}
					</SettingsItem>
					<SettingsItem title="Access">
						<span>
							Developers{" "}
							{environment.isProduction ? (
								<strong>do not</strong>
							) : (
								<strong>do</strong>
							)}{" "}
							have access to modify this environment.
						</span>
					</SettingsItem>

					<SettingsItem title="Clusters">
						<Link
							to={`/dashboard/${org}/env/${environment.id}/settings/clusters`}
							color="textPrimary"
						>
							{environment.clusters?.length} cluster
							{environment.clusters?.length != 1 ? "s" : ""}
						</Link>
					</SettingsItem>
					<SettingsItem title="Created">
						<span>
							<DateTime value={environment.createdAt} /> by{" "}
							<UserProfile
								userId={environment.createdBy}
								returnLabel={returnLabel}
							/>
						</span>
					</SettingsItem>
					{environment.updatedAt !== environment.createdAt ? (
						<SettingsItem title="Modified">
							<>
								<DateTime value={environment.updatedAt} /> (
								<Duration
									start={environment.createdAt}
									end={environment.updatedAt}
								/>{" "}
								after creation) by{" "}
								<UserProfile
									org={org}
									userId={environment.updatedBy}
									returnLabel={returnLabel}
								/>
							</>
						</SettingsItem>
					) : null}
				</List>
				<CardActions>
					<Button
						variant="outlined"
						onClick={onEdit}
						startIcon={<SettingsIcon />}
					>
						Edit Settings
					</Button>
				</CardActions>
			</Card>
			<br />
			<DangerZone
				{...{
					patchReq,
					deleteReq,
					environment,
					oldEnv,
					onSaveId,
					onDelete,
					onChange
				}}
			/>
		</>
	);
};

export default EnvReadonlySettings;
