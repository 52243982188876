import { useLazyFetch } from "react-fetch-hooks";
import { useRunlyConfig } from "@runly/ui";

export const useShutdownNode = ({ org, id, cancelJobs }) => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useLazyFetch(
		url && bearerToken ? `${url}/${org}/nodes/${id}/shutdown/` : null,
		{
			method: "POST",
			bearerToken,
			body: JSON.stringify({ cancelJobs })
		}
	);
};
