import React from "react";
import { CardHeader } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const SkeletonCardHeader = ({ avatar = false, subheader = true }) => {
	return (
		<CardHeader
			avatar={
				avatar ? <Skeleton variant="circle" width={40} height={40} /> : null
			}
			title={<Skeleton height="1em" width="50%" />}
			subheader={subheader ? <Skeleton height={12} width={"30%"} /> : null}
		/>
	);
};
