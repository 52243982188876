import { useReducer, useCallback, useEffect, useMemo } from "react";

const reducer = (state, { type, payload }) => {
	switch (type) {
		case "init":
			return [];

		case "event":
			return [
				...state,
				{
					...payload,
					id: state.length + 1
				}
			];

		default:
			return state;
	}
};

const appendPendingEvent = (events, msg) => [
	...events,
	{ id: 0, type: "Pending", itemId: msg }
];

const useEventConnection = connection => {
	const [events, dispatch] = useReducer(reducer, []);

	const onEvent = useCallback(ev => {
		dispatch({ type: "event", payload: ev });
	}, []);

	useEffect(() => {
		if (connection) {
			dispatch({ type: "init" });

			connection.on("Event", onEvent);
			connection.invoke("SetMode", "Timeline"); // TODO: handle errors? this function returns a promise
		}

		return () => {
			if (connection) {
				connection.off("Event", onEvent);
			}
		};
	}, [connection, onEvent]);

	const eventsWithPending = useMemo(() => {
		if (!events.length) {
			return appendPendingEvent(events, "Loading timeline…");
		}

		const lastEvent = events[events.length - 1];
		switch (lastEvent.type) {
			case "Queued": {
				return appendPendingEvent(events, "Preparing to run…");
			}

			case "Assigned": {
				return appendPendingEvent(events, "Waiting for node acquisition…");
			}

			case "Acquired": {
				return appendPendingEvent(events, "Preparing to execute job…");
			}

			case "Executed":
			case "Started": {
				return appendPendingEvent(events, "Initializing job…");
			}

			case "Method": {
				switch (lastEvent.itemId) {
					case "InitializeAsync":
					case "GetItemsAsync": {
						return appendPendingEvent(events, "Getting items to process…");
					}

					case "GetEnumerator":
					case "Count": {
						return appendPendingEvent(events, "Processing items…");
					}

					case "FinalizeAsync": {
						return appendPendingEvent(events, "Completing job…");
					}
				}
				break;
			}

			case "Result": {
				return appendPendingEvent(events, "Processing items…");
			}
		}

		return events;
	}, [events]);

	return eventsWithPending;
};

export default useEventConnection;
