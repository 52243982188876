import { useCallback, useEffect, useState } from "react";
import { useFetchRunConfig } from "../../../../run/detail/api";
import useProcessPicker from "./process-picker";
import useQueueProcessAPI from "../api";
import { useSuccessTransition } from "../../../../mutation-handler";

const useConfigEditorState = ({
	org,
	env,
	runId,
	populateConfigFromProcess = true
}) => {
	const [_monaco, _setMonaco] = useState(null);
	const [instance, setInstance] = useState(null);
	const [hasValidationErrors, setHasValidationErrors] = useState(null);
	const [fetchConfigParams, setFetchConfigParams] = useState({});

	const configReq = useFetchRunConfig(fetchConfigParams);

	const [value, setValue] = useState("");
	const [isDirty, setIsDirty] = useState(false);

	const {
		selectedProcess,
		setSelectedProcess,
		selectedVersion,
		setSelectedVersion,
		onProcessPickerChange,
		onVersionPickerChange
	} = useProcessPicker({
		populateConfigFromProcess,
		org,
		setIsDirty,
		setValue,
		_monaco,
		instance
	});

	const queueReq = useQueueProcessAPI({
		org,
		env,
		value
	});

	const handleDoQueue = useCallback(() => {
		queueReq.fetch();
	}, [queueReq]);

	const resetDirty = useCallback(() => {
		setSelectedProcess(null);
		setSelectedVersion(null);
		setIsDirty(false);
	}, [setSelectedProcess, setSelectedVersion]);
	const handleChange = useCallback(
		newValue => {
			setValue(newValue);
			setIsDirty(true);
		},
		[setValue]
	);

	useEffect(() => {
		if (runId) {
			setIsDirty(true);
			setValue(`// Fetching config for ${runId}…`);
			setFetchConfigParams({ org, runId });
		}
	}, [org, runId]);

	const justFetched = useSuccessTransition(configReq);

	useEffect(() => {
		if (justFetched) {
			setValue(JSON.stringify(configReq.body, null, 2));
		}
	}, [configReq.body, justFetched]);

	return {
		_monaco,
		_setMonaco,
		instance,
		setInstance,
		hasValidationErrors,
		setHasValidationErrors,
		value,
		setValue,
		isDirty,
		setIsDirty,
		resetDirty,
		handleChange,
		selectedProcess,
		selectedVersion,
		onProcessPickerChange,
		onVersionPickerChange,
		queueReq,
		handleDoQueue,
		...fetchConfigParams
	};
};

export default useConfigEditorState;
