import React, { useMemo } from "react";

import {
	List,
	IconButton,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	ListItemIcon,
	Paper,
	Card
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
	CloudDownload as CloudDownloadIcon,
	CheckCircle as CheckCircleIcon,
	Error as ErrorIcon,
	RemoveCircle as RemoveCircleIcon
} from "@material-ui/icons";

import semver from "semver";

import UserProfile from "../../user-profile";

import Link from "../../refable-link";
import SettingsItem from "../../settings-property";

import { usePackageDownloadUrl } from "../../pkg-version/api";
import { FadeIn } from "../../skeleton/fade-in";
import { SkeletonList } from "../../skeleton/list";
import { SkeletonCardHeader } from "../../skeleton/card";

export const PackageVersionsNav = ({ org, id, versions, version }) => {
	return (
		<List style={{ maxHeight: "50vh", overflowY: "auto" }}>
			{versions.map((v, i) => (
				<PkgVersionNavItem
					key={v.version}
					org={org}
					pkgId={id}
					{...v}
					isSelected={version == v.version || (!version && i == 0)}
				/>
			))}
		</List>
	);
};

export const PackageVersionsNavPlaceholder = () => (
	<FadeIn>
		<Card>
			<SkeletonCardHeader subheader={false} />
			<SkeletonList avatarSize={24} featuredCol />
		</Card>
	</FadeIn>
);

export const ActiveVersionDetails = ({
	org,
	id,
	version: { version, clientVersion, uploadedAt, uploadedBy }
}) => {
	const uploadedDate = new Date(uploadedAt);
	const downloadUrl = usePackageDownloadUrl(org, id, version);

	return (
		<Paper>
			<List>
				<ListItem display="flex">
					<SettingsItem title="Version">{version}</SettingsItem>
					<SettingsItem title="Runly Version">
						{clientVersion || "\u00A0"}
					</SettingsItem>
					<SettingsItem title="Uploaded">
						<span
							title={`${uploadedDate.toLocaleDateString()} at ${uploadedDate.toLocaleTimeString()}`}
						>
							{uploadedDate.toLocaleDateString()}
						</span>{" "}
						by{" "}
						<UserProfile
							userId={uploadedBy}
							{...(typeof uploadedBy === "string" ? {} : uploadedBy)}
							returnLabel={id}
						/>
					</SettingsItem>
					<ListItemSecondaryAction>
						<IconButton
							size="medium"
							aria-label="download"
							title="Download"
							href={downloadUrl}
						>
							<CloudDownloadIcon />
						</IconButton>
					</ListItemSecondaryAction>
				</ListItem>
			</List>
		</Paper>
	);
};

const Cell = props => {
	const classes = useCellStyles();
	return <ListItemText className={classes.root} {...props} />;
};

const useCellStyles = makeStyles(theme => {
	return {
		root: {
			flexBasis: "33%",
			[theme.breakpoints.up("md")]: {
				flexBasis: "25%"
			}
		}
	};
});

const PkgVersionNavItem = ({ org, pkgId, version, status, isSelected }) => {
	const isPrerelease = useMemo(() => !!semver.prerelease(version), [version]);

	const classes = useRowStyles({ isPrerelease });

	return (
		<ListItem
			display="flex"
			button
			component={Link}
			className={classes.row}
			to={`/dashboard/${org}/pkg/${pkgId}/${version}/`}
			selected={isSelected}
		>
			<ListItemIcon title={status}>
				<StatusIcon status={status} />
			</ListItemIcon>
			<Cell>{version}</Cell>
		</ListItem>
	);
};

const useRowStyles = makeStyles(theme => ({
	row: {
		display: "flex",
		flexWrap: "wrap",
		color: ({ isPrerelease }) =>
			isPrerelease ? theme.palette.text.secondary : undefined
	}
}));

const StatusIcon = ({ status }) => {
	const classes = useIconStyles({ status });

	return status == "Processed" ? (
		<CheckCircleIcon className={classes.icon} />
	) : status == "Error" ? (
		<ErrorIcon className={classes.icon} />
	) : (
		<RemoveCircleIcon className={classes.icon} />
	);
};

const useIconStyles = makeStyles(theme => ({
	icon: {
		color: ({ status }) =>
			status == "Processed"
				? theme.palette.success.main
				: status == "Error"
				? theme.palette.error.main
				: theme.palette.warning.main
	}
}));
