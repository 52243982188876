import React, { useMemo } from "react";
import { List } from "@material-ui/core";
import RunsListItem from "./list-item";

const RunsList = ({ org, env, runs, location }) => {
	const runIds = useMemo(() => runs.map(r => r.id), [runs]);

	return (
		<>
			<RunsListInner {...{ runs, runIds, org, env, location }} />
		</>
	);
};

const RunsListInner = ({ runs, runIds, org, env, location }) => {
	const linkProps = useMemo(
		() => ({
			state: {
				runIds,
				referrerSearch: location?.search
			}
		}),
		[location, runIds]
	);

	return (
		<List>
			{runs.map(run => (
				<RunsListItem
					runIds={runIds}
					key={run.id}
					LinkProps={linkProps}
					{...{ org, env, run }}
				/>
			))}
		</List>
	);
};

export default RunsList;
