import React, { useMemo } from "react";
import {
	parseISO,
	formatDistanceStrict,
	differenceInSeconds,
	differenceInMilliseconds
} from "date-fns";
import { formatNumber } from "accounting";

import useNow from "./now";

const useTimes = ({ start, end }) => {
	const now = useNow();
	const _start = useMemo(() => (start ? parseISO(start) : null), [start]);
	const _end = useMemo(() => (end ? parseISO(end) : null), [end]);
	return [_start, _end || now];
};

const Duration = ({
	start,
	end,
	showSuffix = false,
	beforeSuffix = "before",
	afterSuffix = "after",
	inWords = true
}) => {
	const [_start, _end] = useTimes({ start, end });

	if (start === end) return null;
	if (!_start || !_end) return null;

	const secsElapsed = differenceInMilliseconds(_end, _start) / 1e3;
	const dateTime = toISO8601DurationString(differenceInSeconds(_start, _end));
	const title = `${formatNumber(Math.abs(secsElapsed), 2)} second${
		Math.abs(secsElapsed) === 1 ? "" : "s"
	}`;

	return (
		<time dateTime={dateTime} title={title}>
			{inWords
				? Math.abs(secsElapsed) < 1
					? title
					: formatDistanceStrict(_start, _end)
				: toCounterString(secsElapsed)}{" "}
			{showSuffix ? (secsElapsed < 0 ? beforeSuffix : afterSuffix) : null}
		</time>
	);
};

function toISO8601DurationString(durationInSeconds) {
	if (durationInSeconds === 0) {
		return "P0D";
	}

	const seconds = parseInt(durationInSeconds % 60);
	const minutes = parseInt((durationInSeconds / 60) % 60);
	const hours = parseInt((durationInSeconds / (60 * 60)) % 24);
	const isoTime = [hours, minutes, seconds]
		.map((part, i) => {
			if (!part || part === 0) {
				return;
			}

			// assuming we only have max HOURS in duration, no Days
			let specifier;
			if (i === 0) {
				specifier = "H";
			} else if (i === 1) {
				specifier = "M";
			} else {
				specifier = "S";
			}

			return `${part}${specifier}`;
		})
		.filter(Boolean)
		.join("");

	return `PT${isoTime}`;
}

export default Duration;

const toCounterString = durationInSeconds => {
	if (durationInSeconds === 0) {
		return "00:00:00";
	}

	const seconds = parseInt(durationInSeconds % 60);
	const minutes = parseInt((durationInSeconds / 60) % 60);
	const hours = parseInt((durationInSeconds / (60 * 60)) % 24);
	const result = [hours, minutes, seconds]
		.map(p => `${p}`.padStart(2, "0"))
		.join(":");

	return result;
};
