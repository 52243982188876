import { useCallback } from "react";

import { COLLECTION_ACTIONS } from "./actions";

export const useDeleteHandlers = ({ req, dispatch }) => {
	const handleSelectDeleteItem = useCallback(
		id => {
			dispatch({
				type: LOCAL_SELECT_DELETE_ITEM,
				payload: { id }
			});
		},
		[dispatch]
	);

	const handleCancelDelete = useCallback(() => {
		dispatch({
			type: LOCAL_SELECT_DELETE_ITEM,
			payload: { id: null }
		});
	}, [dispatch]);

	const handleConfirmDelete = useCallback(() => {
		req.fetch();
	}, [req]);

	const handleExited = useCallback(
		id => {
			dispatch({
				type: LOCAL_DELETE,
				payload: { id }
			});
		},
		[dispatch]
	);

	return {
		handleSelectDeleteItem,
		handleCancelDelete,
		handleConfirmDelete,
		handleExited
	};
};

const { LOCAL_SELECT_DELETE_ITEM, LOCAL_DELETE } = COLLECTION_ACTIONS;
