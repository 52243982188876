import React, { useCallback } from "react";
import {
	ListItemText,
	MenuItem,
	ListItemIcon // ListItemAvatar
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import MoreMenu from "../../more-menu";

import useDialog from "../../use-dialog";
import { RunCancelDialog } from "./cancel-dialog";
import { ReactComponent as CodeBracesIcon } from "../../assets/code-braces.svg";
import RefableLink from "../../refable-link";

import { useCanQueue } from "../../org/permissions";

const RunItemMenu = ({ org, env, canceler, run, location }) => {
	const canQueue = useCanQueue(org, run.environment.isProduction);

	const cancelDialog = useDialog();

	const handleCancel = useCallback(() => {
		cancelDialog.hide();
		canceler.fetch();
	}, [cancelDialog, canceler]);

	const cancelMenuItem = !run.completedAt ? (
		<MenuItem disabled={canceler.isFetching} onClick={cancelDialog.show}>
			<ListItemIcon>
				<CloseIcon />
			</ListItemIcon>
			<ListItemText primary="Cancel run" />
		</MenuItem>
	) : null;

	const editConfigMenuItem = canQueue ? (
		<MenuItem
			component={RefableLink}
			to={`/dashboard/${org}/env/${env}/runs/${run.id}/requeue`}
			state={{
				...location.state,
				redirectToNew: true,
				returnUrl: `${location.pathname}${location.search}`
			}}
		>
			<ListItemIcon>
				<CodeBracesIcon />
			</ListItemIcon>
			<ListItemText primary="Requeue" />
		</MenuItem>
	) : null;

	const menuOptions = [editConfigMenuItem, cancelMenuItem];
	const hasMenuOptions = menuOptions.some(m => m);

	return hasMenuOptions ? (
		<>
			<MoreMenu>
				{editConfigMenuItem}
				{cancelMenuItem}
			</MoreMenu>
			<RunCancelDialog
				{...{
					cancelDialog,
					handleCancel
				}}
			/>
		</>
	) : null;
};

export default RunItemMenu;
