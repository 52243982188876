import React, { lazy, Suspense } from "react";

import { useTheme, makeStyles } from "@material-ui/core/styles";
import useDimensions from "react-use-dimensions";

import { LoadingIndicator } from "@runly/gatsby-theme";

import useEditorInit from "./editor-init";
import { useWidth } from "../../../layout/context";

const _MonacoEditor = lazy(() => import("react-monaco-editor"));

const MonacoEditor = React.memo(
	_MonacoEditor,
	(prev, next) =>
		prev?.value === next?.value &&
		prev?.width === next?.width &&
		prev?.height === next?.height &&
		prev?.theme === next?.theme
);

const ProcessConfigEditor = ({
	_monaco,
	_setMonaco,
	instance,
	setInstance,
	setHasValidationErrors,
	value,
	handleChange,
	fullWidth = true
}) => {
	useWidth(fullWidth ? false : undefined);
	const classes = useStyles();
	const theme = useTheme();
	const [ref, { width }] = useDimensions();
	const { handleEditorWillMount, handleEditorDidMount } = useEditorInit({
		_monaco,
		_setMonaco,
		instance,
		setInstance,
		setHasValidationErrors
	});

	const computedHeight = Math.max(
		72 + (value || "").split("\n").length * 19,
		256
	);
	return (
		<div ref={ref} className={classes.root}>
			<Suspense
				fallback={<LoadingIndicator>Loading config editor…</LoadingIndicator>}
			>
				<MonacoEditor
					width={width}
					height={computedHeight}
					language="json"
					theme={`solarized-${theme.palette.type}`}
					value={value}
					onChange={handleChange}
					editorWillMount={handleEditorWillMount}
					editorDidMount={handleEditorDidMount}
					options={{
						tabFocusMode: false,
						minimap: { enabled: false },
						scrollBeyondLastColumn: false,
						scrollBeyondLastLine: false,
						scrollbar: {
							alwaysConsumeMouseWheel: false,
							horizontal: "hidden",
							vertical: "hidden"
						}
					}}
				/>
			</Suspense>
		</div>
	);
};

const useStyles = makeStyles(() => {
	return {
		root: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: "100%"
		}
	};
});

export default ProcessConfigEditor;
