import { useState, useCallback } from "react";

export const CURS = {
	BEFORE: "endingBefore",
	AFTER: "startingAfter",
	RANGE: "RANGE"
};
export const usePaginationCursor = () => {
	const [dir, _setDir] = useState(null);
	const [pos, _setPos] = useState(null);

	const setBefore = useCallback(id => {
		_setDir(CURS.BEFORE);
		_setPos(id);
	}, []);
	const setAfter = useCallback(id => {
		_setDir(CURS.AFTER);
		_setPos(id);
	}, []);
	const setRange = useCallback(([afterId, beforeId]) => {
		_setDir(CURS.RANGE);
		_setPos([afterId, beforeId]);
	}, []);

	return {
		dir,
		pos,
		setBefore,
		setAfter,
		setRange
	};
};
