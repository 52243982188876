import React, { useState, useCallback } from "react";
import { injectStripe } from "react-stripe-elements";

import { Box, TextField, DialogActions, Button } from "@material-ui/core";
import StripeTextField from "../../stripe/text-field";
import BusyButton from "../../../busy-button";
import { Flipped } from "react-flip-toolkit";

const CreditCardEditor = ({ stripe, onSaved, onCancelEdit }) => {
	const [isSaving, setIsSaving] = useState(false);
	const [nameOnCard, setNameOnCard] = useState("");
	const [isCardComplete, setIsCardComplete] = useState(false);

	const handleNameOnCardChange = useCallback(
		evt => setNameOnCard(evt.target.value),
		[]
	);

	const handleSubmit = useCallback(
		async ev => {
			if (!isSaving) {
				ev.preventDefault();

				setIsSaving(true);

				// TODO: handle stripe error here
				// https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
				const { paymentMethod } = await stripe.createPaymentMethod("card", {
					billing_details: { name: nameOnCard }
				});

				if (onSaved && paymentMethod) {
					onSaved(paymentMethod);
				}
			}
		},
		[isSaving, nameOnCard, onSaved, stripe]
	);

	return (
		<form onSubmit={handleSubmit}>
			<Box px={2} pb={3}>
				<TextField
					fullWidth
					id="name-on-card"
					label="Name on Card"
					value={nameOnCard}
					onChange={handleNameOnCardChange}
					margin="dense"
					required
					disabled={isSaving}
				/>
				<Flipped flipId="billing-card-summary">
					<StripeTextField
						margin="dense"
						required
						subscribeToIsComplete={setIsCardComplete}
					/>
				</Flipped>
			</Box>

			<DialogActions>
				<Button onClick={onCancelEdit}>Don't update</Button>
				<BusyButton
					type="submit"
					isBusy={isSaving}
					size="large"
					variant="contained"
					color="primary"
					busyChildren={<>Updating payment method…</>}
					disabled={!isCardComplete || !nameOnCard?.trim()}
				>
					<>Update payment method</>
				</BusyButton>
			</DialogActions>
		</form>
	);
};

export default injectStripe(CreditCardEditor);
