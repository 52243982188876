import { useState, useEffect, useCallback } from "react";

import { useRunlyConfig } from "@runly/ui";

const useNtp = () => {
	const { url } = useRunlyConfig();
	const [data, setServerData] = useState();

	useEffect(() => {
		async function syncTime() {
			const clientStartTime = new Date().valueOf();

			let data;
			try {
				const res = await fetch(`${url}/time?t=${clientStartTime}`, {
					method: "GET"
				});
				data = await res.json();

				setServerData({
					offset: data.offset,
					serverTime: data.timestamp,
					clientStartTime,
					clientEndTime: new Date().valueOf()
				});
			} catch (error) {
				// if it fails for whatever reason, just trust the client's time
				console.warn(error);
				setServerData("error");
			}
		}

		if (url) {
			syncTime();
		}
	}, [url]);

	const calculateTime = useCallback(() => {
		const clientTime = new Date();

		// if the server hasn't returned yet or if it bombs, just go with the client time
		if (!data || data == "error") return clientTime;

		const offsetTime = new Date(
			clientTime.valueOf() +
				(data.offset + (data.serverTime - data.clientEndTime)) / 2
		);

		return offsetTime;
	}, [data]);

	return calculateTime;
};

const useNow = () => {
	const whatIsNow = useNtp();
	const [now, setNow] = useState(whatIsNow);

	const onSecond = useCallback(() => {
		setNow(whatIsNow());
	}, [whatIsNow]);

	useEffect(() => {
		let t;

		t = setInterval(onSecond, 1000);

		return () => {
			if (t) {
				clearInterval(t);
			}
		};
	}, [whatIsNow, onSecond]);

	return now;
};

export default useNow;
