import { CURS } from "./cursor";

export const usePager = ({ hasMore, query }) => {
	const isEmpty = !(CURS.AFTER in query || CURS.BEFORE in query);

	const hasPrev = CURS.AFTER in query || (hasMore && CURS.BEFORE in query);
	const hasNext =
		CURS.BEFORE in query || (hasMore && (isEmpty || CURS.AFTER in query));

	return { hasPrev, hasNext };
};
