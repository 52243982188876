import React from "react";
import { navigate } from "@reach/router";
import { useHotkeys } from "react-hotkeys-hook";
import {
	ListItem,
	ListItemText,
	ListItemAvatar,
	Avatar
} from "@material-ui/core";

const KeyboardShortcut = ({
	shortcut,
	shortcutLabel = shortcut,
	children,
	callback,
	navigateTo,
	navigateOptions,
	enabled = true
}) => {
	useHotkeys(
		shortcut,
		() => {
			if (enabled) {
				callback?.();
				if (navigateTo) {
					navigate(navigateTo, navigateOptions);
				}
			}
		},
		[shortcut, enabled, callback, navigateTo, navigateOptions]
	);

	return (
		<ListItem disabled={!enabled}>
			<ListItemAvatar>
				<Avatar
					variant="rounded"
					style={{
						fontSize: "2em",
						fontWeight: "bold",
						fontFamily: "monospace"
					}}
				>
					{shortcutLabel}
				</Avatar>
			</ListItemAvatar>
			<ListItemText
				primary={children}
				primaryTypographyProps={{ variant: "h5" }}
			/>
		</ListItem>
	);
};

export default KeyboardShortcut;
