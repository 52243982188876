import { useMemo } from "react";
import { sortBy } from "lodash";

export const useCollectionSorter = collection => {
	return useMemo(() => {
		const unsorted = collection
			? Object.keys(collection).map(k => {
					return collection[k];
			  })
			: null;

		const sorted = unsorted
			? sortBy(
					unsorted,
					item => -new Date(item?.updatedAt || item?.createdAt).getTime()
			  )
			: null;

		return sorted || unsorted;
	}, [collection]);
};
