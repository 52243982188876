import React from "react";

import { Paper } from "@material-ui/core";

import { SkeletonList } from "../../skeleton/list";
import { FadeIn } from "../../skeleton/fade-in";

import { Helmet } from "react-helmet";

import RunEventTimeline from "./timeline";

const RunOverview = ({ run, connection }) => (
	<>
		<Helmet>
			<title>Run Overview</title>
		</Helmet>

		{run ? (
			<RunEventTimeline connection={connection} />
		) : (
			<FadeIn>
				<Paper>
					<SkeletonList size={7} />
				</Paper>
			</FadeIn>
		)}
	</>
);

export default RunOverview;
