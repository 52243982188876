import React from "react";
import { Link } from "@runly/gatsby-theme";
import ProcessConfigEditor from "../../details/queue-process/editor";
import {
	Card,
	CardHeader,
	Box,
	DialogActions,
	Button,
	CardContent
} from "@material-ui/core";
import FetchButton from "../../../busy-button/fetch-button";
import { Helmet } from "react-helmet";
import { useJobTemplateEditor } from "./state";
import { JobTemplateEditorToolbar } from "./toolbar";

const JobTemplateEditor = ({
	isNew,
	id: existingId,
	org,
	env,
	requests,
	handlers,
	configEditor
}) => {
	const jobTemplateEditor = useJobTemplateEditor({
		isNew,
		org,
		env,
		id: existingId,
		requests,
		handlers,
		configEditor
	});

	const title = isNew ? "New template" : `Edit ${existingId}`;

	return (
		<>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<Box clone mt={3}>
				<Card>
					<CardHeader title={title} />
					<CardContent>
						<JobTemplateEditorToolbar
							{...{
								isNew,
								org,
								configEditor,
								requests
							}}
							{...jobTemplateEditor}
						/>
					</CardContent>
					<ProcessConfigEditor fullWidth={false} {...configEditor} />
					<DialogActions>
						<Button
							component={Link}
							to={`/dashboard/${org}/env/${env}/templates`}
						>
							Don't save
						</Button>
						<FetchButton
							disabled={jobTemplateEditor.isSaveDisabled}
							variant="contained"
							color="primary"
							req={jobTemplateEditor.saveRequest}
							onClick={jobTemplateEditor.handleSave}
						>
							Save template
						</FetchButton>
					</DialogActions>
				</Card>
			</Box>
		</>
	);
};

export default JobTemplateEditor;
