export const COLLECTION_ACTIONS = {
	FETCHING: "FETCHING",
	FETCHED: "FETCHED",
	LOCAL_CREATED: "LOCAL_CREATED",
	REMOTE_CREATING: "REMOTE_CREATING",
	REMOTE_CREATED: "REMOTE_CREATED",
	REMOTE_PATCHED: "REMOTE_PATCHED",
	LOCAL_SELECT_DETAILS: "LOCAL_SELECT_DETAILS",
	LOCAL_SELECT_DELETE_ITEM: "LOCAL_SELECT_DELETE_ITEM",
	REMOTE_ATTEMPT_DELETE: "REMOTE_ATTEMPT_DELETE",
	REMOTE_DELETED: "REMOTE_DELETED",
	LOCAL_DELETE: "LOCAL_DELETE"
};
