import { useFetchVersions } from "../../../../../../pkg/details/api";

const useSemver = ({ isFocusDirty, org, packageId }) => {
	const versionsRequest = useFetchVersions(
		isFocusDirty ? { org, id: packageId } : {}
	);
	const versions = versionsRequest?.body?.map?.(v => v.version) || [];

	return { versions, req: versionsRequest };
};

export default useSemver;
