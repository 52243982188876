import { useFetch, useLazyFetch } from "react-fetch-hooks";

import { useRunlyConfig } from "@runly/ui";

export const useFetchOrgMembers = org => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useFetch(url && bearerToken ? `${url}/${org}/members/` : null, {
		method: "GET",
		bearerToken
	});
};

export const useInviteMember = org => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useLazyFetch(url && bearerToken ? `${url}/${org}/members/` : null, {
		method: "POST",
		bearerToken
	});
};

export const useAcceptInvitation = (org, memberId) => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useFetch(
		url && bearerToken ? `${url}/${org}/members/${memberId}` : null,
		{
			method: "POST",
			bearerToken
		}
	);
};

export const useUpdateMember = (org, memberId) => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useLazyFetch(
		url && bearerToken ? `${url}/${org}/members/${memberId}` : null,
		{
			method: "PATCH",
			bearerToken
		}
	);
};

export const useRemoveMember = (org, memberId) => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useLazyFetch(
		org && memberId ? `${url}/${org}/members/${memberId}` : null,
		{
			method: "DELETE",
			bearerToken,
			resetDelay: 1
		}
	);
};
