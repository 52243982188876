import React from "react";

import { LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { isNumber } from "lodash";

const RunProgressBar = ({ total, success, failed }) => {
	const progressClasses = useProgressStyles();

	if (!isNumber(total) || !isNumber(success) || !isNumber(failed)) {
		return null;
	}

	const successPercent = (success / total) * 100.0;
	const failedPercent = ((success + failed) / total) * 100.0;

	return (
		<>
			<LinearProgress
				variant="buffer"
				value={successPercent}
				valueBuffer={failedPercent}
				classes={progressClasses}
			/>
		</>
	);
};

const useProgressStyles = makeStyles(theme => ({
	root: {
		height: theme.spacing(2)
	},
	bar1Buffer: {
		backgroundColor: theme.palette.success.main
	},
	bar2Buffer: {
		backgroundColor: theme.palette.error.main
	}
}));

export default RunProgressBar;
