import React, { useCallback, useState } from "react";

import { Typography } from "@material-ui/core";
import {
	WrapText as WrapTextIcon,
	Delete as DeleteIcon
} from "@material-ui/icons";

import DangerConfirm, {
	useDangerConfirmState,
	useDangerButtonStyles
} from "../../../../danger/confirm";
import DangerZone from "../../../../danger/zone";
import DangerItem from "../../../../danger/item";

import SlugEditor from "../../../../slug/editor";
import FetchButton from "../../../../busy-button/fetch-button";

const EnvDangerZone = ({
	environment,
	oldEnv,
	onSaveId,
	onDelete,
	onChange,
	patchReq,
	deleteReq
}) => {
	const changeIdDialog = useDangerConfirmState({
		magicWord: oldEnv.id,
		onConfirm: onSaveId,
		validate: useCallback(
			() => !!environment.id.trim().length && environment.id !== oldEnv.id,
			[environment.id, oldEnv.id]
		)
	});

	const deleteEnvDialog = useDangerConfirmState({
		magicWord: oldEnv.id,
		onConfirm: () => {
			onDelete();
		}
	});

	const [isNewIdDirty, setIsNewIdDirty] = useState(false);
	const dangerButtonClasses = useDangerButtonStyles({ isAction: true });

	return (
		<>
			<DangerZone>
				<DangerItem
					title="Change ID"
					action={
						<FetchButton
							req={patchReq}
							classes={dangerButtonClasses}
							variant="outlined"
							startIcon={<WrapTextIcon />}
							{...changeIdDialog.buttonProps}
							busyChildren={<>Changing ID</>}
						>
							Change ID
						</FetchButton>
					}
				>
					Change the globally unique ID for this environnment. This will break
					integrations that currently depend on this environment.
				</DangerItem>

				<DangerItem
					title="Delete Environment"
					action={
						<FetchButton
							req={deleteReq}
							classes={dangerButtonClasses}
							variant="outlined"
							startIcon={<DeleteIcon />}
							{...deleteEnvDialog.buttonProps}
							busyChildren={<>Deleting</>}
						>
							Delete environment
						</FetchButton>
					}
				>
					Permanently delete this environment, scattering its remnants to the
					four winds. This cannot be undone.
				</DangerItem>
			</DangerZone>

			<DangerConfirm
				{...changeIdDialog}
				title="Confirm Change ID"
				cancelText="Don't change"
				actionText="Save new ID"
				preamble={
					<>
						<Typography paragraph>
							This will change the ID that is used to identify this environment
							in the API. Changing it will break any integrations you may have
							if you rely on this environment identifier in your app.
						</Typography>
						<Typography>First, enter the existing environment ID:</Typography>
					</>
				}
			>
				<SlugEditor
					label="New Environment ID"
					value={environment.id === oldEnv.id ? "" : environment.id}
					onChange={useCallback(slug => onChange("id", slug), [onChange])}
					onBlur={useCallback(() => {
						setIsNewIdDirty(true);
					}, [])}
					variant="outlined"
					fullWidth
					error={isNewIdDirty && environment.id === oldEnv.id}
					helperText={
						environment.id === oldEnv.id ? <>New ID must be different</> : null
					}
				/>
			</DangerConfirm>

			<DangerConfirm
				{...deleteEnvDialog}
				title="Confirm Delete Environment"
				cancelText="Don't delete"
				actionText="Delete environment"
				preamble={
					<>
						<Typography paragraph>
							This action <strong>cannot</strong> be undone. This will
							permanently delete the <strong>{environment.id}</strong>{" "}
							environment along with all associated runs (pending or complete).
						</Typography>
						<Typography paragraph>
							This will not change your billing plan. If you want to downgrade
							after deleting this environment, you must do so in your Billing
							Settings.
						</Typography>
					</>
				}
			/>
		</>
	);
};

export default EnvDangerZone;
