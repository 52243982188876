import React from "react";
import { List, Box, Paper } from "@material-ui/core";
import LatestVersion from "../latest-version";
import SettingsItem from "../../settings-property";
import DateTime from "../../time";
import UserProfile from "../../user-profile";
import { FadeIn } from "../../skeleton/fade-in";
import { SkeletonCardHeader } from "../../skeleton/card";
import { SkeletonListItem } from "../../skeleton/list";

export const PkgSettings = ({ org, pkg, horizontal = false }) => {
	return (
		<Box clone display={horizontal ? "flex" : null}>
			<List>
				<SettingsItem title="Latest Stable Version">
					<LatestVersion org={org} pkgId={pkg.id} versions={pkg.versions} />
				</SettingsItem>
				<SettingsItem title="Last Updated">
					<span>
						<DateTime value={pkg.updatedAt} /> by{" "}
						<UserProfile userId={pkg.updatedBy} returnLabel={pkg.id} />
					</span>
				</SettingsItem>
			</List>
		</Box>
	);
};
export const PkgSettingsPlaceholder = () => (
	<FadeIn>
		<Paper>
			<SkeletonCardHeader subheader={false} />
			<SkeletonListItem cols={2} avatar={false} />
		</Paper>
	</FadeIn>
);
