import { useFetch } from "react-fetch-hooks";
import useParsedDates from "../parse-dates";
import { useRunlyConfig } from "@runly/ui";

export const useFetchPayments = org => {
	const { url, token: bearerToken } = useRunlyConfig();

	const { body: _body, ...rest } = useFetch(
		url && bearerToken ? `${url}/${org}/payments` : null,
		{
			method: "GET",
			bearerToken
		}
	);

	const body = useParsedDates(_body);

	return { body, ...rest };
};
