import React from "react";

import {
	Card,
	List,
	ListItem,
	ListItemText,
	MenuItem,
	ListItemIcon,
	ListItemSecondaryAction,
	CardHeader
} from "@material-ui/core";
import { Settings as SettingsIcon } from "@material-ui/icons";

import { Link } from "@runly/gatsby-theme";

import RefableLink from "../../refable-link";
import MoreMenu from "../../more-menu";

const OrgSettings = ({ orgs }) => {
	return (
		<Card>
			<CardHeader
				title="Organization Settings"
				subheader="You can send notifications to different email addresses depending on the organization."
			/>
			<List>
				{orgs.map(o => (
					<ListItem key={o.id} display="flex">
						<ListItemText
							primary={
								<Link to={`/dashboard/${o.id}/env`} color="inherit">
									{o.name}
								</Link>
							}
							secondary={o.id}
							style={{ flexBasis: "50%" }}
						/>
						<ListItemText
							primary={o.email}
							secondary={o.role}
							style={{ flexBasis: "50%" }}
						/>
						<ListItemSecondaryAction>
							<MoreMenu>
								<MenuItem
									component={RefableLink}
									to={`/dashboard/account/orgs/${o.id}`}
								>
									<ListItemIcon>
										<SettingsIcon />
									</ListItemIcon>
									<ListItemText primary="Notification Settings" />
								</MenuItem>
							</MoreMenu>
						</ListItemSecondaryAction>
					</ListItem>
				))}
			</List>
		</Card>
	);
};

export default OrgSettings;
