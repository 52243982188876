import { useFetch, useLazyFetch } from "react-fetch-hooks";
import { useRunlyConfig } from "@runly/ui";

import useMutationHandler from "../../mutation-handler";

export const useCancelRun = ({ org, runId }) => {
	const { url, token: bearerToken } = useRunlyConfig();

	const req = useLazyFetch(
		url && bearerToken ? `${getRunBaseUrl({ url, org, runId })}/cancel` : null,
		{
			method: "POST",
			bearerToken
		}
	);

	useMutationHandler({
		req,
		errorNotification: { verb: "canceling", description: "run" }
	});

	return req;
};

export const useFetchRunLogInfo = ({ org, runId }) => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useFetch(
		url && bearerToken && runId
			? `${getRunBaseUrl({ url, org, runId })}/logs`
			: null,
		{
			method: "GET",
			bearerToken
		}
	);
};

export const useFetchRunConfig = ({ org, runId }) => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useFetch(
		url && bearerToken && runId
			? `${getRunBaseUrl({ url, org, runId })}/config`
			: null,
		{
			method: "GET",
			bearerToken
		}
	);
};

const getRunBaseUrl = ({ url, org, runId }) => `${url}/${org}/runs/${runId}`;
