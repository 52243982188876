import React, { useMemo, useState, useCallback, useEffect } from "react";

import {
	Card,
	DialogActions,
	CardHeader,
	CardContent,
	TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Button } from "@runly/gatsby-theme";

import useMutationHandler from "../../mutation-handler";
import FetchButton from "../../busy-button/fetch-button";

import { useUpdateAccount } from "../api";

const AccountPersonalInfo = ({ user, navigate, onChange }) => {
	const classes = useStyles();

	const { fetch: save, ...req } = useUpdateAccount();

	const [name, setName] = useState({ value: user.name, isDirty: false });
	const handleNameChange = useCallback(
		ev => setName({ value: ev.target.value, isDirty: true }),
		[]
	);

	const [email, setEmail] = useState({ value: user.email, isDirty: false });
	const handleEmailChange = useCallback(
		ev => setEmail({ value: ev.target.value, isDirty: true }),
		[]
	);

	const changes = useMemo(() => {
		let body;
		if (name.isDirty) body = { ...(body || {}), name: name.value };
		if (email.isDirty) body = { ...(body || {}), email: email.value };
		return body;
	}, [email.isDirty, email.value, name.isDirty, name.value]);

	const handleSubmit = useCallback(
		ev => {
			ev.preventDefault();

			if (changes) {
				save(changes);
			} else {
				navigate("/dashboard/account");
			}
		},
		[changes, navigate, save]
	);

	useEffect(() => {
		if (req.isFetched && onChange && changes) {
			onChange(changes);
		}
	}, [changes, onChange, req.isFetched]);

	useMutationHandler({
		req,
		successRedirectUrl: "/dashboard/account",
		successNotification: {
			messageId: "change-profile",
			text: "Succesfully updated profile"
		}
	});

	return (
		<form onSubmit={handleSubmit}>
			<Card>
				<CardHeader
					title="Edit Profile"
					subheader="Modify your personal information."
				/>
				<CardContent>
					<div className={classes.formRow}>
						<TextField
							id="user-name"
							label="Name"
							required
							value={name.value}
							onChange={handleNameChange}
						/>
					</div>

					<div className={classes.formRow}>
						<TextField
							id="user-email"
							label="Email"
							required
							type="email"
							value={email.value}
							onChange={handleEmailChange}
						/>
					</div>
				</CardContent>
				<DialogActions>
					<Button to="/dashboard/account">Don't save</Button>
					<FetchButton
						type="submit"
						variant="contained"
						color="primary"
						req={req}
						busyChildren={<>Saving…</>}
					>
						Save
					</FetchButton>
				</DialogActions>
			</Card>
		</form>
	);
};

const useStyles = makeStyles(theme => ({
	formRow: {
		margin: theme.spacing(2)
	}
}));

export default AccountPersonalInfo;
