import { useLazyFetch } from "react-fetch-hooks";
import { useRunlyConfig } from "@runly/ui";

const useQueueProcessAPI = ({ value, org, env }) => {
	const { url, token: bearerToken } = useRunlyConfig();

	const queueReq = useLazyFetch(
		url && bearerToken ? `${url}/${org}/environments/${env}/runs/` : null,
		{
			method: "POST",
			body: value,
			bearerToken
		}
	);

	return queueReq;
};

export default useQueueProcessAPI;
