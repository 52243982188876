import React from "react";
import { CircularProgress } from "@material-ui/core";
import normalizeToPercent from "../../normalize-to-percent";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { formatNumber } from "accounting";
import { green } from "@material-ui/core/colors";
import RunStatusIcon from "../status-icon";

const spacingSizes = {
	small: 3,
	medium: 4,
	large: 6
};

const RunProgress = ({
	completedAt,
	success,
	failed,
	total: _total,
	state,
	size = "medium"
}) => {
	const theme = useTheme();
	const _size = theme.spacing(spacingSizes[size]);
	const thickness = theme.spacing(0.25);
	const total = state === "Complete" && !_total ? success + failed : _total;
	const noProgress = success + failed === 0;
	const classes = useStyles({ size: _size, thickness });
	const successPercent = normalizeToPercent({ value: success, max: total });
	const anyPercent = normalizeToPercent({
		value: success + failed,
		max: total
	});
	const isEnqueued = state === "Enqueued";
	const isRunning = state === "Running";

	const summaryInWords = `${formatNumber(success)} succeeded, ${formatNumber(
		failed
	)} failed${
		total
			? ` of ${formatNumber(total)} total (${anyPercent.toFixed(0)}% done)`
			: ""
	}`;

	return (
		<div className={classes.root} title={summaryInWords}>
			{(!isEnqueued && !isRunning && noProgress) ||
			completedAt ? null : isEnqueued ? (
				<>
					<CircularProgress
						size={_size}
						variant="indeterminate"
						className={classes.indeterminateBar}
						thickness={thickness}
					/>
					<CircularProgress
						size={_size}
						variant="indeterminate"
						className={classes.indeterminateBar}
						thickness={thickness}
						style={{ animationDelay: "0.5s", animationDuration: "1.8s" }}
					/>
					<CircularProgress
						size={_size}
						variant="indeterminate"
						className={classes.indeterminateBar}
						thickness={thickness}
						style={{ animationDelay: "0.66s", animationDuration: "2s" }}
					/>
				</>
			) : (
				<>
					<CircularProgress
						size={_size}
						variant="static"
						value={anyPercent}
						className={classes.failureBar}
						thickness={thickness}
					/>
					<CircularProgress
						size={_size}
						variant="static"
						value={successPercent}
						className={classes.successBar}
						thickness={thickness}
					/>
				</>
			)}

			<div className={classes.statusIcon}>
				<RunStatusIcon size={_size - thickness * 2} state={state} />
			</div>
		</div>
	);
};

const useStyles = makeStyles(theme => {
	return {
		root: {
			position: "relative",
			zIndex: 0,
			width: ({ size }) => size,
			height: ({ size }) => size
		},
		indeterminateBar: {
			position: "absolute",
			top: 0,
			left: 0,
			mixBlendMode: "screen",
			opacity: 0.5
		},
		successBar: {
			position: "absolute",
			top: 0,
			left: 0,
			color: green[500]
		},
		failureBar: {
			position: "absolute",
			top: 0,
			left: 0,
			color: theme.palette.error.main
		},
		bar1Buffer: { backgroundColor: green[500] },
		bar2Buffer: { backgroundColor: theme.palette.error.main },
		statusIcon: {
			width: ({ size, thickness }) => size - thickness * 2,
			height: ({ size, thickness }) => size - thickness * 2,
			position: "absolute",
			top: ({ thickness }) => thickness,
			left: ({ thickness }) => thickness
		}
	};
});

export default RunProgress;
