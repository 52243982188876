import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { Router } from "@reach/router";

import Layout from "../../layout";
import NavTabs from "../../layout/nav-tabs";

import { Card, CardHeader, Grid, Box } from "@material-ui/core";
import { Link } from "@runly/gatsby-theme";

import { useFetchPackage, useFetchVersions } from "./api";

import {
	PackageVersionsNav,
	ActiveVersionDetails,
	PackageVersionsNavPlaceholder
} from "./versions";
import ProcessesTable, {
	ActiveVersionProcessesTablePlaceholder
} from "./processes";

import FakeSuspense from "../../fake-suspense";
import PageHeader, { HeaderIcon } from "../../page-header";

import PackageIcon from "../../assets/package-icon";
import { PkgSettingsPlaceholder, PkgSettings } from "./settings";

const PkgDetails = ({ location, org, id }) => {
	const pkgReq = useFetchPackage({ org, id });
	const versionsReq = useFetchVersions({ org, id });

	return (
		<Layout
			org={org}
			location={location}
			navTabs={
				<NavTabs
					tabs={[
						{
							href: `/dashboard/${org}`,
							isGoBack: true,
							label: "Dashboard"
						}
					]}
					location={location}
				/>
			}
			breadCrumb={
				<>
					<Link to={`/dashboard/${org}/pkg/${id}/`} color="inherit">
						{id}
					</Link>
				</>
			}
		>
			<Helmet>
				<title>{id} Package Details</title>
			</Helmet>

			<PageHeader>
				<HeaderIcon>
					<PackageIcon fontSize="inherit" type={pkgReq?.body?.type} />
				</HeaderIcon>{" "}
				{id}
			</PageHeader>

			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Box mb={3}>
						<FakeSuspense
							req={pkgReq}
							description="package"
							placeholder={<PkgSettingsPlaceholder />}
						>
							<Card>
								<CardHeader title="General" />
								<PkgSettings horizontal org={org} pkg={pkgReq.body} />
							</Card>
						</FakeSuspense>
					</Box>
					<Grid container spacing={3}>
						<Grid item xs={12} md={4} lg={3}>
							<FakeSuspense
								req={versionsReq}
								description="versions"
								placeholder={<PackageVersionsNavPlaceholder />}
							>
								<Card>
									<CardHeader title="Version History" />
									<Router>
										<PackageVersionsNav versions={versionsReq.body} path="/" />
										<PackageVersionsNav
											versions={versionsReq.body}
											path="/:version"
										/>
									</Router>
								</Card>
							</FakeSuspense>
						</Grid>
						<Grid item xs={12} md={8} lg={9}>
							<FakeSuspense
								req={versionsReq}
								placeholder={<ActiveVersionProcessesTablePlaceholder />}
							>
								<Router>
									<VersionDetails versions={versionsReq.body} path="/" />
									<VersionDetails versions={versionsReq.body} path=":version" />
								</Router>
							</FakeSuspense>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Layout>
	);
};

const VersionDetails = ({ versions, version, ...props }) => {
	const result = useMemo(() => {
		if (!versions || !versions.length) return null;
		let result = versions.find(v => v.version == version);
		if (!result) result = versions[0];

		return result;
	}, [version, versions]);

	if (!result) return null;

	return (
		<>
			<ActiveVersionDetails version={result} {...props} />
			<ProcessesTable version={result} {...props} />
		</>
	);
};

export default PkgDetails;
