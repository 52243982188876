import React from "react";
import {
	List,
	ListItem,
	ListItemText,
	Paper,
	ButtonBase
} from "@material-ui/core";
import { maxBy, sortBy } from "lodash";
import Time from "../time";
import PaperTip from "../paper-tip";

const timeSorter = ({ value }) => new Date(value).getTime();

const LastActivity = ({ node, shutdownReq }) => {
	const allTimes = [
		{ label: "Connected", value: node.connectedAt },
		{ label: "Last Seen", value: node.pingedAt },
		{ label: node.disconnectType, value: node.disconnectedAt },
		{ label: "Shutdown Requested", value: node.shutdownRequestedAt }
	].filter(({ value }) => value);

	const lastActivity = maxBy(allTimes, timeSorter);
	const sortedActivity = sortBy(allTimes, timeSorter).reverse();

	const activitySummary = (
		<Paper elevation={4}>
			<List
				disablePadding
				style={{ backgroundColor: "rgba(255, 255, 255, 0.05)" }}
			>
				{sortedActivity.map(({ label, value }) => (
					<ListItem key={label}>
						<ListItemText primary={label} secondary={<Time value={value} />} />
					</ListItem>
				))}
			</List>
		</Paper>
	);

	return (
		<PaperTip title={activitySummary} placement="left-start">
			<ButtonBase
				focusRipple
				style={{ textAlign: "left", paddingLeft: 8, flexBasis: "50%" }}
			>
				<ListItemText
					primary={
						shutdownReq.isFetching ? "Shutting down" : lastActivity.label
					}
					secondary={
						<>
							<Time value={lastActivity.value} />
						</>
					}
				/>
			</ButtonBase>
		</PaperTip>
	);
};

export default LastActivity;
