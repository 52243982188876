import { useFetch, useLazyFetch } from "react-fetch-hooks";
import { useRunlyConfig } from "@runly/ui";

export const useRemoteCollectionRequests = ({ endpoint, selections }) => {
	const getCollection = useGetCollection(endpoint);
	const postItem = usePostItem(endpoint);
	const getItem = useGetItem(endpoint, selections?.getItem);
	const patchItem = usePatchItem(endpoint, selections?.getItem);
	const deleteItem = useDeleteItem(endpoint, selections?.deleteItem);

	return {
		getCollection,
		postItem,
		getItem,
		patchItem,
		deleteItem
	};
};

const useEndpoint = endpoint => {
	const { url: _url, token: bearerToken } = useRunlyConfig();

	const url = _url && bearerToken ? `${_url}${endpoint}` : null;

	return { url, bearerToken };
};

export const useGetCollection = endpoint => {
	const { url, bearerToken } = useEndpoint(endpoint);
	const getCollectionReq = useFetch(url, {
		method: "GET",
		bearerToken
	});

	return getCollectionReq;
};

export const usePostItem = endpoint => {
	const { url, bearerToken } = useEndpoint(endpoint);
	const postItemReq = useLazyFetch(url, {
		method: "POST",
		bearerToken
	});

	return postItemReq;
};

export const useGetItem = (endpoint, selectedId) => {
	const { url, bearerToken } = useEndpoint(endpoint);
	const getItemReq = useFetch(
		url && selectedId ? `${url}/${selectedId}` : null,
		{
			method: "GET",
			bearerToken
		}
	);

	return getItemReq;
};

export const usePatchItem = (endpoint, selectedId) => {
	const { url, bearerToken } = useEndpoint(endpoint);
	const patchItemReq = useLazyFetch(
		url && selectedId ? `${url}/${selectedId}` : null,
		{
			method: "PATCH",
			bearerToken
		}
	);

	return patchItemReq;
};

export const useDeleteItem = (endpoint, selectedId) => {
	const { url, bearerToken } = useEndpoint(endpoint);
	const deleteItemReq = useLazyFetch(
		url && selectedId ? `${url}/${selectedId}` : null,
		{
			method: "DELETE",
			bearerToken
		}
	);

	return deleteItemReq;
};
