import React from "react";
import { Box, CardActions } from "@material-ui/core";

import { Button } from "@runly/gatsby-theme";

const UpgradeCard = ({ org, role, isAvailable, children }) => {
	if (isAvailable) return null;

	return (
		<Box>
			<Box p={1}>
				Your current plan doesn't allow the <em>{role}</em> role.
				<br />
				You must upgrade your plan to enable setting this role.
			</Box>
			<CardActions style={{ padding: 0 }}>
				{children}
				<Button
					variant="contained"
					color="primary"
					to={`/dashboard/${org}/settings/billing`}
				>
					Upgrade Plan
				</Button>
			</CardActions>
		</Box>
	);
};

export default UpgradeCard;
