import React, { useEffect, useMemo } from "react";
import { Router, Redirect, LocationProvider } from "@reach/router";

import { useLogin } from "@runly/ui";

// yo dawg, I heard you like Providers
import { useOrgs, Provider as OrgProvider } from "./org/context";
import { Provider as AccountProvider } from "./account/context";
import { Provider as LayoutProvider } from "./layout/context";
import useUserProfiles, {
	Provider as UserProfilesProvider
} from "./user-profile/context";

import NewOrg from "./org/new";
import OrgSettings from "./org/settings/";

import DashboardPage from "./dashboard";

import EnvDetailsPage from "./env/details";
import NewEnvironmentPage from "./env/new";

import ClusterDetailsPage from "./cluster/details";
import NewClusterPage from "./cluster/new";

import PkgDetailsPage from "./pkg/details";

import Account from "./account";

import MemberDetails from "./user-profile/details";
import InviteMember from "./member/new";
import AcceptInvitation from "./member/accept-invite";
import ChangeMemberRole from "./member/role/edit";

import RedirectNotification from "./notification/redirect";

// Workaround for https://github.com/reach/router/issues/153
if (process.env.NODE_ENV === "development") {
	import("react-hot-loader").then(({ cold }) => {
		cold(Redirect);
	});
}

const App = () => (
	<UserProfilesProvider>
		<LayoutProvider>
			<>
				<Router>
					<AuthenticatedRoot path="/dashboard/*" />
				</Router>
			</>
		</LayoutProvider>
	</UserProfilesProvider>
);

const AuthenticatedRoot = ({ location }) => {
	const isAuthenticated = useLogin(location, "/auth");

	if (!isAuthenticated) {
		return <DashboardPage location={location} />;
	}

	return (
		<AccountProvider>
			<Router>
				<AcceptInvitation path=":org/invite/:memberId" />
				<NewOrg path="orgs/new/*" />
				<Account path="account/*" />
				<OrgRoot path="/*" />
			</Router>

			<RedirectNotification location={location} />
		</AccountProvider>
	);
};

const OrgRoot = () => (
	<OrgProvider>
		<LocationProvider>
			<Router>
				<RedirectWithoutError path="/" />
				<DashboardPage path=":org" />

				<NewEnvironmentPage path=":org/env/new/*" />
				<EnvDetailsPage path=":org/env/:env/*" />

				<NewClusterPage path=":org/clusters/new/*" />
				<ClusterDetailsPage path=":org/clusters/:cluster/*" />

				<PkgDetailsPage path=":org/pkg/:id/*" />

				<OrgSettings path=":org/settings/*" />

				<InviteMember path=":org/invite" />
				<MemberDetails path=":org/u/:userId" />
				<ChangeMemberRole path=":org/members/:memberId" />
			</Router>
			<Router>
				<OrgRouteToUsersCtx path=":org/*" />
			</Router>
		</LocationProvider>
	</OrgProvider>
);

const RedirectWithoutError = ({ location, navigate }) => {
	const orgs = useOrgs();

	const planId = useMemo(() => getQueryVariable(location, "planId"), [
		location
	]);

	useEffect(() => {
		if (orgs && orgs[0]) {
			if (planId) {
				navigate(
					`/dashboard/${orgs[0].id}/settings/billing/upgrade/${planId}`,
					{
						replace: true
					}
				);
			} else {
				navigate(`/dashboard/${orgs[0].id}`, {
					replace: true
				});
			}
		}
	}, [navigate, orgs, planId]);

	return <DashboardPage location={location} />;
};

function getQueryVariable(location, prop) {
	const query = location.search.substring(1);
	const vars = query.split("&");

	for (let i = 0; i < vars.length; i++) {
		const pair = vars[i].split("=");
		if (decodeURIComponent(pair[0]) == prop) {
			return decodeURIComponent(pair[1]);
		}
	}
}

const OrgRouteToUsersCtx = ({ org }) => {
	const { setOrg } = useUserProfiles();

	useEffect(() => {
		setOrg(org);
	}, [org, setOrg]);
	return null;
};

export default App;
