import React from "react";
import { Helmet } from "react-helmet";

import useMutationHandler from "../mutation-handler";
import FakeSuspense from "../fake-suspense";

import { useAcceptInvitation } from "./api";

const AcceptInvitationPage = ({ org, memberId }) => {
	const req = useAcceptInvitation(org, memberId);

	useMutationHandler({
		req,
		successRedirectUrl: `/dashboard/${org}`,
		successNotification: {
			messageId: "invite-accepted",
			text: `Welcome to the ${org} organization!`
		},
		errorNotification: { verb: "accepting", description: "invitation" }
	});

	return (
		<>
			<Helmet>
				<title>Accept Invitation to {org}</title>
			</Helmet>

			<FakeSuspense req={req} description="Accepting Invitation" />
		</>
	);
};

export default AcceptInvitationPage;
