import React, { useMemo } from "react";
import { Typography, Box } from "@material-ui/core";

import { useFetchRunHistory } from "../../run/api";

import RunsList from "../../run/list";

import { groupBy } from "lodash";
import FakeSuspense from "../../fake-suspense";
import { SkeletonList } from "../../skeleton/list";

const UserEnvRuns = ({ org, userId }) => {
	const runsReq = useFetchRunHistory({ org, query: { user: userId } });
	const results = runsReq?.body?.data;

	const envs = useMemo(() => {
		if (!results?.length) return null;
		return groupBy(results, "environment.id");
	}, [results]);

	if (runsReq.isFetched && !envs) return null;

	return (
		<>
			<Box px={2}>
				<Typography variant="h5" component="h3">
					Runs
				</Typography>
			</Box>
			<FakeSuspense req={runsReq} placeholder={<SkeletonList cols={3} />}>
				<div>
					{envs &&
						Object.keys(envs).map(env => (
							<div key={env}>
								<Box px={2}>
									<Typography variant="h6" component="h3">
										{env}
									</Typography>
								</Box>
								<RunsList {...{ org, env, runs: envs[env] }} />
							</div>
						))}
				</div>
			</FakeSuspense>
		</>
	);
};

export default UserEnvRuns;
