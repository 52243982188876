import React, { useState, useCallback } from "react";
import { Helmet } from "react-helmet";

import {
	Card,
	CardHeader,
	DialogActions,
	CardContent
} from "@material-ui/core";

import { Button } from "@runly/gatsby-theme";

import Layout from "../../layout";
import NavTabs from "../../layout/nav-tabs";

import FakeSuspense from "../../fake-suspense";
import FetchButton from "../../busy-button/fetch-button";

import { useFetchUserProfile } from "../../user-profile/api";
import { useUpdateMember } from "../api";
import { useFetchOrgLimits } from "../../org/api";

import RoleChooser from "./chooser";
import { Role as RoleBlurb } from "../../blurbs";

import { find } from "lodash";
import useMutationHandler from "../../mutation-handler";
import UpgradeCard from "./upgrade-card";

const EditRolePage = ({ org, memberId, location }) => {
	const memberReq = useFetchUserProfile(memberId);
	const orgReq = useFetchOrgLimits(org);

	const returnUrl = location?.state?.returnUrl;

	return (
		<Layout
			org={org}
			location={location}
			navTabs={
				<NavTabs
					tabs={[
						{
							href: returnUrl || `/dashboard/${org}/settings/members`,
							isGoBack: true,
							label: "Members"
						}
					]}
					location={location}
				/>
			}
		>
			<Helmet>
				<title>Change Member Role</title>
			</Helmet>

			<FakeSuspense req={memberReq} description="Member">
				<FakeSuspense req={orgReq} description="Organization">
					<EditMemberRoot
						returnUrl={returnUrl}
						org={org}
						member={memberReq.body}
						limits={orgReq.body}
					/>
				</FakeSuspense>
			</FakeSuspense>
		</Layout>
	);
};

const EditMemberRoot = ({ org, member, limits, returnUrl }) => {
	const [role, setRole] = useState(() =>
		find(limits.roles, { role: member.role })
	);
	const handleChange = useCallback(newRole => setRole(newRole), []);

	const req = useUpdateMember(org, member.id);

	useMutationHandler({
		req: req,
		successRedirectUrl: returnUrl || `/dashboard/${org}/settings/members`,
		successNotification: {
			messageId: member.name,
			text: `Updated ${member.name || member.email}'s role to ${role.role}`
		},
		errorNotification: { verb: "saving", description: "member role" }
	});
	const handleSubmit = useCallback(
		e => {
			e.preventDefault();
			req.fetch({ role: role.role });
		},
		[req, role]
	);

	const cancelButton = (
		<Button to={returnUrl || `/dashboard/${org}/settings/members`}>
			Don't change
		</Button>
	);
	return (
		<Card>
			<CardHeader
				title={`Change Permissions for ${member.name || member.email}`}
				subheader={<RoleBlurb />}
			/>

			<form onSubmit={handleSubmit}>
				<CardContent>
					<RoleChooser
						roles={limits.roles}
						value={role}
						onChange={handleChange}
					/>
				</CardContent>
				<DialogActions>
					{role.isAvailable ? (
						<>
							{cancelButton}
							<FetchButton
								disabled={!role.isAvailable}
								type="submit"
								variant="contained"
								color="primary"
								req={req}
								busyChildren={<>Saving…</>}
							>
								Change role
							</FetchButton>
						</>
					) : (
						<UpgradeCard org={org} {...role}>
							{cancelButton}
						</UpgradeCard>
					)}
				</DialogActions>
			</form>
		</Card>
	);
};

export default EditRolePage;
