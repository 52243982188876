import { useFetch, useLazyFetch } from "react-fetch-hooks";
import useParsedDates from "../parse-dates";
import { useRunlyConfig } from "@runly/ui";

export const useFetchCheckoutPreview = (org, planId) => {
	const { url, token: bearerToken } = useRunlyConfig();

	const { body: checkoutPreview, ...rest } = useFetch(
		url && bearerToken
			? `${url}/${org}/billing/checkout?planId=${planId}`
			: null,
		{
			method: "GET",
			bearerToken
		}
	);

	const result = useParsedDates(checkoutPreview);

	return { checkoutPreview: result, ...rest };
};

export const useCheckout = (org, planId) => {
	const { url, token: bearerToken } = useRunlyConfig();

	const {
		body: checkoutResult,
		isFetching: isCheckingOut,
		isFetched: isCheckedOut,
		error: checkoutError,
		fetch: doCheckout
	} = useLazyFetch(
		url && bearerToken ? `${url}/${org}/billing/checkout` : null,
		{
			method: "POST",
			body: JSON.stringify({ planId }),
			bearerToken
		}
	);

	const result = useParsedDates(checkoutResult);

	return {
		checkout: result,
		doCheckout,
		isCheckingOut,
		isCheckedOut,
		checkoutError
	};
};
