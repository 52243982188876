import { useEffect, useReducer, useMemo } from "react";
import { orderBy } from "lodash";
import { useChannel, useRunlyConfig } from "@runly/ui";

const reducer = (state, { type, payload }) => {
	switch (type) {
		case "cluster_status":
			return { ...state, cluster: payload };

		case "all_nodes": {
			const nodes = {};

			payload.forEach(run => {
				nodes[run.id] = run;
			});

			return { ...state, nodes, nodesLoaded: true };
		}

		case "node_status": {
			const nodes = {
				...state.nodes,
				[payload.id]: payload
			};

			return { ...state, nodes };
		}

		default:
			return state;
	}
};

const useClusterConnection = ({ org, cluster: clusterId }) => {
	const { url, token } = useRunlyConfig();

	const { connection, ...connectionState } = useChannel(
		`${url}/${org}/clusters/${clusterId}/channel`,
		token
	);

	const [{ nodes, nodesLoaded, cluster }, dispatch] = useReducer(reducer, {
		nodes: {},
		nodesLoaded: false
	});

	useEffect(() => {
		if (connection) {
			connection.on("Nodes", nodes => {
				dispatch({ type: "all_nodes", payload: nodes });
			});

			connection.on("NodeStatus", node => {
				dispatch({ type: "node_status", payload: node });
			});

			connection.on("ClusterStatus", cluster => {
				dispatch({ type: "cluster_status", payload: cluster });
			});
		}
	}, [connection]);

	const sortedNodes = useMemo(() => {
		if (!nodes) return nodes;

		let sortedNodes = null;
		for (let nodeId in nodes) {
			if (!Array.isArray(sortedNodes)) {
				sortedNodes = [];
			}
			sortedNodes.push(nodes[nodeId]);
		}

		sortedNodes = orderBy(sortedNodes, ["connectedAt"], ["desc"]);

		return sortedNodes;
	}, [nodes]);

	return {
		cluster,
		nodes: sortedNodes,
		nodesLoaded,
		...connectionState
	};
};

export default useClusterConnection;
