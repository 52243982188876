import React from "react";
import { Router } from "@reach/router";

import { Card, CardActions, List, CardHeader } from "@material-ui/core";
import { Settings as SettingsIcon, Lock as LockIcon } from "@material-ui/icons";

import { Button } from "@runly/gatsby-theme";

import SettingsItem from "../../settings-property";

import ChangePassword from "./password";
import EditPersonalInfo from "./personal-info";

const AccountSettings = ({ user, onChange }) => (
	<Router>
		<Readonly path="/" user={user} />
		<EditPersonalInfo path="info" user={user} onChange={onChange} />
		<ChangePassword path="password" />
	</Router>
);

const Readonly = ({ user }) => (
	<Card>
		<CardHeader
			title="Profile"
			subheader={`Your personal information${
				!user.canEdit
					? " is managed by your identity provider. Login there to change your information."
					: ""
			}`}
		/>
		<List>
			<SettingsItem title="Name">{user.name}</SettingsItem>
			<SettingsItem title="Email">{user.email}</SettingsItem>
		</List>
		{user.canEdit ? (
			<CardActions>
				<Button
					variant="outlined"
					to="/dashboard/account/info"
					startIcon={<SettingsIcon />}
				>
					Edit Settings
				</Button>
				<Button
					variant="outlined"
					to="/dashboard/account/password"
					startIcon={<LockIcon />}
				>
					Change Password
				</Button>
			</CardActions>
		) : null}
	</Card>
);

export default AccountSettings;
