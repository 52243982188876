import {
	useFetchEnvironment,
	useUpdateEnvironment,
	useDeleteEnvironment
} from "../api";

import useMutationHandler from "../../mutation-handler";

const useEnvState = (org, env) => {
	const fetchReq = useFetchEnvironment(org, env);
	const patchReq = useUpdateEnvironment(org, env);
	const deleteReq = useDeleteEnvironment(org, env);

	const originalId = fetchReq?.body?.id;
	const savedId = patchReq?.body?.id;
	const idChanged = savedId && savedId != originalId;

	useMutationHandler({
		req: patchReq,
		successRedirectUrl: idChanged
			? `/dashboard/${org}/env/${savedId}/settings`
			: null,
		successNotification: idChanged
			? {
					severity: "info",
					text: `ID updated from ${fetchReq.body.id} to ${patchReq.body.id}.`
			  }
			: null,
		errorNotification: { verb: "saving", description: `${env} settings` }
	});

	useMutationHandler({
		req: deleteReq,
		successRedirectUrl: `/dashboard/${org}`,
		successNotification: {
			severity: "info",
			text: `Deleted ${env}.`
		},
		errorNotification: { verb: "deleting", description: "environment" }
	});

	return {
		fetchReq,
		patchReq,
		deleteReq
	};
};

export default useEnvState;
