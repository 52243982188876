import React, { useState, useCallback } from "react";
import {
	ListItem,
	ListItemText,
	TextField,
	ListItemSecondaryAction,
	InputAdornment,
	Box,
	Tooltip,
	Collapse
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import CopyAppApiKeyButton from "./copy-button";
import DateTime from "../../time";
import { makeStyles } from "@material-ui/core/styles";
import { BusyIconButton } from "../../busy-button";

const useStyles = makeStyles(theme => {
	return {
		keysRoot: {
			alignItems: "start",
			flexDirection: "column",
			[theme.breakpoints.up("md")]: { flexDirection: "row" }
		}
	};
});

export const AppItem = ({
	name,
	secretKey,
	publishableKey,
	createdAt,
	lastUsedAt,
	listItemProps,
	deleteButtonProps,
	isItemDeleting,
	_justCreated,
	style
}) => {
	const [isOpen, setOpen] = useState(_justCreated);
	const handleClick = useCallback(() => setOpen(o => !o), []);

	const classes = useStyles();

	return (
		<Box style={style}>
			<ListItem
				{...listItemProps}
				button
				onClick={handleClick}
				className={classes.root}
			>
				<ListCol primary={name} />

				<ListCol
					primary={
						<>
							Created <DateTime value={createdAt} />
						</>
					}
					secondary={
						lastUsedAt ? (
							<>
								Last Used <DateTime value={lastUsedAt} />
							</>
						) : (
							<>Never Used</>
						)
					}
				/>
				<ListItemSecondaryAction>
					<Tooltip title="Delete key">
						<BusyIconButton
							isBusy={isItemDeleting}
							size="medium"
							{...deleteButtonProps}
						>
							<DeleteIcon />
						</BusyIconButton>
					</Tooltip>
				</ListItemSecondaryAction>
			</ListItem>
			{secretKey && publishableKey ? (
				<Collapse in={isOpen} timeout="auto" unmountOnExit>
					<ListItem
						disableGutters
						{...listItemProps}
						selected
						className={classes.keysRoot}
					>
						<KeyCol name={name} label="Secret Key" apiKey={secretKey}>
							Secret keys should be used server-side and{" "}
							<strong>should not be publicly exposed</strong>.
						</KeyCol>
						<KeyCol name={name} label="Publishable Key" apiKey={publishableKey}>
							Publishable keys are safe to use in client-side applications.
						</KeyCol>
					</ListItem>
				</Collapse>
			) : null}
		</Box>
	);
};

const KeyCol = ({ name, label, apiKey, children }) => {
	const { handleApiKeyFocus } = useAppItem();

	return (
		<Box px={2} width="100%">
			<ListCol>
				<TextField
					fullWidth
					onFocus={handleApiKeyFocus}
					variant="outlined"
					label={label}
					value={apiKey}
					inputProps={{ readonly: true }}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<CopyAppApiKeyButton name={`${name} ${label}`} value={apiKey} />
							</InputAdornment>
						)
					}}
					helperText={<Box pb={4}>{children}</Box>}
				/>
			</ListCol>
		</Box>
	);
};

export const ListCol = props => {
	const classes = useListColStyles();
	return <ListItemText className={classes.root} {...props} />;
};

const useListColStyles = makeStyles({ root: { flexBasis: "33%" } });

const useAppItem = () => {
	const handleApiKeyFocus = useCallback(e => {
		e?.target?.select?.();
	}, []);

	return {
		handleApiKeyFocus
	};
};
