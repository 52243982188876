import React, { useCallback } from "react";

import {
	Card,
	DialogActions,
	CardHeader,
	CardContent
} from "@material-ui/core";

import { Button } from "@runly/gatsby-theme";

import FetchButton from "../../busy-button/fetch-button";

import { useUpdateAccount } from "../api";

const AccountPassword = () => {
	const { fetch: save, ...req } = useUpdateAccount();

	const handleSubmit = useCallback(
		ev => {
			ev.preventDefault();
			save({ name: "Bob Wilson" });
		},
		[save]
	);

	return (
		<form onSubmit={handleSubmit}>
			<Card>
				<CardHeader
					title="Change Password"
					subheader="Change the password you use to login to Runly."
				/>
				<CardContent>do it</CardContent>
				<DialogActions>
					<Button to="/dashboard/account">Don't change</Button>
					<FetchButton
						type="submit"
						variant="contained"
						color="primary"
						req={req}
						busyChildren={<>Saving…</>}
					>
						Change Password
					</FetchButton>
				</DialogActions>
			</Card>
		</form>
	);
};

export default AccountPassword;
