import { useFetch } from "react-fetch-hooks";
import { useRunlyConfig } from "@runly/ui";

export const useFetchJobs = org => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useFetch(url && bearerToken && org ? `${url}/${org}/jobs/` : null, {
		method: "GET",
		bearerToken
	});
};

export const useFetchJobDetails = (org, id) => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useFetch(
		url && bearerToken && org && id ? `${url}/${org}/jobs/${id}` : null,
		{
			method: "GET",
			bearerToken
		}
	);
};
