import React from "react";

import { Button, ButtonGroup, SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import DropzoneIndicator from "../../../pkg/upload/drop-zone-indicator";

import { ReactComponent as FileUploadIcon } from "../../../assets/file-upload.svg";
import { mdiPlaylistPlus } from "@mdi/js";
import RefableLink from "../../../refable-link";

const QueueProcess = ({
	org,
	env,
	location,
	dropzoneProps: { getInputProps, ...dropzone }
}) => {
	const classes = useStyles();

	return (
		<>
			<input {...getInputProps()} />
			<ButtonGroup variant="text">
				<Button
					component={RefableLink}
					to={`/dashboard/${org}/env/${env}/runs/new`}
					state={{ returnUrl: location.pathname }}
					title="Queue new job"
					aria-label="Queue new job"
				>
					<SvgIcon>
						<path d={mdiPlaylistPlus} />
					</SvgIcon>
				</Button>
				<Button
					onClick={dropzone.onClick}
					title="Upload config"
					aria-label="Upload config"
				>
					<FileUploadIcon className={classes.fileIcon} />
				</Button>
			</ButtonGroup>
			<DropzoneIndicator {...dropzone} />
		</>
	);
};

const useStyles = makeStyles(theme => ({
	fileIcon: {
		fontSize: theme.typography.pxToRem(24)
	}
}));

export default QueueProcess;
