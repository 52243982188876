import React, { useMemo } from "react";

import { Grid } from "@material-ui/core";
import { Link } from "@runly/gatsby-theme";

import Layout from "../../layout";
import NavTabs from "../../layout/nav-tabs";
import DeepNav from "../../layout/deep-nav";

import { useCanEditCluster } from "../../org/permissions";

const ClusterLayout = ({
	org,
	cluster,
	location,
	header,
	children,
	...rest
}) => {
	const returnContext = location.state?.clusterReturn;

	const canEditCluster = useCanEditCluster(org);

	const backTab = useMemo(
		() => ({
			href: returnContext?.url ? returnContext.url : `/dashboard/${org}`,
			isGoBack: true,
			linkState: location.state,
			label: returnContext?.label ? returnContext.label : "Dashboard"
		}),
		[location.state, org, returnContext]
	);

	const nav = useMemo(
		() => [
			{
				href: `/dashboard/${org}/clusters/${cluster}`,
				label: "Overview",
				state: location.state
			},
			{
				href: `/dashboard/${org}/clusters/${cluster}/settings`,
				label: "Settings",
				state: location.state,
				disabled: !canEditCluster,
				disabledReason: canEditCluster
					? null
					: "You do not have permission to edit this cluster"
			}
		],
		[org, cluster, location.state, canEditCluster]
	);

	return (
		<Layout
			org={org}
			location={location}
			navTabs={<NavTabs tabs={[backTab]} location={location} />}
			breadCrumb={
				<>
					<Link to={`/dashboard/${org}/clusters/${cluster}/`} color="inherit">
						{cluster}
					</Link>
				</>
			}
			{...rest}
		>
			{header}

			<Grid container spacing={3}>
				<Grid item xs={12} sm={3}>
					<DeepNav location={location} tabs={nav} />
				</Grid>

				<Grid item xs={12} sm={9}>
					{children}
				</Grid>
			</Grid>
		</Layout>
	);
};

export default ClusterLayout;
