import React, { memo } from "react";
import { useLocation } from "@reach/router";

import {
	ListItem,
	ListItemText,
	ListItemSecondaryAction, // ListItemAvatar
	ListItemAvatar
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import RunProgress from "./progress/icon";
import RunStatus from "./status";
import RefableLink from "../refable-link";
import RunItemMenu from "./menu";
import Duration from "../time/duration";
import { useCancelRun } from "./detail/api";

import UserActivity from "../user-profile/activity";
import DateTime from "../time";
import { useCanQueue } from "../org/permissions";
import { words, upperFirst } from "lodash";

const RunListItem = memo(
	({ ListItemProps: _ListItemProps = {}, org, env, run }) => {
		const classes = useStyles();
		const canceler = useCancelRun({ org, runId: run.id });

		const canQueue = useCanQueue(org, run.environment?.isProduction);

		const ListItemProps = {
			button: true,
			display: "block",

			disabled: canceler.isFetching,
			className: classes.root,
			..._ListItemProps
		};

		const duration = run.startedAt ? (
			<>
				{run.completedAt ? "Took" : ""}{" "}
				<Duration start={run.startedAt} end={run.completedAt} />{" "}
				{run.completedAt ? "" : "elapsed"}
			</>
		) : (
			"\u00A0"
		);

		const location = useLocation();

		return (
			<>
				<ListItem button {...ListItemProps}>
					<RefableLink
						to={`/dashboard/${org}/env/${run.environment.id}/runs/${run.id}`}
						state={{ searchUrl: `${location.pathname}${location.search}` }}
						style={{
							position: "absolute",
							top: 0,
							right: 0,
							bottom: 0,
							left: 0,
							zIndex: 0
						}}
					>
						{" "}
					</RefableLink>
					<ListItemAvatar>
						<RunProgress
							completedAt={run.completedAt}
							{...run.progress}
							state={run.state}
						/>
					</ListItemAvatar>
					<TextCol
						primary={wrapLong(run.job.name)}
						secondary={`v${run.job.version}`}
					/>
					<TextCol
						primary={
							run.cancellationRequestedAt ? (
								<UserActivity
									org={org}
									verb="cancelled"
									at={run.cancellationRequestedAt}
									by={run.cancellationRequestedBy}
								/>
							) : (
								<UserActivity
									org={org}
									verb="queued"
									at={run.enqueuedAt}
									by={run.enqueuedBy}
								/>
							)
						}
						secondary={
							run.completedAt && !run.cancellationRequestedAt ? (
								<>
									Completed <DateTime value={run.completedAt} />
								</>
							) : null
						}
					/>
					<TextCol primary={<RunStatus {...run} />} secondary={duration} />
					{canQueue ? (
						<ListItemSecondaryAction>
							<RunItemMenu {...{ canceler, org, env, run, location }} />
						</ListItemSecondaryAction>
					) : null}
				</ListItem>
			</>
		);
	}
);

const useStyles = makeStyles(() => {
	return {
		root: { flexWrap: "wrap" }
	};
});

const typographyProps = { component: "div" };

const TextCol = props => {
	const classes = useColStyles();
	return (
		<ListItemText
			className={classes.col}
			primaryTypographyProps={typographyProps}
			secondaryTypographyProps={typographyProps}
			{...props}
		/>
	);
};
const useColStyles = makeStyles(theme => {
	return {
		col: {
			flexBasis: "50%",
			paddingRight: theme.spacing(1),
			[theme.breakpoints.up("md")]: {
				flexBasis: "25%"
			},
			"& a": {
				position: "relative",
				zIndex: 1
			}
		}
	};
});

const wrapLong = s => words(upperFirst(s)).join("\u200b");

export default RunListItem;
