import React, { useCallback } from "react";
import { Chip, Avatar, IconButton, Box } from "@material-ui/core";
import EnvIcon from "./icon";
import RefableLink from "../refable-link";
import { Settings as SettingsIcon } from "@material-ui/icons";

const EnvChip = ({
	org,
	id,
	isProduction,
	settingsLink = `/dashboard/${org}/env/${id}/settings`,
	settingsTitle = "Configure settings"
}) => {
	const linkHandler = useCallback(() => true, []);
	return (
		<Chip
			component={RefableLink}
			to={`/dashboard/${org}/env/${id}`}
			icon={
				isProduction === undefined ? null : (
					<Avatar>
						<EnvIcon {...{ isProduction }} />
					</Avatar>
				)
			}
			variant="outlined"
			size="small"
			label={<Box px={0.5}>{id}</Box>}
			clickable
			onDelete={linkHandler}
			deleteIcon={
				<IconButton
					component={RefableLink}
					to={settingsLink}
					title={settingsTitle}
				>
					<SettingsIcon />
				</IconButton>
			}
		/>
	);
};

export default EnvChip;
