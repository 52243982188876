import { useReducer, useCallback } from "react";

const reducer = (state, { type, payload }) => {
	switch (type) {
		case "add_env":
			return { ...state, environments: [...state.environments, payload.env] };
		case "remove_env":
			return {
				...state,
				environments: state.environments.filter(c => c !== payload.env)
			};
		case "change":
			return { ...state, [payload.key]: payload.value };

		default:
			return state;
	}
};

const useNewClusterState = preSelectedEnv => {
	const [state, dispatch] = useReducer(reducer, {
		environments: preSelectedEnv ? [preSelectedEnv] : []
	});

	const onChange = useCallback(
		(key, value) => dispatch({ type: "change", payload: { key, value } }),
		[]
	);

	const onToggleEnv = useCallback(
		env => evt => {
			dispatch({
				type: evt.target.checked ? "add_env" : "remove_env",
				payload: { env }
			});
		},
		[]
	);

	const onChangeId = useCallback(value => onChange("id", value), [onChange]);

	return {
		onChangeId,
		onToggleEnv,
		state
	};
};

export default useNewClusterState;
