import React from "react";
import { ListItem, ListItemText } from "@material-ui/core";

const SettingsProperty = ({ title, children, listItemProps }) => (
	<ListItem {...listItemProps}>
		<ListItemText
			primary={title}
			secondary={children}
			primaryTypographyProps={{ color: "textSecondary", component: "h3" }}
			secondaryTypographyProps={{ color: "textPrimary", component: "div" }}
		/>
	</ListItem>
);

export default SettingsProperty;
