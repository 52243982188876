import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField, ListItem, ListItemText } from "@material-ui/core";
import GrowPopper from "../../grow-popper";

import { isString } from "lodash";

const ProcessOption = ({ version }) => (
	<ListItem component="div" disableGutters>
		<ListItemText primary={version} />
	</ListItem>
);

const VersionPicker = ({ process, value, onChange, disabled }) => {
	if (process?.versions) {
		return (
			<Autocomplete
				disabled={disabled}
				PopperComponent={GrowPopper}
				onChange={onChange}
				value={value}
				options={process.versions}
				getOptionLabel={o => (isString(o) ? o : o.version)}
				renderOption={ProcessOption}
				renderInput={params => (
					<TextField {...params} label="Version" variant="outlined" fullWidth />
				)}
				getOptionSelected={(option, value) =>
					option.version && isString(value)
						? option.version == value
						: option.version == value.version
				}
			/>
		);
	}
	return <TextField variant="outlined" disabled fullWidth label="Version" />;
};

export default VersionPicker;
