import React from "react";

import {
	Paper,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Typography
} from "@material-ui/core";

import { format as formatDate } from "date-fns";
import { formatMoney } from "accounting";

import PaymentMethod from "../payment-method";

const PaymentsRoot = ({ payments }) => {
	return (
		<>
			<Typography gutterBottom variant="h4" component="h1">
				Payment History
			</Typography>
			<Paper>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Date</TableCell>
							<TableCell>Description</TableCell>
							<TableCell>Method</TableCell>
							<TableCell align="right">Amount</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{payments.map(payment => (
							<TableRow key={payment.id} style={{ verticalAlign: "top" }}>
								<TableCell component="th" scope="row">
									{formatDate(payment.createdOn, "yyyy-MM-dd h:mm a")}
								</TableCell>
								<TableCell>{payment.description}</TableCell>
								<TableCell>
									<PaymentMethod
										typographyProps={{ variant: "body2" }}
										paymentMethod={payment.method}
									/>
								</TableCell>
								<TableCell align="right">
									{formatMoney(payment.amount)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Paper>
		</>
	);
};

export default PaymentsRoot;
