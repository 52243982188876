import React, { useCallback, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
	Typography,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableRow,
	CardContent,
	DialogActions,
	Collapse
} from "@material-ui/core";
import { KeyboardBackspace as KeyboardBackspaceIcon } from "@material-ui/icons";

import { Button } from "@runly/gatsby-theme";

import PaymentMethod, {
	PaymentMethodChrome
} from "../../../billing/payment-method";

import FetchButton from "../../../busy-button/fetch-button";

const StepContent = ({
	name,
	plan,
	onSubmit,
	req,
	paymentMethod,
	setPaymentMethod
}) => {
	const classes = useStyles();
	const tableCellClasses = useTableCellStyles();

	const [isEditingCard, setEditCard] = useState(false);
	const toggleEditCard = useCallback(() => setEditCard(b => !b), []);

	const onPaymentMethodSaved = useCallback(
		pm => {
			setPaymentMethod(pm);
			setEditCard(false);
		},
		[setPaymentMethod]
	);

	return (
		<>
			<CardContent>
				<Typography variant="h5" component="h2">
					Summary for {name}
				</Typography>

				<Table className={classes.table}>
					<TableBody>
						<TableRow>
							<TableCell component="th" scope="row">
								{plan.name} Plan
							</TableCell>
							<TableCell align="right">${plan.price}/mo</TableCell>
						</TableRow>
					</TableBody>
					<TableFooter>
						<TableRow>
							<TableCell component="th" scope="row" classes={tableCellClasses}>
								Total Due Today
							</TableCell>
							<TableCell align="right" classes={tableCellClasses}>
								${plan.price}
							</TableCell>
						</TableRow>
					</TableFooter>
				</Table>

				<PaymentMethodChrome isEditing={isEditingCard}>
					<PaymentMethod
						paymentMethod={paymentMethod?.card}
						isEditing={isEditingCard}
						onCancelEdit={toggleEditCard}
						onSaved={onPaymentMethodSaved}
					/>
				</PaymentMethodChrome>
				<Collapse in={!isEditingCard}>
					<Button
						variant={!paymentMethod ? "contained" : "outlined"}
						color="primary"
						onClick={toggleEditCard}
					>
						{!paymentMethod ? "Add Payment Method" : "Update Payment Method"}
					</Button>
				</Collapse>
			</CardContent>

			<Collapse in={!isEditingCard}>
				<DialogActions>
					<Button to="/dashboard/orgs/new/plan">
						<KeyboardBackspaceIcon />
						Back
					</Button>
					<FetchButton
						variant="contained"
						color="primary"
						req={req}
						busyChildren={<>Saving…</>}
						disabled={!paymentMethod}
						onClick={onSubmit}
					>
						Create Organization
					</FetchButton>
				</DialogActions>
			</Collapse>
		</>
	);
};

StepContent.label = "Review & Payment";
StepContent.slug = "payment";

const useStyles = makeStyles(theme => ({
	table: {
		margin: theme.spacing(3, 0)
	}
}));

const useTableCellStyles = makeStyles(theme => ({
	footer: {
		color: theme.palette.text.primary,
		...theme.typography.h6
	}
}));

export default StepContent;
