import React from "react";

const ticksInSecond = 10000000;
const ticksInMs = ticksInSecond / 1000;

const Duration = ({ ticks }) => {
	let ms = Math.round(ticks / ticksInMs);
	if (ms == 0) ms = 1;

	let s = Math.floor(ms / 1000);
	ms = ms % 1000;
	let m = Math.floor(s / 60);
	s = s % 60;
	let h = Math.floor(m / 60);
	m = m % 60;

	if (h == 0 && m == 0 && s == 0) {
		// only show the milliseconds
		return <span title={getWords({ h, m, s, ms })}>{ms} ms</span>;
	}

	if (h == 0 && m == 0) {
		// only show the seconds (but with precision)
		s = Math.round((ticks / ticksInSecond) * 10) / 10;
		return <span title={getWords({ h, m, s })}>{s} s</span>;
	}

	return (
		<span title={getWords({ h, m, s })}>
			{pad(h, 2)}:{pad(m, 2)}:{pad(s, 2)}
		</span>
	);
};

const getWords = ({ h, m, s, ms }) => {
	let result = [];

	if (ms > 0) {
		result.unshift(`${ms} millisecond${ms != 1 ? "s" : ""}`);
	}

	if (s > 0) {
		result.unshift(`${s} second${s != 1 ? "s" : ""}`);
	}

	if (m > 0) {
		result.unshift(`${m} minute${m != 1 ? "s" : ""}`);
	}

	if (h > 0) {
		result.unshift(`${h} hour${h != 1 ? "s" : ""}`);
	}

	return result.join(", ");
};

function pad(number, size) {
	number = `0${number}`;
	return number.substr(number.length - size);
}

export default Duration;
