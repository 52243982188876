import React, { useCallback, useState } from "react";

import { TextField, Typography } from "@material-ui/core";

import { kebabCase } from "lodash";

const SlugEditor = ({
	maxLength = 32,
	label = "ID",
	value,
	onChange: onRootChange,
	...props
}) => {
	value = value || "";

	const [enteredValue, setEnteredValue] = useState(value);

	const onChange = useCallback(
		evt => {
			setEnteredValue(evt.target.value);

			const slug = kebabCase(evt.target.value).substr(0, maxLength);
			onRootChange(slug);
		},
		[maxLength, onRootChange]
	);

	return (
		<>
			<Typography component="div">
				<TextField
					id="id-slug"
					label={label}
					value={enteredValue}
					onChange={onChange}
					margin="normal"
					required
					helperText={
						enteredValue !== value ? (
							<>
								Will appear as <strong>{value}</strong>
							</>
						) : (
							"\u00A0"
						)
					}
					{...props}
					inputProps={{ ...(props.inputProps || {}), maxLength }}
				/>
			</Typography>
		</>
	);
};

export default SlugEditor;
