import React, { memo, useState, useMemo } from "react";
import { Box, List } from "@material-ui/core";
import { sortBy } from "lodash";
import { CategoryBar } from "./bar";
import { CategoryLabel } from "./label";
import { useStyles } from "./styles";
import { useCategory } from "./use-category";

const ProgressCategories = memo(
	({ categories: _categories, total, success, failed }) => {
		const [activeCategory, setActiveCategory] = useState(null);
		const { onDeselect } = useCategory({ setActiveCategory });

		const classes = useStyles();

		const notProcessed = total
			? {
					count: total - (success + failed),
					isSuccessful: null,
					category: "Not processed"
			  }
			: null;

		const categories = useMemo(() => {
			if (!_categories) return null;

			const items = sortBy(_categories, [
				c => !c.isSuccessful,
				c => c.category,
				c => -c.count
			]);

			if (notProcessed) {
				items.push(notProcessed);
			}

			return items.filter(c => c.count);
		}, [_categories, notProcessed]);

		if (!categories) return null;

		const itemProps = {
			setActiveCategory,
			activeCategory
		};

		return (
			<>
				<List disablePadding onMouseLeave={onDeselect}>
					{categories.map(c => (
						<CategoryLabel key={c.category} {...c} {...itemProps} />
					))}
				</List>
				<div className={classes.barContainer}>
					<Box display="flex" mx={-0.25}>
						{categories.map(c => (
							<CategoryBar
								classes={classes}
								key={c.category}
								{...c}
								{...itemProps}
								{...{ total }}
							/>
						))}
					</Box>
				</div>
			</>
		);
	}
);

export default ProgressCategories;
