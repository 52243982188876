import { useEffect, useCallback, useState } from "react";
import { useSuccessTransition } from "../../../mutation-handler";
import { navigate } from "@reach/router";
import { useAllowRestrictedAppsEditor } from "./restricted-apps";
import { useScheduleEditorState } from "./schedule";

export const useJobTemplateEditor = ({
	isNew,
	org,
	env,
	id: _id,
	requests,
	handlers,
	configEditor
}) => {
	const [id, setId] = useState(_id);
	const restrictedAppsState = useAllowRestrictedAppsEditor(configEditor);
	const scheduleEditor = useScheduleEditorState();

	const handleGetItem = handlers.getItem;
	useEffect(() => {
		if (!isNew) {
			handleGetItem(_id);
			return () => {
				handleGetItem(null);
			};
		}
	}, [_id, handleGetItem, id, isNew]);

	useEffect(() => {
		configEditor.resetDirty();
		if (isNew) {
			configEditor.setValue("");
		} else {
			configEditor.setValue("// loading configuration…");
		}
		return () => {
			configEditor.resetDirty();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isJustFetched = useSuccessTransition(requests.getItem);
	useEffect(() => {
		if (isJustFetched && requests.getItem.body) {
			configEditor.setValue(
				JSON.stringify(requests.getItem.body.config, null, 2)
			);
			restrictedAppsState.setValue(requests.getItem.body.allowRestrictedApps);
			scheduleEditor.setValue(requests.getItem.body.schedule);
		}
	}, [
		configEditor,
		isJustFetched,
		requests.getItem.body,
		restrictedAppsState,
		scheduleEditor
	]);

	const saveRequest = isNew ? requests.postItem : requests.patchItem;
	const handleSave = useCallback(() => {
		const savePayload = getSavePayload({
			id,
			scheduleEditor,
			configEditor,
			allowRestrictedApps: restrictedAppsState.value
		});
		saveRequest.fetch(savePayload);
	}, [
		configEditor,
		id,
		restrictedAppsState.value,
		saveRequest,
		scheduleEditor
	]);
	const isSaveDisabled =
		!(configEditor.value || "").trim() ||
		configEditor.hasValidationErrors ||
		!configEditor.isDirty
			? true
			: undefined;

	const isJustPatched = useSuccessTransition(requests.patchItem);
	const isJustPosted = useSuccessTransition(requests.postItem);
	useEffect(() => {
		if (isJustPatched || isJustPosted) {
			navigate(`/dashboard/${org}/env/${env}/templates`);
		}
	}, [env, isJustPatched, isJustPosted, org]);

	return {
		handleSave,
		saveRequest,
		isSaveDisabled,
		id,
		setId,
		restrictedAppsState,
		scheduleEditor,
		handleAllowRestrictedAppsChange: restrictedAppsState.handleValueChange
	};
};
const getSavePayload = ({
	id,
	scheduleEditor,
	configEditor,
	allowRestrictedApps
}) => {
	let config;
	try {
		config = JSON.parse(configEditor.value);
	} catch (e) {
		config = {};
	}

	return {
		id,
		job: {
			type: configEditor.selectedProcess?.type,
			version: configEditor.selectedVersion?.version
		},
		schedule: scheduleEditor.value,
		config,
		allowRestrictedApps
	};
};
