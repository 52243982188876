import React, { useCallback } from "react";

import ReadonlySettings from "./readonly";
import EditSettings from "./edit";
import { Helmet } from "react-helmet";
import { useFormState, FORM_ACTIONS } from "./state";

const { READ, EDIT } = FORM_ACTIONS;

// only deal with the fields that we are updating
const trimEnv = env => ({
	description: env.description,
	isProduction: env.isProduction,
	versionRange: env.versionRange
});

const EnvSettings = ({ org, environment, envState }) => {
	const { env, mode, ...actions } = useFormState(org, environment);

	const save = envState.patchReq.fetch;
	const onDelete = envState.deleteReq.fetch;

	const onSaveSettings = useCallback(() => save(trimEnv(env)), [env, save]);
	const onSaveId = useCallback(() => save({ id: env.id }), [env.id, save]);

	let result = null;

	const { patchReq, deleteReq } = envState;

	switch (mode) {
		case READ:
			result = (
				<ReadonlySettings
					org={org}
					oldEnv={environment}
					environment={env}
					{...actions}
					onSaveId={onSaveId}
					onDelete={onDelete}
					{...{ patchReq, deleteReq }}
				/>
			);
			break;

		case EDIT:
			result = (
				<EditSettings
					environment={env}
					{...actions}
					envState={envState}
					onSave={onSaveSettings}
				/>
			);
			break;
	}

	return (
		<>
			<Helmet>
				<title>General</title>
			</Helmet>
			{result}
		</>
	);
};

export default EnvSettings;
