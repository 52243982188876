import React from "react";

import { Card, CardHeader, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CloudDownload as CloudDownloadIcon } from "@material-ui/icons";

import { usePackageDownloadUrl } from "../pkg-version/api";

import { Link } from "@runly/gatsby-theme";
import PackageIcon from "../assets/package-icon";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles(() => {
	return { content: { width: "70%" } };
});

const JobCard = ({ job, ...props }) => {
	const downloadUrl = usePackageDownloadUrl(
		job?.org,
		job?.package,
		job?.version
	);

	const classes = useStyles();

	return (
		<Card {...props}>
			<CardHeader
				avatar={<PackageIcon style={{ width: 40, height: 40 }} />}
				disableTypography
				classes={{ content: classes.content }}
				title={
					job ? (
						<Typography variant="body2" noWrap title={job.type}>
							<Link
								to={`/dashboard/${job.org}/pkg/${job.package}/`}
								color="inherit"
							>
								{job.type}
							</Link>
						</Typography>
					) : (
						<Skeleton width="70%" height={10} />
					)
				}
				subheader={
					job ? (
						<Typography
							variant="body2"
							color="textSecondary"
							noWrap
							title={`${job.package} v${job.version}`}
						>
							{job.package} v{job.version}
						</Typography>
					) : (
						<Skeleton width="50%" height={10} />
					)
				}
				action={
					<IconButton
						size="medium"
						aria-label="download package"
						title="Download Package"
						href={downloadUrl}
					>
						<CloudDownloadIcon />
					</IconButton>
				}
			/>
		</Card>
	);
};

export default JobCard;
