import React from "react";
import { ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => {
	return {
		root: {
			flexBasis: "25%",
			marginRight: theme.spacing(3)
		},
		primaryTypography: {
			whiteSpace: "nowrap",
			display: "block",
			overflow: "hidden",
			textOverflow: "ellipsis"
		}
	};
});

const NodeName = ({ node }) => {
	const classes = useStyles();
	return (
		<ListItemText
			className={classes.root}
			primaryTypographyProps={{
				className: classes.primaryTypography,
				title: node.machine
			}}
			primary={node.machine}
			secondary={`v${node.clientVersion}`}
		/>
	);
};

export default NodeName;
