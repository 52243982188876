import React, { useMemo } from "react";

import {
	Table,
	TableBody,
	TableHead,
	TableRow,
	TableCell,
	Card,
	CardHeader,
	CardContent,
	Paper
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";

import { sortBy } from "lodash";

import DocLink from "../../doc-link";
import EmptyMessage from "../../empty-message";
import { FadeIn } from "../../skeleton/fade-in";
import { SkeletonListItem, SkeletonList } from "../../skeleton/list";
import { SkeletonCardHeader } from "../../skeleton/card";

const PackageProcessesTable = ({ version }) => (
	<Card>
		<CardHeader title={`v${version.version} Jobs`} />
		<PackageProcessContent version={version} />
	</Card>
);

const PackageProcessContent = ({ version }) => {
	const jobs = useMemo(() => sortBy(version.jobs, "name"), [version.jobs]);

	if (version.errorMessage) {
		return (
			<CardContent>
				<Alert severity="error" variant="filled">
					{version.errorMessage}
				</Alert>
			</CardContent>
		);
	}

	if (version.status == "Pending") {
		return (
			<CardContent>
				<EmptyMessage>
					This package has not been processed yet. Make sure you have a node
					connected to your default environment.
					<br />
					<DocLink href="/packages/inspector/">Learn more</DocLink>
				</EmptyMessage>
			</CardContent>
		);
	}

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>Name</TableCell>
					<TableCell>Type</TableCell>
					<TableCell>Config Type</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{jobs.map(p => (
					<TableRow key={p.id}>
						<TableCell>{p.name}</TableCell>
						<TableCell>
							<code>{p.type}</code>
						</TableCell>
						<TableCell>
							<code>{p.configType}</code>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};

export const ActiveVersionProcessesTablePlaceholder = () => (
	<FadeIn>
		<Paper>
			<SkeletonListItem avatar={false} cols={4} />
			<SkeletonCardHeader subheader={false} />
			<SkeletonList avatar={false} cols={3} />
		</Paper>
	</FadeIn>
);

export default PackageProcessesTable;
