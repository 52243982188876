import React, { useCallback, useState, useMemo } from "react";
import { List, ListItemSecondaryAction } from "@material-ui/core";

import UserProfile from "../user-profile";

import RemoveDialog, { useRemoveDialogState } from "./remove-dialog";

import { xor } from "lodash";
import MemberMenu from "./menu";

const MemberList = ({ members, org }) => {
	const { memberToRemove, onRemoveClick, dialog } = useRemoveDialogState();
	const [removedMembers, setRemovedMembers] = useState([]);

	const handleRemoval = useCallback(m => {
		setRemovedMembers(s => [...s, m]);
	}, []);

	const membersWithoutRemovals = useMemo(() => xor(members, removedMembers), [
		members,
		removedMembers
	]);

	return (
		<>
			<List>
				{membersWithoutRemovals.map(m => (
					<MemberListItem
						key={m.id}
						org={org}
						member={m}
						onRemoveClick={onRemoveClick}
					/>
				))}
			</List>
			<RemoveDialog
				{...{ org, memberToRemove, dialog }}
				onSuccess={handleRemoval}
			/>
		</>
	);
};

const MemberListItem = ({ org, member, onRemoveClick }) => {
	const handleRemoveClick = useCallback(() => onRemoveClick(member), [
		member,
		onRemoveClick
	]);

	return (
		<UserProfile
			variant="ListItem"
			isFetched
			isOwner={member.role === "Owner"}
			isSystem={member.userId?.toLowerCase?.() === "system"}
			returnLabel="Members"
			{...member}
		>
			<ListItemSecondaryAction>
				<MemberMenu {...{ org, member, handleRemoveClick }} />
			</ListItemSecondaryAction>
		</UserProfile>
	);
};

export default MemberList;
