import React, { useCallback } from "react";

import {
	DialogActions,
	CardContent,
	TextField,
	Typography
} from "@material-ui/core";
import { ArrowRightAlt as ArrowRightIcon } from "@material-ui/icons";

import { Button } from "@runly/gatsby-theme";

import SlugEditor from "../../slug/editor";

const ChooseNameStep = ({
	org,
	state,
	onChangeId,
	onChangeDescription,
	navigate
}) => {
	return (
		<form
			onSubmit={useCallback(
				ev => {
					ev.preventDefault();
					navigate("access");
				},
				[navigate]
			)}
		>
			<CardContent>
				<Typography variant="h5" component="h2">
					How should this environment be identified?
				</Typography>
				<Typography paragraph>
					This will be the identifier used in the API for integrations that you
					may build. You can change it later if you need to.
				</Typography>

				<SlugEditor value={state.id} onChange={onChangeId} />

				<TextField
					id="env-description"
					label="Description"
					value={state.description}
					onChange={onChangeDescription}
					multiline
					fullWidth
					helperText="Optional description used to let your team know how this environment should be used."
				/>
			</CardContent>

			<DialogActions>
				<Button to={`/dashboard/${org}`}>Cancel</Button>
				<Button type="submit" variant="contained" color="primary">
					Set Access
					<ArrowRightIcon />
				</Button>
			</DialogActions>
		</form>
	);
};

export default ChooseNameStep;
