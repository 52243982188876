import React from "react";
import { Helmet } from "react-helmet";
import { Router } from "@reach/router";

import { Grid } from "@material-ui/core";

import useClusterConnection from "../hub";

import Layout from "./layout";

import Notification from "../../notification";
import PageHeader, { HeaderIcon } from "../../page-header";
import { ReactComponent as ClusterIcon } from "../../assets/cluster-material-system.svg";

import ClusterInfo, { ClusterInfoPlaceholder } from "./info";
import NodeList, { NodeListPlaceholder } from "./nodes";
import Settings from "./settings";

import SocketIndicator from "../../socket-indicator";

const ClusterDetailsPage = ({ org, cluster, location }) => (
	<Layout
		{...{ org, cluster, location }}
		header={
			<PageHeader>
				<HeaderIcon>
					<ClusterIcon style={{ height: "1em" }} />
				</HeaderIcon>{" "}
				{cluster}
			</PageHeader>
		}
	>
		<Router>
			<DetailsRoot path="/" />
			<Settings path="settings" />
		</Router>
	</Layout>
);

const DetailsRoot = ({ org, cluster: clusterId, location }) => {
	const {
		error,
		nodes,
		nodesLoaded,
		cluster,
		...connectionState
	} = useClusterConnection({
		org,
		cluster: clusterId
	});

	const notification =
		(!nodes || !cluster) && error ? (
			<Notification severity="error">{error.message}</Notification>
		) : null;

	return (
		<>
			<Helmet>
				<title>{`${clusterId} cluster`}</title>
			</Helmet>

			{notification}

			<Grid container spacing={3}>
				<Grid item xs={12}>
					<SocketIndicator
						connection={{ error, ...connectionState }}
						body={cluster}
						placeholder={<ClusterInfoPlaceholder />}
					>
						<ClusterInfo
							org={org}
							clusterId={clusterId}
							cluster={cluster}
							location={location}
						/>
					</SocketIndicator>
				</Grid>
				<Grid item xs={12}>
					<SocketIndicator
						connection={{ error, ...connectionState }}
						body={nodesLoaded ? nodes : null}
						placeholder={<NodeListPlaceholder />}
					>
						<NodeList {...{ org, nodes }} />
					</SocketIndicator>
				</Grid>
			</Grid>
		</>
	);
};

export default ClusterDetailsPage;
