import { useReducer, useCallback } from "react";

import { remoteCollectionReducer } from "./reducer";
import { useDeleteHandlers } from "./use-delete-item";
import { COLLECTION_ACTIONS } from "./actions";

import { useCollectionSorter } from "./use-collection-sorter";
import { useSyncCollection } from "./use-sync-collection";
import { useRemoteCollectionRequests } from "./api";

export const useRemoteCollection = ({ endpoint, itemDescription = "item" }) => {
	const [{ itemsById, selections }, dispatch] = useReducer(
		remoteCollectionReducer,
		{
			itemsById: {},
			selections: {
				getItem: null,
				deleteItem: null,
				lastUpdated: null,
				lastDeleted: null
			}
		}
	);

	const handleSelectDetails = useCallback(
		id => {
			dispatch({
				type: COLLECTION_ACTIONS.LOCAL_SELECT_DETAILS,
				payload: { id }
			});
		},
		[dispatch]
	);

	const requests = useRemoteCollectionRequests({ endpoint, selections });

	useSyncCollection({ selections, dispatch, requests });

	const isDeleteQueued =
		requests?.deleteItem.isFetched || requests?.deleteItem.isFetching;

	const items = useCollectionSorter(itemsById);

	return {
		requests,
		itemsById,
		items,
		selections,
		handlers: {
			getItem: handleSelectDetails,
			deleteItem: useDeleteHandlers({
				req: requests.deleteItem,
				dispatch
			})
		},
		dispatch,
		isDeleteQueued,
		itemDescription
	};
};
