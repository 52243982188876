import React, { useCallback, useMemo } from "react";

import { useFetchJobs } from "../../../job/api";
import ProcessPicker from "../../../job/picker";
import VersionPicker from "../../../job/picker/version";

import { find } from "lodash";
import { Grid } from "@material-ui/core";

const RunsProcessFilter = ({ org, queryParams, onValueChange }) => {
	const req = useFetchJobs(org);

	const selectedProcess = useMemo(() => {
		if (!req.body) return queryParams.job;
		return find(req.body, { type: queryParams.job });
	}, [queryParams.job, req.body]);

	const selectedVersion = useMemo(() => {
		if (!selectedProcess?.versions) return queryParams.version;
		return find(selectedProcess.versions, { version: queryParams.version });
	}, [queryParams.version, selectedProcess]);

	const onProcessChange = useCallback(
		(evt, newValue) => onValueChange("job", newValue?.type),
		[onValueChange]
	);

	const onVersionChange = useCallback(
		(evt, newValue) => onValueChange("version", newValue?.version),
		[onValueChange]
	);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={8}>
				<ProcessPicker
					req={req}
					value={selectedProcess || ""}
					onChange={onProcessChange}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<VersionPicker
					process={selectedProcess}
					value={selectedVersion || ""}
					onChange={onVersionChange}
				/>
			</Grid>
		</Grid>
	);
};

export default RunsProcessFilter;
