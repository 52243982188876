import React, { useCallback } from "react";

import {
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	FormHelperText,
	Radio
} from "@material-ui/core";

const EnvProductionFlagEditor = ({ value, onChange }) => (
	<FormControl component="fieldset">
		<FormLabel component="legend">Access</FormLabel>
		<RadioGroup
			aria-label="access"
			name="env-access"
			value={value ? "prod" : "dev"}
			onChange={useCallback(evt => onChange(evt.target.value == "prod"), [
				onChange
			])}
		>
			<FormControlLabel
				value="prod"
				control={<Radio />}
				label={
					<>
						Production Environment
						<FormHelperText>
							Only <em>Ops</em> & <em>Owners</em> will be able to modify the
							environment's settings.
						</FormHelperText>
					</>
				}
			/>
			<FormControlLabel
				value="dev"
				control={<Radio />}
				label={
					<>
						Development Environment
						<FormHelperText>
							All users including <em>Developers</em> will be able to modify the
							environment's settings.
						</FormHelperText>
					</>
				}
			/>
		</RadioGroup>
	</FormControl>
);

export default EnvProductionFlagEditor;
