import { useState, useCallback, useEffect } from "react";

import { useUpdateBilling } from "../api";

const usePaymentMethodState = org => {
	const [isEditingCard, setIsEditingCard] = useState(false);

	const cancelEditCard = useCallback(() => {
		setIsEditingCard(false);
	}, []);

	const toggleIsEditingCard = useCallback(
		e => {
			e?.preventDefault();
			setIsEditingCard(v => !v);
		},
		[setIsEditingCard]
	);

	const {
		isSaved,
		billing: savedBilling,
		save: saveBilling,
		...saveResults
	} = useUpdateBilling(org);

	const onPaymentMethodSaved = useCallback(
		paymentMethod => {
			saveBilling({ paymentMethodId: paymentMethod.id });
		},
		[saveBilling]
	);

	useEffect(() => {
		if (isSaved) {
			toggleIsEditingCard();
		}
	}, [isSaved, toggleIsEditingCard]);

	return {
		isEditingCard,
		cancelEditCard,
		toggleIsEditingCard,

		...saveResults,
		savedBilling,
		onPaymentMethodSaved,
		isSaved
	};
};

export default usePaymentMethodState;
