import { useReducer, useCallback, useEffect } from "react";
import { takeRight } from "lodash";

const reducer = (state, { type, payload }) => {
	switch (type) {
		case "init":
			return { isLoading: true, logType: payload, logs: [] };

		case "inited":
			return { ...state, isLoading: false };

		case "log":
			if (payload.type != state.logType) {
				return state;
			}

			return {
				...state,
				isLoading: false,
				logs: [...takeRight(state.logs, 99), payload]
			};

		case "clear":
			return { ...state, isLoading: false, logs: [] };

		default:
			return state;
	}
};

const useLogConnection = (connection, logType) => {
	const [state, dispatch] = useReducer(reducer, { isLoading: true, logs: [] });

	const onLog = useCallback(log => {
		dispatch({ type: "log", payload: log });
	}, []);

	const onClear = useCallback(() => {
		dispatch({ type: "clear" });
	}, []);

	useEffect(() => {
		async function init() {
			dispatch({ type: "init", payload: logType });
			connection.on("Log", onLog);
			await connection.invoke("SetMode", logType);
			dispatch({ type: "inited" });
		}

		if (connection) {
			init();
		}

		return () => {
			if (connection) {
				connection.off("Log", onLog);
			}
		};
	}, [connection, logType, onLog]);

	return [state, { onClear }];
};

export default useLogConnection;
