import React from "react";
import { ListItemText, ListItem, ListItemIcon } from "@material-ui/core";
import { formatNumber } from "accounting";
import { useCategory } from "./use-category";
import ResultIcon from "../../detail/timeline/icon";

export const CategoryLabel = ({
	category,
	setActiveCategory,
	isSuccessful,
	count
}) => {
	const { onSelect, onDeselect } = useCategory({ setActiveCategory, category });
	return (
		<ListItem
			display="flex"
			onMouseEnter={onSelect}
			onFocus={onSelect}
			onBlur={onDeselect}
		>
			<ListItemIcon>
				<ResultIcon
					style={{ display: "block", margin: "0 auto" }}
					{...{ isSuccessful }}
				/>
			</ListItemIcon>
			<ListItemText
				primary={formatNumber(count)}
				style={{
					textAlign: "right",
					paddingRight: 16,
					flexGrow: 0,
					minWidth: "6em"
				}}
			/>
			<ListItemText primary={category} />
		</ListItem>
	);
};
