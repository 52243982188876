import React from "react";

import {
	Box,
	Card,
	CardActions,
	Button,
	List,
	CardHeader
} from "@material-ui/core";
import { Settings as SettingsIcon } from "@material-ui/icons";

import SettingsItem from "../../../settings-property";

import { Link } from "@runly/gatsby-theme";

import DateTime from "../../../time";
import Duration from "../../../time/duration";
import UserProfile from "../../../user-profile";
import DangerZone from "./danger-zone";

const OrgReadonlySettings = ({ organization, onEdit }) => {
	if (!organization) return null;

	const returnLabel = {
		id: organization.name,
		label: "Organization Settings"
	};

	return (
		<>
			<Box>
				<Card>
					<CardHeader title="General" id="#" />
					<List>
						<SettingsItem title="Name">{organization.name}</SettingsItem>
						<SettingsItem title="API ID">{organization.id}</SettingsItem>
						<SettingsItem title="Billing Plan">
							<Link
								to={`/dashboard/${organization.id}/settings/billing`}
								color="inherit"
							>
								{organization.plan}
							</Link>
						</SettingsItem>

						<SettingsItem title="Created">
							<span>
								<DateTime value={organization.createdAt} /> by{" "}
								<UserProfile
									userId={organization.createdBy}
									returnLabel={returnLabel}
								/>
							</span>
						</SettingsItem>
						{organization.updatedAt !== organization.createdAt ? (
							<SettingsItem title="Modified">
								<>
									<DateTime value={organization.updatedAt} /> (
									<Duration
										start={organization.createdAt}
										end={organization.updatedAt}
									/>{" "}
									after creation) by{" "}
									<UserProfile
										userId={organization.updatedBy}
										returnLabel={returnLabel}
									/>
								</>
							</SettingsItem>
						) : null}
					</List>
					<CardActions>
						<Button
							variant="outlined"
							onClick={onEdit}
							startIcon={<SettingsIcon />}
						>
							Edit Settings
						</Button>
					</CardActions>
				</Card>
			</Box>

			<Box mt={3}>
				<DangerZone organization={organization} />
			</Box>
		</>
	);
};

export default OrgReadonlySettings;
