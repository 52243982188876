import React, { useState, useCallback, useEffect } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Grow
} from "@material-ui/core";

import { useRemoveMember } from "./api";

import useDialog from "../use-dialog";
import FetchButton from "../busy-button/fetch-button";
import useMutationHandler from "../mutation-handler";

const RemoveMemberDialog = ({
	org,
	dialog: { hide, dialogProps },
	memberToRemove,
	onSuccess
}) => {
	const req = useRemoveMember(org, memberToRemove?.id);

	useMutationHandler({
		req,
		errorNotification: { verb: "removing", description: "member" }
	});

	useEffect(() => {
		if (req.isFetched) {
			hide();

			if (onSuccess) {
				onSuccess(memberToRemove);
			}
		}
	}, [hide, memberToRemove, onSuccess, req.isFetched]);

	if (!memberToRemove) return null;

	return (
		<Dialog
			{...dialogProps}
			fullWidth
			TransitionComponent={Grow}
			PaperProps={{ style: { overflow: "visible" } }}
		>
			<DialogTitle>Remove Member</DialogTitle>
			<DialogContent>
				Are you sure you want to remove{" "}
				<strong>
					{memberToRemove.name} ({memberToRemove.email})
				</strong>{" "}
				from this organization?
			</DialogContent>
			<DialogActions>
				<Button onClick={hide}>Don't remove</Button>
				<FetchButton
					variant="outlined"
					color="primary"
					req={req}
					busyChildren={<>Removing…</>}
					onClick={() => req.fetch()}
				>
					Remove Member
				</FetchButton>
			</DialogActions>
		</Dialog>
	);
};

export const useRemoveDialogState = () => {
	const [memberToRemove, setMemberToRemove] = useState();
	const dialog = useDialog();

	const { show } = dialog;
	const onRemoveClick = useCallback(
		u => {
			setMemberToRemove(u);
			show();
		},
		[show]
	);

	return { memberToRemove, onRemoveClick, dialog };
};

export default RemoveMemberDialog;
