import React, { useState, useCallback, useEffect } from "react";

import { Button } from "@runly/gatsby-theme";

import Notification from ".";
import { navigate } from "@reach/router";
import { usePrevious } from "../use-previous";

const RedirectNotification = ({ location, ...props }) => {
	const {
		isOpen,
		handleClose,
		onExited,
		notification
	} = useRedirectNotification(location);

	const { messageId, actionText, actionLink, text, severity, title } =
		notification || {};

	const actionBtn =
		actionText && actionLink
			? [
					<Button
						key="action"
						to={actionLink}
						onClick={handleClose}
						color="inherit"
					>
						{actionText}
					</Button>
			  ]
			: undefined;

	return (
		<Notification
			title={title}
			severity={severity}
			messageId={messageId}
			isOpen={isOpen}
			handleClose={handleClose}
			onExited={onExited}
			action={actionBtn}
			{...props}
		>
			{text}
		</Notification>
	);
};

const useRedirectNotification = location => {
	const [isOpen, setOpen] = useState(false);

	const notification = location?.state?.notification;

	const handleClose = useCallback(() => {
		setOpen(false);
	}, []);

	const onExited = useCallback(() => {
		// wait until the notification is finished hiding, then clear it from location state
		navigate(null, { notification: null });
	}, []);

	const prevNotification = usePrevious(notification);

	useEffect(() => {
		// show notification on truthy transition
		if (!prevNotification && notification) {
			setOpen(true);
		}
	}, [notification, prevNotification]);

	return { isOpen, handleClose, onExited, notification };
};

export default RedirectNotification;
