import { omit } from "lodash";
import { COLLECTION_ACTIONS } from "./actions";

export const remoteCollectionReducer = (state, action) => {
	switch (action.type) {
		case FETCHING: {
			return { ...state, isFetching: true };
		}

		case FETCHED: {
			const itemsById = {};

			action.payload.body?.forEach(item => (itemsById[item.id] = item));

			return { ...state, itemsById };
		}

		case REMOTE_CREATED: {
			const { itemsById, selections, ...rest } = state;

			const id = action.payload.id;

			const result = {
				...itemsById,
				[id]: { ...action.payload, _justCreated: true }
			};

			return {
				...rest,
				selections: { ...selections, lastUpdated: id },
				itemsById: result
			};
		}

		case REMOTE_PATCHED: {
			const { itemsById, selections, ...rest } = state;

			const { id, oldId } = action.payload;

			const result = {
				...itemsById,
				[oldId]: { ...itemsById[oldId], remoteDeleted: true },
				[id]: action.payload
			};

			return {
				...rest,
				selections: { ...selections, lastUpdated: id },
				itemsById: result
			};
		}

		case LOCAL_SELECT_DETAILS: {
			const { selections, ...rest } = state;

			return {
				...rest,
				selections: { ...selections, getItem: action.payload.id }
			};
		}

		case LOCAL_SELECT_DELETE_ITEM: {
			const { selections, ...rest } = state;

			return {
				...rest,
				selections: { ...selections, deleteItem: action.payload.id }
			};
		}

		case REMOTE_ATTEMPT_DELETE: {
			const { itemsById, ...rest } = state;

			const id = action.payload.id;

			const result = {
				...itemsById,
				[id]: {
					...itemsById[id],
					isDeleting: true
				}
			};

			return { ...rest, itemsById: result };
		}

		case REMOTE_DELETED: {
			const { itemsById, selections, ...rest } = state;

			const id = action.payload.id;

			const result = {
				...itemsById,
				[id]: {
					...itemsById[id],
					remoteDeleted: true
				}
			};

			return {
				...rest,
				selections: {
					...selections,
					lastDeleted: itemsById[id]
				},
				itemsById: result
			};
		}

		case LOCAL_DELETE: {
			const { itemsById, selections, ...rest } = state;

			const id = action.payload.id;

			return {
				...rest,
				itemsById: omit(itemsById, id),
				selections: {
					...selections,
					deleteItem: null
				}
			};
		}

		default:
			throw new Error(`Unsupported action type: ${action.type}`);
	}
};

const {
	FETCHING,
	FETCHED,
	REMOTE_CREATED,
	REMOTE_PATCHED,
	LOCAL_SELECT_DETAILS,
	LOCAL_SELECT_DELETE_ITEM,
	REMOTE_ATTEMPT_DELETE,
	REMOTE_DELETED,
	LOCAL_DELETE
} = COLLECTION_ACTIONS;
