import React, { useState, useEffect } from "react";
import { ListItem, List } from "@material-ui/core";
import FakeSuspense from "../fake-suspense";
import EmptyMessage from "../empty-message";
import { FadeIn } from "../skeleton/fade-in";
import { SkeletonList } from "../skeleton/list";
import DeleteItem from "./delete-item";
import RemoteCollectionItemWrapper from "./item-wrapper";
import { useInitRequestTransition } from "../mutation-handler";
import { usePrevious } from "../use-previous";

const RemoteCollectionList = ({
	collection,
	ItemComponent = ListItem,
	itemProps = {}
}) => {
	const { items, requests, selections, handlers, itemDescription } = collection;

	const [isDeleteOpen, setIsDeleteOpen] = useState(false);

	const isJustDeleting = useInitRequestTransition(
		collection.requests.deleteItem
	);

	const wasDeleteSelected = usePrevious(selections.deleteItem);
	const didJustSelectDelete = !wasDeleteSelected && !!selections.deleteItem;

	useEffect(() => {
		if (didJustSelectDelete) {
			setIsDeleteOpen(true);
		}
		if (isJustDeleting || !selections.deleteItem) {
			setIsDeleteOpen(false);
		}
	}, [didJustSelectDelete, isJustDeleting, selections.deleteItem]);

	return (
		<FakeSuspense
			req={{ ...requests.getCollection, body: items }}
			placeholder={
				<FadeIn>
					<SkeletonList />
				</FadeIn>
			}
			emptyChildren={<EmptyMessage>No items to display</EmptyMessage>}
		>
			<>
				<List>
					{items?.map?.((item, i) => {
						return (
							<RemoteCollectionItemWrapper
								key={item.id}
								item={item}
								collection={collection}
								ItemComponent={ItemComponent}
								itemProps={itemProps}
								isLast={i >= items.length - 1}
							/>
						);
					})}
				</List>
				<DeleteItem
					deleteReq={requests.deleteItem}
					description={itemDescription}
					isOpen={isDeleteOpen}
					{...handlers.deleteItem}
				/>
			</>
		</FakeSuspense>
	);
};

export default RemoteCollectionList;
