import { useFetch, useLazyFetch } from "react-fetch-hooks";
import useParsedDates from "./parse-dates";
import { useRunlyConfig } from "@runly/ui";

export const useFetchBillingInfo = org => {
	const { url, token: bearerToken } = useRunlyConfig();

	const { body: billing, ...rest } = useFetch(
		url && bearerToken && org ? `${url}/${org}/billing` : null,
		{
			method: "GET",
			bearerToken
		}
	);

	const result = useParsedDates(billing);

	return { billing: result, ...rest };
};

export const useUpdateBilling = org => {
	const { url, token: bearerToken } = useRunlyConfig();

	const {
		body: billing,
		fetch: save,
		isFetching: isSaving,
		isFetched: isSaved,
		error: saveError
	} = useLazyFetch(url && bearerToken ? `${url}/${org}/billing` : null, {
		method: "POST",
		bearerToken
	});

	const result = useParsedDates(billing);

	return { billing: result, save, isSaving, isSaved, saveError };
};
