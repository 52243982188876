import React from "react";

import { ReactComponent as Visa } from "payment-icons/min/flat/visa.svg";
import { ReactComponent as Diners } from "payment-icons/min/flat/diners.svg";
import { ReactComponent as JCB } from "payment-icons/min/flat/jcb.svg";
import { ReactComponent as MasterCard } from "payment-icons/min/flat/mastercard.svg";
import { ReactComponent as DefaultCard } from "payment-icons/min/outline/default.svg";
import { ReactComponent as UnionPay } from "payment-icons/min/flat/unionpay.svg";
import { ReactComponent as Discover } from "payment-icons/min/flat/discover.svg";
import { ReactComponent as Amex } from "payment-icons/min/flat/amex.svg";

import { makeStyles } from "@material-ui/core/styles";

const icons = {
	visa: Visa,
	mastercard: MasterCard,
	diners: Diners,
	jcb: JCB,
	amex: Amex,
	discover: Discover,
	unionpay: UnionPay
};

const PaymentIcon = ({ cardType = "UNKNOWN" }) => {
	const t = cardType?.toLowerCase?.();
	const isDefault =
		!t || t === "unknown" || !Object.prototype.hasOwnProperty.call(icons, t);

	const classes = useStyles({ isDefault });

	let IconComponent;

	if (isDefault) {
		IconComponent = DefaultCard;
	} else {
		IconComponent = icons[t];
	}

	return (
		<IconComponent
			className={classes.root}
			viewBox="0 0 750 471"
			width="1.2em"
			height="0.875em"
		/>
	);
};

const useStyles = makeStyles(theme => {
	return {
		root: {
			width: "1.2em",
			height: "0.875em",
			"& *": {
				fill: ({ isDefault }) =>
					isDefault ? theme.palette.text.secondary : undefined,
				stroke: ({ isDefault }) =>
					isDefault ? theme.palette.text.secondary : undefined
			}
		}
	};
});

export default PaymentIcon;
