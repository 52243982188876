import React from "react";
import {
	ListItem,
	Collapse,
	ListItemSecondaryAction,
	ListItemText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Lock as LockIcon } from "@material-ui/icons";
import RefableLink from "../../refable-link";

const DeepNavTab = ({
	href,
	label,
	isFragment = false,
	isActive,
	disabled,
	disabledReason,
	tabs,
	state,
	location
}) => {
	const classes = useStyles({ isActive, isFragment });
	return (
		<>
			<ListItem
				dense={isFragment}
				className={classes.root}
				button
				component={RefableLink}
				to={href}
				state={state}
				display="block"
				disabled={disabled}
			>
				<ListItemText primary={label} />
				{disabled && disabledReason ? (
					<ListItemSecondaryAction>
						<LockIcon />
					</ListItemSecondaryAction>
				) : null}
			</ListItem>
			<Collapse in={isActive}>
				{tabs?.map((t, idx) => (
					<DeepNavTab
						key={t.href}
						{...t}
						isFragment
						isActive={
							idx === 0
								? location.pathname + location.hash === t.href
								: location.pathname.startsWith(t.href)
						}
					/>
				))}
			</Collapse>
		</>
	);
};

const useStyles = makeStyles(theme => {
	return {
		root: {
			marginLeft: theme.spacing(-0.25),
			paddingLeft: ({ isFragment }) => theme.spacing(isFragment ? 4 : 2),
			borderLeft: ({ isActive }) =>
				`2px solid ${isActive ? theme.palette.primary.main : "transparent"}`,
			background: ({ isActive, isFragment }) =>
				isActive && !isFragment ? theme.palette.background.paper : null,
			fontWeight: ({ isActive, isFragment }) =>
				isFragment && isActive ? "bold" : "normal",
			"&, &:hover, &:focus": {
				textDecoration: "none"
			}
		}
	};
});

export default DeepNavTab;
