import React from "react";
import { Grid, Box, Button, Typography } from "@material-ui/core";
import ProcessPicker from "../../../job/picker";
import VersionPicker from "../../../job/picker/version";

const ConfigEditorToolbar = ({ configEditor, org }) => {
	const {
		selectedProcess,
		selectedVersion,
		onProcessPickerChange,
		onVersionPickerChange,
		resetDirty
	} = configEditor;

	return (
		<>
			{configEditor.isDirty ? (
				<Grid item sm={12}>
					<Box py={1} display="flex">
						<Typography>
							Default config{" "}
							{selectedProcess?.type ? <>for {selectedProcess.type}</> : ""} has
							been edited.
						</Typography>
						<Button
							style={{ marginLeft: "auto" }}
							size="medium"
							variant="outlined"
							onClick={resetDirty}
						>
							Choose a job
						</Button>
					</Box>
				</Grid>
			) : (
				<>
					<Grid item sm={6}>
						<ProcessPicker
							org={org}
							value={selectedProcess}
							onChange={onProcessPickerChange}
						/>
					</Grid>
					<Grid item sm={6}>
						<VersionPicker
							process={selectedProcess}
							value={selectedVersion}
							onChange={onVersionPickerChange}
						/>
					</Grid>
				</>
			)}
		</>
	);
};

export default ConfigEditorToolbar;
