import { useMemo } from "react";
import { useLazyFetch } from "react-fetch-hooks";

import { useRunlyConfig } from "@runly/ui";
import { useFetchClusters } from "../../../../cluster/api";

import { some } from "lodash";

export const useFetchEnvClusters = ({ org, selectedClusters }) => {
	const { body: allClusters, ...allClustersReq } = useFetchClusters({ org });

	const clusters = useMemo(() => {
		if (!allClusters || !selectedClusters) return null;

		return allClusters.map(c => ({
			...c,
			isSelected: some(selectedClusters, sc => sc == c.id)
		}));
	}, [allClusters, selectedClusters]);

	return {
		body: clusters,
		isFetching: allClustersReq.isFetching,
		isFetched: allClustersReq.isFetched,
		error: allClustersReq.error
	};
};

export const useAddCluster = ({ org, env, cluster }) => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useLazyFetch(
		url && bearerToken ? `${url}/${org}/environments/${env}/clusters/` : null,
		{
			method: "POST",
			bearerToken,
			body: JSON.stringify({ clusters: [cluster] })
		}
	);
};

export const useRemoveCluster = ({ org, env, cluster }) => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useLazyFetch(
		url && bearerToken
			? `${url}/${org}/environments/${env}/clusters/${cluster}`
			: null,
		{
			method: "DELETE",
			bearerToken
		}
	);
};
