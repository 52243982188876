import { useReducer, useCallback } from "react";

const reducer = (state, { type, payload }) => {
	switch (type) {
		case "add_cluster":
			return { ...state, clusters: [...state.clusters, payload.cluster] };
		case "remove_cluster":
			return {
				...state,
				clusters: state.clusters.filter(c => c !== payload.cluster)
			};
		case "change":
			return { ...state, [payload.key]: payload.value };

		default:
			return state;
	}
};

const useNewEnvironmentState = () => {
	const [state, dispatch] = useReducer(reducer, { clusters: [] });

	const onChange = useCallback(
		(key, value) => dispatch({ type: "change", payload: { key, value } }),
		[]
	);

	const onToggleCluster = useCallback(
		cluster => evt => {
			dispatch({
				type: evt.target.checked ? "add_cluster" : "remove_cluster",
				payload: { cluster }
			});
		},
		[]
	);

	const onChangeId = useCallback(value => onChange("id", value), [onChange]);

	const onChangeDescription = useCallback(
		evt => onChange("description", evt.target.value),
		[onChange]
	);

	const onChangeIsProduction = useCallback(
		value => onChange("isProduction", value),
		[onChange]
	);

	return {
		onChangeId,
		onChangeDescription,
		onChangeIsProduction,
		onToggleCluster,
		state
	};
};

export default useNewEnvironmentState;
