import React from "react";
import {
	Close as CancelledIcon,
	CloudCircle as EnqueuedIcon,
	HourglassEmpty as RunningIcon,
	CheckCircle as SuccessfulIcon,
	Error as FailedIcon
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const baseIconStyles = {
	position: ({ position }) => position,
	top: ({ top }) => top,
	left: ({ left }) => left,
	width: ({ size }) => size,
	height: ({ size }) => size
};

const RunStatusIcon = ({ state, position = "absolute", top, left, size }) => {
	const classes = useStyles({ position, top, left, size });
	if (state === "Enqueued")
		return <EnqueuedIcon className={classes.enqueuedIcon} />;
	if (state === "Cancelled")
		return <CancelledIcon className={classes.cancelledIcon} />;
	if (state === "Successful")
		return <SuccessfulIcon className={classes.successfulIcon} />;
	if (state === "Running")
		return <RunningIcon className={classes.runningIcon} />;
	if (state === "Failed" || state === "TimedOut")
		return <FailedIcon className={classes.failedIcon} />;

	return null;
};

const useStyles = makeStyles(theme => {
	return {
		"@keyframes hourglass-spin": {
			"0%": {
				transform: "rotate(0deg)"
			},
			"93.75%": {
				transform: "rotate(0deg)"
			},
			"100%": {
				transform: "rotate(180deg)"
			}
		},
		icon: baseIconStyles,
		enqueuedIcon: { ...baseIconStyles, color: theme.palette.text.hint },
		failedIcon: {
			...baseIconStyles,
			color: theme.palette.error.main
		},
		cancelledIcon: baseIconStyles,
		successfulIcon: { ...baseIconStyles, color: green[500] },
		runningIcon: {
			...baseIconStyles,
			color: theme.palette.text.hint,
			animation: `$hourglass-spin ${
				theme.transitions.duration.complex * 8
			}ms infinite ease-in-out`
		}
	};
});

export default RunStatusIcon;
