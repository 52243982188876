import React, { Children } from "react";
import {
	Card,
	CardHeader,
	Divider,
	CardContent,
	List
} from "@material-ui/core";

import Item from "./item";

import { makeStyles } from "@material-ui/core/styles";

const DangerZone = ({ children }) => {
	const childCount = Children.count(children);

	return (
		<Card classes={useDangerCardStyles()}>
			<CardHeader
				title="Danger Zone"
				id="danger-zone"
				subheader="Careful with these settings!"
			/>
			<CardContent>
				<List>
					{Children.map(children, (item, i) => {
						if (item.displayName !== Item.displayName) {
							throw new Error(
								`\`DangerZone\` children should be of type \`${Item.displayName}\`. Got \`${item.displayName}\` instead.`
							);
						}

						return (
							<>
								{item}
								{i + 1 != childCount ? <Divider /> : null}
							</>
						);
					})}
				</List>
			</CardContent>
		</Card>
	);
};

export const useDangerCardStyles = makeStyles(theme => ({
	root: {
		borderWidth: 1,
		borderStyle: "solid",
		borderColor: theme.palette.error.main
	}
}));

export default DangerZone;
