import React, { useCallback, useState, useEffect, useMemo } from "react";
import {
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Switch
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useAddCluster, useRemoveCluster } from "./api";

import SlugList from "../../../../slug/list";
import useMutationHandler from "../../../../mutation-handler";

import { without } from "lodash";
import { ClusterAvatar } from "../../../../cluster/item";
import RefableLink from "../../../../refable-link";

const useClusterRequests = ({ org, env, cluster }) => {
	const { fetch: addCluster, ...addReq } = useAddCluster({
		org,
		env,
		cluster
	});

	const { fetch: removeCluster, ...removeReq } = useRemoveCluster({
		org,
		env,
		cluster
	});

	return {
		addCluster,
		removeCluster,
		isFetching: addReq.isFetching || removeReq.isFetching,
		isFetched: addReq.isFetched && removeReq.isFetched,
		error: addReq.error || removeReq.error
	};
};

const EnvClusterItem = ({
	org,
	env,
	id,
	environments,
	isSelected: isInitialSelected
}) => {
	const [isSelected, setIsSelected] = useState(isInitialSelected);

	const { addCluster, removeCluster, ...req } = useClusterRequests({
		org,
		env,
		cluster: id
	});

	const onChange = useCallback(
		ev => {
			if (req.isFetching) {
				// don't do anything
				return;
			}

			setIsSelected(ev.target.checked);

			if (ev.target.checked) {
				addCluster();
			} else {
				removeCluster();
			}
		},
		[addCluster, removeCluster, req.isFetching]
	);

	useEffect(() => {
		// the toggle switch is optimistic, so we need to undo the switch if the request failed
		if (req.error) {
			setIsSelected(isInitialSelected);
		}
	}, [isInitialSelected, req.error]);

	environments = useMemo(() => without(environments, env), [env, environments]);

	const switchId = `cluster-toggle-switch-${id}`;

	useMutationHandler({
		req,
		errorNotification: {
			verb: "saving",
			description: `${id} cluster`
		}
	});

	const bubbleStopper = useStopBubble();
	const classes = useStyles();

	return (
		<ListItem button key={id}>
			<RefableLink
				style={{
					position: "absolute",
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
					zIndex: 0
				}}
				to={`/dashboard/${org}/clusters/${id}`}
			>
				{" "}
			</RefableLink>
			<ClusterAvatar />
			<ListItemText
				className={classes.listItemText}
				primary={id}
				secondary={
					environments.length ? (
						<>
							Also hosting{" "}
							<SlugList
								onClick={bubbleStopper}
								onMouseDown={bubbleStopper}
								onMouseUp={bubbleStopper}
								items={environments.map(e => e.id)}
								getLink={e => `/dashboard/${org}/env/${e}/settings/clusters`}
							/>
						</>
					) : null
				}
				secondaryTypographyProps={{ component: "div" }}
			/>
			<ListItemSecondaryAction>
				<Switch
					id={switchId}
					edge="end"
					checked={isSelected}
					onChange={onChange}
					value={id}
					color="primary"
				/>
			</ListItemSecondaryAction>
		</ListItem>
	);
};

const useStyles = makeStyles(() => {
	return {
		lisItemText: {
			position: "relative",
			zIndex: 1
		}
	};
});

const useStopBubble = () =>
	useCallback(e => {
		e?.stopImmediatePropagation?.();
		e?.stopPropagation?.();
	}, []);

export default EnvClusterItem;
