import React from "react";
import { Grid } from "@material-ui/core";

import FakeSuspense from "../../../fake-suspense";

import DeepNav from "../../../layout/deep-nav";
import { Router } from "@reach/router";

import GeneralSettings from "./general";
import EnvClusters from "./clusters";
import EnvPackages from "./pkg";
import { Helmet } from "react-helmet";
import { startCase } from "lodash";

const EnvSettings = ({ location, org, env, envState }) => {
	const environment =
		(envState.patchReq && !envState.patchReq.error && envState.patchReq.body) ||
		envState?.fetchReq?.body;

	return (
		<>
			<Helmet
				titleTemplate={`%s Settings | ${startCase(environment?.id)} | Runly`}
			/>
			<Grid container spacing={3}>
				<Grid item xs={12} md={2}>
					<DeepNav
						location={location}
						tabs={[
							{
								href: `/dashboard/${org}/env/${environment?.id}/settings`,
								label: "General",
								tabs: [
									{
										href: `/dashboard/${org}/env/${environment?.id}/settings#danger-zone`,
										label: "Danger Zone"
									}
								]
							},
							{
								href: `/dashboard/${org}/env/${environment?.id}/settings/clusters`,
								label: "Clusters"
							},
							{
								href: `/dashboard/${org}/env/${environment?.id}/settings/pkg`,
								label: "Packages"
							}
						]}
					/>
				</Grid>
				<Grid item xs={12} md={10}>
					<FakeSuspense
						req={envState.fetchReq}
						description={<>{env} environment</>}
					>
						<Router>
							<GeneralSettings
								path="/"
								environment={environment}
								envState={envState}
							/>
							<EnvClusters path="clusters" environment={environment} />
							<EnvPackages
								path="pkg"
								environment={environment}
								envState={envState}
							/>
						</Router>
					</FakeSuspense>
				</Grid>
			</Grid>
		</>
	);
};

export default EnvSettings;
