import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { Paper, Typography, Box } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Link } from "@runly/gatsby-theme";

import DocLink from "../../doc-link";

import { AppItem } from "./app-item";
import { useRemoteCollection } from "../../remote-collection/hook";
import { useRemoteCollectionMessages } from "../../remote-collection/hook/use-messages";

import { NewAppEditor } from "./editor";
import { useNewAppState } from "./editor/new-app-state";
import { useSuccessTransition } from "../../mutation-handler";
import RemoteCollectionList from "../../remote-collection/list";

const Apps = ({ orgs, location }) => {
	const collection = useRemoteCollection({
		endpoint: "/apps",
		itemDescription: "app key"
	});

	useRemoteCollectionMessages({
		location,
		...collection,
		nameKey: "name"
	});

	const {
		requests: { postItem }
	} = collection;
	const newApp = useNewAppState(postItem);

	useHandleCreated({ postItem, newApp });

	return (
		<>
			<Helmet>
				<title>Connected Apps</title>
			</Helmet>

			<Typography paragraph>
				Create applications that are authorized to access the Runly API. Learn
				more about{" "}
				<DocLink href="/platform/apps/">
					how application API keys can be used
				</DocLink>
				.
			</Typography>

			<AppAlert orgs={orgs} />

			<NewAppEditor {...{ newApp, postItem }} />
			<Paper>
				<RemoteCollectionList collection={collection} ItemComponent={AppItem} />
			</Paper>
		</>
	);
};

const AppAlert = ({ orgs }) => {
	const badOrgs = orgs.filter(o => !o.allowApps);

	if (!badOrgs.length) return null;

	return (
		<Box clone my={3}>
			<Alert severity="warning">
				<AlertTitle>Limited API Access</AlertTitle>
				<AppAlertContent orgs={orgs} />
			</Alert>
		</Box>
	);
};

const AppAlertContent = ({ orgs }) => {
	if (orgs.length == 1) {
		return (
			<div>
				Your current plan allows you to create applications to upload nuget
				packages and manage your account. See{" "}
				<Link to={`/dashboard/${orgs[0].id}/settings/billing`}>
					upgrade options
				</Link>{" "}
				to gain API access for integrations.
			</div>
		);
	}

	return (
		<div>
			Your current plan allows you to create applications to upload nuget
			packages and manage your account. See upgrade options to gain API access
			for integrations for the following organizations:
			<ul>
				{orgs.map(({ id, name }) => (
					<li key={id}>
						<Link to={`/dashboard/${id}/settings/billing`}>{name}</Link>
					</li>
				))}
			</ul>
		</div>
	);
};

const useHandleCreated = ({ postItem, newApp }) => {
	const isJustCreated = useSuccessTransition(postItem);

	useEffect(() => {
		if (isJustCreated) {
			newApp.reset();
		}
	}, [isJustCreated, newApp]);
};

export default Apps;
