import React, { Fragment } from "react";

import { makeStyles } from "@material-ui/core/styles";

import DocLink from "./doc-link";

import { isString } from "lodash";
import { solarized } from "./solarized";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	container: {
		overflowX: "auto",
		whiteSpace: "nowrap",
		backgroundColor: solarized.base03,
		color: solarized.base3,
		padding: theme.spacing(1, 2)
	},
	codeBlock: {
		...theme.typography.caption,
		fontFamily: "'Fira Code', Consolas, monospace"
	}
}));

const ShellExample = ({ children, docUrl }) => {
	const classes = useStyles();

	const learnMore = docUrl ? <DocLink href={docUrl}>Learn more</DocLink> : null;

	return (
		<>
			<Box clone my={1}>
				<pre className={classes.container}>
					<code className={classes.codeBlock}>{children}</code>
				</pre>
			</Box>
			{learnMore}
		</>
	);
};

const useSyntaxStyles = makeStyles({
	app: {
		color: solarized.blue
	},
	command: {
		color: solarized.green,
		fontWeight: "bold"
	},
	argOption: {
		fontStyle: "italic"
	},
	argValue: {}
});

export const ShellCommand = ({ command, args }) => {
	const classes = useSyntaxStyles();

	return (
		<div className={classes.container}>
			<span className={classes.app}>runly </span>
			<span className={classes.command}>{command} </span>
			{args &&
				args.map(arg => {
					if (isString(arg)) {
						return (
							<span key={arg} className={classes.argValue}>
								{arg}{" "}
							</span>
						);
					} else if (arg.option && arg.value) {
						return (
							<Fragment key={arg.argOption}>
								<span className={classes.argOption}>{arg.option} </span>
								<span className={classes.argValue}>{arg.value} </span>
							</Fragment>
						);
					}
				})}
		</div>
	);
};

export default ShellExample;
