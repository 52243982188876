import { useFetch } from "react-fetch-hooks";

import { useRunlyConfig } from "@runly/ui";

export const useFetchPlans = (org, promoCode) => {
	const { url: baseUrl, token: bearerToken } = useRunlyConfig();

	const url =
		baseUrl && bearerToken
			? org && promoCode
				? `${baseUrl}/plans?org=${org}&promoCode=${promoCode}`
				: org
				? `${baseUrl}/plans?org=${org}`
				: promoCode
				? `${baseUrl}/plans?promoCode=${promoCode}`
				: `${baseUrl}/plans`
			: null;

	return useFetch(url, {
		method: "GET",
		bearerToken
	});
};
