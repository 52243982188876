import React, { useCallback } from "react";
import {
	Box,
	Card,
	CardContent,
	Typography,
	Grid,
	Fade,
	Paper,
	Link as ExternalLink
} from "@material-ui/core";
import { Link } from "@runly/gatsby-theme";
import { Flipper, Flipped } from "react-flip-toolkit";
import BillingPlans from "./plans";
import { makeStyles } from "@material-ui/core/styles";

import PaymentMethod, { PaymentMethodChrome } from "./payment-method";

import useBillingState from "./state";

import { format as formatDate } from "date-fns";
import FormatPrice from "./plans/format-price";

const BillingSummary = ({ org }) => {
	const classes = useGridItemStyles();

	const {
		billing,
		isEditingCard,
		cancelEditCard,
		toggleIsEditingCard,
		onPaymentMethodSaved
	} = useBillingState(org);

	const listenForEscape = useCallback(
		e => {
			if (e.which === 27) {
				cancelEditCard();
			}
		},
		[cancelEditCard]
	);

	const buildPlanLink = useCallback(
		planId => `/dashboard/${org}/settings/billing/upgrade/${planId}`,
		[org]
	);

	return (
		<Flipper flipKey={isEditingCard}>
			<>
				<Grid container spacing={3} style={{ position: "relative" }}>
					<Flipped flipId="currentPlan">
						<FlexGridItem
							title="Current Plan"
							sm={6}
							md={4}
							collapsed={isEditingCard}
						>
							<Fade in={!!billing}>
								<CardContent>
									{!billing ? null : (
										<Box display="flex">
											<BigText>{billing.planName}</BigText>

											<Box style={{ marginLeft: "auto" }}>
												<FormatPrice
													price={billing.planPrice}
													period="mo"
													bigVariant="h5"
													smallVariant="subtitle2"
												/>
											</Box>
										</Box>
									)}
								</CardContent>
							</Fade>
						</FlexGridItem>
					</Flipped>
					<Flipped flipId="nextPayment">
						<FlexGridItem
							title="Next Payment"
							sm={6}
							md={3}
							lg={4}
							collapsed={isEditingCard}
						>
							<Fade in={!!billing}>
								<CardContent>
									{!billing ? null : (
										<>
											<BigText>
												{billing.nextBillingDate ? (
													formatDate(billing.nextBillingDate, "MMMM do, yyyy")
												) : (
													<>TBD</>
												)}
											</BigText>
											<Typography variant="body1">
												<Link
													to={`/dashboard/${org}/settings/billing/payments`}
												>
													History
												</Link>
											</Typography>
										</>
									)}
								</CardContent>
							</Fade>
						</FlexGridItem>
					</Flipped>
					<Flipped flipId="paymentMethod">
						<FlexGridItem
							expanded={isEditingCard}
							sm={12}
							md={5}
							lg={4}
							title="Payment Method"
							action={
								!isEditingCard ? (
									<Link
										onClick={toggleIsEditingCard}
										to="#change-payment-method"
									>
										Change
									</Link>
								) : null
							}
						>
							<Fade in={!!billing}>
								<Flipped inverseFlipId="paymentMethod">
									{!billing ? null : (
										<PaymentMethodChrome
											isEditing={isEditingCard}
											listenForEscape={listenForEscape}
										>
											<PaymentMethod
												{...billing}
												isEditing={isEditingCard}
												onCancelEdit={toggleIsEditingCard}
												onSaved={onPaymentMethodSaved}
											/>
										</PaymentMethodChrome>
									)}
								</Flipped>
							</Fade>
						</FlexGridItem>
					</Flipped>
				</Grid>

				<Flipped flipId="billingPlans">
					<Box mt={3} className={isEditingCard ? classes.collapsed : undefined}>
						<Paper>
							<Box pt={2} pl={2}>
								<Typography variant="h5">Change Plan</Typography>
								<Typography variant="subtitle2" gutterBottom>
									Pick the plan that best suits your organization's needs.{" "}
									<ExternalLink href="/pricing/" target="_blank">
										Compare features
									</ExternalLink>{" "}
									for plans.
								</Typography>
							</Box>

							<BillingPlans org={org} to={buildPlanLink} />
						</Paper>
					</Box>
				</Flipped>
				<Flipped flipId="payment-method-backdrop">
					<div
						onKeyUp={listenForEscape}
						onClick={cancelEditCard}
						className={
							isEditingCard
								? classes.modalBackdrop
								: classes.modalBackdropHidden
						}
					/>
				</Flipped>
			</>
		</Flipper>
	);
};

const FlexGridItem = ({
	xs = 12,
	sm = 4,
	title,
	action,
	children,
	collapsed,
	expanded,
	...props
}) => {
	const classes = useGridItemStyles({ collapsed });
	return (
		<Grid
			item
			xs={xs}
			sm={sm}
			className={`${
				expanded
					? classes.expanded
					: collapsed
					? classes.collapsed
					: classes.init
			} ${classes.flexCardWrapper}`}
			{...props}
		>
			<Card
				elevation={expanded ? 12 : collapsed ? 0 : 2}
				className={classes.flexCard}
			>
				<Box px={2} pt={1} display="flex">
					<Flipped inverseFlipId={`${title}-card-title`}>
						<Typography variant="subtitle2" color="textSecondary">
							{title}
						</Typography>
					</Flipped>
					{action ? <div style={{ marginLeft: "auto" }}>{action}</div> : null}
				</Box>

				{children}
			</Card>
		</Grid>
	);
};

const useGridItemStyles = makeStyles(theme => {
	return {
		init: {},
		collapsed: {
			opacity: 0.25
		},
		expanded: {
			zIndex: theme.zIndex.modal
		},
		flexCardWrapper: {
			display: "flex",
			flexDirection: "column"
		},
		flexCard: {
			minHeight: theme.spacing(16),
			flexGrow: ({ collapsed }) => (collapsed ? 0 : 1)
		},
		modalBackdropHidden: {
			transform3d: "translate(0, 0, 0)",
			background: "rgba(0, 0, 0, 0.5)",
			opacity: 0,
			backdropFilter: "blur(5px)",
			position: "fixed",
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			zIndex: -1
		},
		modalBackdrop: {
			position: "fixed",
			transform3d: "translate(0, 0, 0)",
			background: "rgba(0, 0, 0, 0.25)",
			opacity: 0.75,
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			zIndex: theme.zIndex.modalBackdrop
		}
	};
});

const BigText = ({ children }) => (
	<Typography variant="h5" component="h2">
		{children}
	</Typography>
);

export default BillingSummary;
