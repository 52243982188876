import React, { useState, useCallback } from "react";
import {
	ListItem,
	ListItemText,
	ListItemAvatar,
	ListItemSecondaryAction,
	MenuItem,
	ListItemIcon
} from "@material-ui/core";

import Avatar from "./avatar";

import LastActivity from "./last-activity";
import NodeName from "./name";
import MoreMenu from "../more-menu";
import RefableLink from "../refable-link";
import { PowerSettingsNew } from "@material-ui/icons";
import useDialog from "../use-dialog";
import { useShutdownNode } from "./api";
import { ShutdownDialog } from "./shutdown-dialog";

const NodeItem = ({ ListItemProps: _ListItemProps = {}, org, node }) => {
	const ListItemProps = {
		color: "inherit",
		..._ListItemProps
	};

	const shutdownDialog = useDialog();

	const [cancelJobs, setCancelJobs] = useState(false);
	const handleCancelJobsChange = useCallback(e => {
		setCancelJobs(e?.target?.checked);
	}, []);

	const shutdownReq = useShutdownNode({ org, id: node.id, cancelJobs });

	const handleConfirmShutdown = useCallback(() => {
		shutdownReq.fetch();
		shutdownDialog.hide();
	}, [shutdownDialog, shutdownReq]);

	return (
		<>
			<ListItem {...ListItemProps}>
				<ListItemAvatar>
					<Avatar
						machine={node.machine}
						isConnected={!node.disconnectedAt}
						isOnline={node.isOnline}
						shutdownRequestedAt={node.shutdownRequestedAt}
					/>
				</ListItemAvatar>
				<NodeName node={node} />
				<ListItemText
					style={{ flexBasis: "25%" }}
					primary={node.runningAs}
					secondary={<>PID {node.pid}</>}
				/>
				<LastActivity node={node} shutdownReq={shutdownReq} />
				<ListItemSecondaryAction>
					<MoreMenu
						IconButtonProps={{
							disabled:
								!node.isOnline ||
								shutdownReq.isFetching ||
								node.shutdownRequestedAt
						}}
					>
						<MenuItem {...shutdownDialog.buttonProps} component={RefableLink}>
							<ListItemIcon>
								<PowerSettingsNew />
							</ListItemIcon>
							<ListItemText primary="Shut down node" />
						</MenuItem>
					</MoreMenu>
				</ListItemSecondaryAction>
			</ListItem>
			<ShutdownDialog
				{...{
					shutdownDialog,
					cancelJobs,
					handleCancelJobsChange,
					handleConfirmShutdown
				}}
			/>
		</>
	);
};

const areEqual = (prev, next) =>
	JSON.stringify(prev?.node) === JSON.stringify(next?.node);

export default React.memo(NodeItem, areEqual);
