import { useCallback } from "react";
import useMutationHandler from "../../mutation-handler";
import { usePrevious } from "../../use-previous";

export const useRemoteCollectionMessages = ({
	location,
	itemsById,
	requests,
	selections,
	itemDescription = "item",
	nameKey = "id"
}) => {
	const wasDeletingId = usePrevious(selections.deleteItem);
	const oldItemsById = usePrevious(itemsById);
	const lastDeletedName = oldItemsById?.[wasDeletingId]?.[nameKey];

	useSuccessMessage({
		location,
		req: requests.postItem,
		message: useSuccessMessageFormatter({
			verb: "Created",
			description: itemDescription,
			nameKey
		})
	});
	useSuccessMessage({
		location,
		req: requests.patchItem,
		message: useSuccessMessageFormatter({
			verb: "Updated",
			description: itemDescription,
			nameKey
		})
	});
	useSuccessMessage({
		location,
		req: requests.deleteItem,
		message: {
			text: `Deleted ${itemDescription} ${lastDeletedName}`
		}
	});
};

const useSuccessMessage = ({ location, req, message }) => {
	useMutationHandler({
		req,
		location,
		successNotification: message
			? typeof message === "function"
				? message(req.body)
				: message
			: null
	});
};

export const useSuccessMessageFormatter = ({
	verb = "Created",
	description = "item",
	nameKey = "name",
	text
}) => {
	return useCallback(
		body => {
			if (text) {
				return { text };
			}
			const name = body?.[nameKey];
			return { text: `${verb} ${description} ${name}` };
		},
		[description, nameKey, text, verb]
	);
};
