import React, { useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Router } from "@reach/router";

import { Card, CardHeader, Grid } from "@material-ui/core";

import useSaveNewOrg from "./api";

import Layout from "../../layout";
import { useWidth } from "../../layout/context";

import StripeProvider from "../../billing/stripe";

import useMutationHandler from "../../mutation-handler";

import Wizard from "../../wizard";

import ConfigStep from "./steps/config";
import PlanStep from "./steps/plan";
import PaymentStep from "./steps/payment";

const steps = [ConfigStep, PlanStep, PaymentStep];

const useWizardState = () => {
	const [name, setName] = useState();
	const [plan, setPlan] = useState();
	const [paymentMethod, setPaymentMethod] = useState();

	return {
		name,
		plan,
		paymentMethod,
		setName,
		setPlan,
		setPaymentMethod
	};
};

const NewOrgPage = ({ location }) => {
	useWidth("md");
	const state = useWizardState();

	const { fetch: saveNewOrg, ...req } = useSaveNewOrg(state);

	useMutationHandler({
		req,
		successRedirectUrl: `/dashboard/${req.body?.id}`,
		successNotification: {
			messageId: `${req.body?.id}-new-org`,
			text: `Created the ${req.body?.name} organization!`
		},
		errorNotification: { verb: "creating", description: "organization" }
	});

	const onSubmit = useCallback(
		ev => {
			ev.preventDefault();
			saveNewOrg({
				name: state.name,
				planId: state.plan?.id,
				paymentMethodId: state.paymentMethod?.id
			});
		},
		[saveNewOrg, state.name, state.paymentMethod, state.plan]
	);

	return (
		<Layout location={location} navTabs={false}>
			<Helmet>
				<title>Create a New Organization</title>
			</Helmet>

			<StripeProvider>
				<Grid container spacing={2}>
					<Grid item md={12}>
						<Card>
							<CardHeader
								title="Create a New Organization"
								subheader="Collaborate with your team to create & run jobs."
							/>
						</Card>
					</Grid>

					<Grid item md={12}>
						<Card>
							<Router>
								<Wizard path="/" steps={steps}>
									{steps.map(Step => (
										<Step
											key={Step.label}
											path={Step.slug}
											{...state}
											req={req}
											onSubmit={onSubmit}
										/>
									))}
								</Wizard>
							</Router>
						</Card>
					</Grid>
				</Grid>
			</StripeProvider>
		</Layout>
	);
};

export default NewOrgPage;
