import React from "react";
import { List } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Button } from "@runly/gatsby-theme";

import ClusterItem, { EnvOverviewClusterInfo } from "./item";
import EmptyMessage from "../empty-message";

const ClusterList = ({ org, env, clusters, ListItemProps }) => {
	const classes = useStyles();

	if (!clusters || !clusters.length) {
		return (
			<EmptyMessage>
				<>Configure a cluster to run jobs for this environment.</>
				<div className={classes.actionButton}>
					<Button
						color="primary"
						variant="contained"
						to={`/dashboard/${org}/env/${env}/settings/clusters`}
						title="Add a cluster to this environment"
					>
						Add Cluster
					</Button>
				</div>
			</EmptyMessage>
		);
	}

	return (
		<List>
			{clusters.map(cluster => {
				return (
					<ClusterItem
						key={cluster.id}
						{...{ org, env, cluster, ListItemProps }}
					>
						<EnvOverviewClusterInfo cluster={cluster} />
					</ClusterItem>
				);
			})}
		</List>
	);
};

const useStyles = makeStyles(theme => ({
	actionButton: {
		marginTop: theme.spacing(1)
	}
}));

export default ClusterList;
