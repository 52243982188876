import React, { useState, useCallback } from "react";
import { CardElement } from "react-stripe-elements";
import TextField from "@material-ui/core/TextField";
import StripeInput from "./input";

const StripeTextField = ({
	InputLabelProps,
	InputProps,
	fullWidth = true,
	label = "Credit Card",
	labelErrorMessage,
	margin,
	stripeComponent: StripeComponent = CardElement,
	subscribeToIsComplete = Function.prototype,
	subscribeToIsEmpty = Function.prototype,
	subscribeToIsError = Function.prototype,
	subscribeToErrorMessage = Function.prototype,
	...other
}) => {
	const [isFocused, setIsFocused] = useState(false);
	const [, setIsComplete] = useState(false);
	const [isEmpty, setIsEmpty] = useState(true);
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	const handleBlur = useCallback(() => {
		setIsFocused(false);
	}, []);
	const handleFocus = useCallback(() => {
		setIsFocused(true);
	}, []);

	const handleChange = useCallback(
		({ error, empty, complete }) => {
			setIsComplete(complete);
			subscribeToIsComplete(complete);
			setIsError(!!error);
			subscribeToIsError(!!error);
			setErrorMessage(error?.message || null);
			if (error?.message) {
				subscribeToErrorMessage(error.message);
			}
			setIsEmpty(empty);
			subscribeToIsEmpty(empty);
		},
		[
			subscribeToErrorMessage,
			subscribeToIsComplete,
			subscribeToIsEmpty,
			subscribeToIsError
		]
	);

	const shrink = isFocused || !isEmpty;

	return (
		<TextField
			fullWidth={fullWidth}
			margin={margin}
			label={
				isError
					? labelErrorMessage || errorMessage || `Invalid ${label}`
					: label
			}
			error={isError}
			InputLabelProps={{
				...InputLabelProps,
				focused: isFocused,
				shrink,
				style:
					StripeComponent === CardElement && !shrink
						? { transform: "translate(40px, 12px)" }
						: undefined
			}}
			InputProps={{
				...InputProps,
				inputProps: {
					isFocused,
					component: StripeComponent,
					onFocus: handleFocus,
					onBlur: handleBlur,
					onChange: handleChange
				},
				inputComponent: StripeInput
			}}
			{...other}
		/>
	);
};

export default StripeTextField;
