import React from "react";
import { Box, Card, List, CardHeader, CardContent } from "@material-ui/core";

import { LoadingIndicator } from "@runly/gatsby-theme";

import ShellExample, { ShellCommand } from "../../shell-example";

import SettingsProperty from "../../settings-property";
import DateTime from "../../time";
import UserProfile from "../../user-profile";

import EnvChip from "../../env/chip";
import { FadeIn } from "../../skeleton/fade-in";
import { SkeletonListItem } from "../../skeleton/list";
import { Skeleton } from "@material-ui/lab";
import { SkeletonCardHeader } from "../../skeleton/card";

const ClusterInfo = props => {
	return (
		<Card>
			<CardHeader title="General" />
			<ClusterContent {...props} />
		</Card>
	);
};

const ClusterContent = ({ org, cluster }) => {
	if (!cluster) {
		return <LoadingIndicator />;
	}

	const returnLabel = { id: cluster.id, label: "Cluster" };

	return (
		<List>
			<Box display={["block", "block", "flex"]}>
				<SettingsProperty title="Created">
					<span>
						<DateTime value={cluster.createdAt} /> by{" "}
						<UserProfile userId={cluster.createdBy} returnLabel={returnLabel} />
					</span>
				</SettingsProperty>

				<SettingsProperty title="Modified">
					<span>
						<DateTime value={cluster.updatedAt} /> by{" "}
						<UserProfile userId={cluster.updatedBy} returnLabel={returnLabel} />
					</span>
				</SettingsProperty>

				<SettingsProperty title="Hosting Environments">
					{cluster.environments.length ? (
						<Box display="flex">
							{cluster.environments.map(({ id, isProduction }) => {
								const envLink = `/dashboard/${org}/env/${id}`;
								return (
									<Box key={id} pr={1}>
										<EnvChip
											org={org}
											id={id}
											isProduction={isProduction}
											settingsLink={`${envLink}/settings/clusters`}
											settingsTitle="Configure Clusters for Environment"
										/>
									</Box>
								);
							})}
						</Box>
					) : (
						<span>None</span>
					)}
				</SettingsProperty>
			</Box>

			{cluster.apiKey ? (
				<SettingsProperty title="Connect a Node">
					<ShellExample docUrl="/getting-started/">
						<ShellCommand command="set-apikey" args={[cluster.apiKey]} />
						<ShellCommand command="start-node" />
					</ShellExample>
				</SettingsProperty>
			) : null}
		</List>
	);
};

export const ClusterInfoPlaceholder = () => {
	return (
		<FadeIn>
			<Card>
				<SkeletonCardHeader subheader={false} />
				<SkeletonListItem avatar={false} cols={3} />
				<CardContent>
					<Box mb={1}>
						<Skeleton width="12em" />
					</Box>
					<Box mb={1}>
						<Skeleton variant="rect" width="100%" height={56} />
					</Box>
					<Skeleton width="8em" />
				</CardContent>
			</Card>
		</FadeIn>
	);
};

export default ClusterInfo;
