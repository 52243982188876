import React, { useCallback, useState, useEffect } from "react";

import { Typography } from "@material-ui/core";
import { WrapText as WrapTextIcon } from "@material-ui/icons";

import DangerConfirm, {
	useDangerConfirmState,
	useDangerButtonStyles
} from "../../../danger/confirm";
import DangerZone from "../../../danger/zone";
import DangerItem from "../../../danger/item";

import SlugEditor from "../../../slug/editor";
import FetchButton from "../../../busy-button/fetch-button";
import useMutationHandler from "../../../mutation-handler";

import { useUpdateOrg } from "../../api";
import { useOnIdChange } from "../../context";

const OrgDangerZone = ({ organization }) => {
	const { fetch: save, ...req } = useUpdateOrg(organization.id);

	const [newId, setNewId] = useState("");

	const onIdChange = useOnIdChange();
	useEffect(() => {
		if (req.isFetched) {
			onIdChange(organization.id, req.body?.id);
		}
	}, [onIdChange, organization.id, req.body, req.isFetched]);

	useMutationHandler({
		req,
		successRedirectUrl: `/dashboard/${newId}/settings`,
		successNotification: {
			messageId: `${organization.id}-id-change`,
			text: `Updated ID for ${organization.name}`
		},
		errorNotification: { verb: "saving", description: "organization ID" }
	});

	const changeIdDialog = useDangerConfirmState({
		magicWord: organization.id,
		onConfirm: useCallback(() => save({ id: newId }), [newId, save]),
		validate: useCallback(() => organization.id !== newId, [
			newId,
			organization.id
		])
	});

	const dangerButtonClasses = useDangerButtonStyles({ isAction: true });

	return (
		<>
			<DangerZone>
				<DangerItem
					title="Change ID"
					action={
						<FetchButton
							req={req}
							classes={dangerButtonClasses}
							variant="outlined"
							startIcon={<WrapTextIcon />}
							{...changeIdDialog.buttonProps}
							busyChildren={<>Changing ID</>}
						>
							Change ID
						</FetchButton>
					}
				>
					Change the globally unique ID for this organization. This will break
					integrations that currently depend on this organization.
				</DangerItem>
			</DangerZone>

			<DangerConfirm
				{...changeIdDialog}
				title="Confirm Change ID"
				cancelText="Don't change"
				actionText="Save new ID"
				preamble={
					<>
						<Typography paragraph>
							This will change the ID that is used to identify this organization
							in the API. Changing it will break any integrations you may have
							if you rely on this organization identifier in your app.
						</Typography>
						<Typography>First, enter the existing organization ID:</Typography>
					</>
				}
			>
				<SlugEditor
					label="New Organization ID"
					value={newId}
					onChange={useCallback(slug => setNewId(slug), [])}
					variant="outlined"
					fullWidth
					error={newId === organization.id}
					helperText={
						newId === organization.id ? <>New ID must be different</> : null
					}
				/>
			</DangerConfirm>
		</>
	);
};

export default OrgDangerZone;
