import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Grow
	// ListItemAvatar
} from "@material-ui/core";

export const RunCancelDialog = ({ cancelDialog, handleCancel }) => {
	return (
		<Dialog
			{...cancelDialog.dialogProps}
			fullWidth
			TransitionComponent={Grow}
			PaperProps={{ style: { overflow: "visible" } }}
		>
			<DialogTitle>Cancel Run</DialogTitle>
			<DialogContent>Are you sure you want to cancel this run?</DialogContent>
			<DialogActions>
				<Button onClick={cancelDialog.hide}>Don't cancel</Button>
				<Button variant="outlined" color="primary" onClick={handleCancel}>
					Cancel run
				</Button>
			</DialogActions>
		</Dialog>
	);
};
