import { useCallback, useEffect } from "react";
import solarizedLight from "monaco-themes/themes/Solarized-light";
import solarizedDark from "monaco-themes/themes/Solarized-dark";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import tc from "tinycolor2";

// not calling it just "monaco" anywhere here because
// monaco-editor adds a GLOBALLY SCOPED `monaco`
const getEditorValidation = (instance, _monaco) => {
	const invalidMarkers = [
		_monaco.MarkerSeverity.Error,
		_monaco.MarkerSeverity.Warning
	];
	const model = instance.getModel();
	if (model) {
		const owner = model.getModeId();
		const markers = _monaco.editor.getModelMarkers({ owner });

		const errorMarkers = markers.filter(marker =>
			invalidMarkers.includes(marker.severity)
		);
		if (errorMarkers.length) {
			return true;
		}
	}
	return false;
};

const useStyles = makeStyles(theme => {
	return {
		"@global": {
			".monaco-editor": {
				backgroundColor: ["transparent", "!important"]
			},
			".monaco-editor-background": {
				backgroundColor: [
					tc(theme.palette.background.default).setAlpha(0.85).toString(),
					"!important"
				],
				backdropFilter: "blur(5px)"
			}
		}
	};
});

const useEditorInit = ({
	_monaco,
	_setMonaco,
	instance,
	setInstance,
	setHasValidationErrors
}) => {
	const theme = useTheme();
	useStyles();

	const handleEditorWillMount = useCallback(
		m => {
			m.editor.defineTheme("solarized-light", {
				...solarizedLight,
				rules: [
					{
						...solarizedLight.rules,
						background: theme.palette.background.default
					}
				],
				colors: {
					...solarizedLight.colors,
					"editor.background": theme.palette.background.default
				}
			});
			m.editor.defineTheme("solarized-dark", solarizedDark);
			_setMonaco(m);
		},
		[_setMonaco, theme.palette.background.default]
	);

	const handleEditorDidMount = useCallback(
		editor => {
			setInstance(editor);
			editor.focus();
		},
		[setInstance]
	);

	useEffect(() => {
		if (instance) {
			instance.onDidChangeModelDecorations(() => {
				setHasValidationErrors(getEditorValidation(instance, _monaco));
			});
		}
	}, [instance, _monaco, setHasValidationErrors]);
	return {
		handleEditorWillMount,
		handleEditorDidMount,
		_monaco
	};
};

export default useEditorInit;
