import React from "react";
import { Popper, Grow } from "@material-ui/core";

const GrowPopper = ({ children, ...props }) => (
	<Popper {...props} transition>
		{({ TransitionProps }) => (
			<Grow
				{...TransitionProps}
				style={{ transformOrigin: "50% 0 0" }}
				timeout={150}
			>
				<div>{children}</div>
			</Grow>
		)}
	</Popper>
);

export default GrowPopper;
