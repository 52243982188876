import React, {
	memo,
	useState,
	useCallback,
	useRef,
	useEffect,
	useLayoutEffect
} from "react";
import { Helmet } from "react-helmet";

import { Tabs, Tab, Button, Box, SvgIcon, IconButton } from "@material-ui/core";
import { Lock, LockOpen } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { mdiPlaylistRemove, mdiFileDownload } from "@mdi/js";

import Ansi, { useAnsiStyles } from "./ansi";
import { useInView } from "react-intersection-observer";
import prettyBytes from "pretty-bytes";

import { solarized } from "../../../solarized";
import { usePrevious } from "../../../use-previous";

import useLogConnection from "./channel";
import { useFetchRunLogInfo } from "../api";
import { last } from "lodash";

const tabs = ["stdout", "stderr"];

const RunLog = ({ org, run, connection }) => {
	const [logTab, setLogTab] = useState(0);
	const onChange = useCallback((evt, newValue) => setLogTab(newValue), []);

	const logType = tabs[logTab];
	const [logs, { onClear }] = useLogConnection(connection, logType);

	const { body: info } = useFetchRunLogInfo({ org, runId: run?.id });

	const handleClear = useCallback(() => {
		onClear();
	}, [onClear]);

	const [isPinned, setIsPinned] = useState(true);
	const pin = useCallback(() => {
		setIsPinned(true);
	}, []);
	const unpin = useCallback(() => {
		setIsPinned(false);
	}, []);
	const togglePinned = useCallback(() => {
		setIsPinned(p => !p);
	}, []);

	return (
		<>
			<Helmet>
				<title>Run Logs</title>
			</Helmet>

			<Box display="flex">
				<Tabs value={logTab} onChange={onChange}>
					{tabs.map(t => (
						<Tab key={t} label={t} />
					))}
				</Tabs>
				<Box style={{ marginLeft: "auto" }} display="flex" alignItems="center">
					{logs?.logs?.length ? (
						<Box mr={1}>
							<IconButton title="Clear log display" onClick={handleClear}>
								<SvgIcon>
									<path d={mdiPlaylistRemove} />
								</SvgIcon>
							</IconButton>
						</Box>
					) : null}
					<Box mr={1}>
						<IconButton
							onClick={togglePinned}
							title={isPinned ? "Unlock scroll" : "Lock scroll"}
						>
							{isPinned ? <LockOpen /> : <Lock />}
						</IconButton>
					</Box>
					<LogDownload info={info?.[logType]} />
				</Box>
			</Box>
			<LogLines {...logs} description={logType} {...{ isPinned, pin, unpin }} />
		</>
	);
};

const _LogLines = ({ isLoading, logs, description, isPinned, pin, unpin }) => {
	useAnsiStyles();
	const classes = useStyles();
	const scrollContainer = useRef();

	const handleWheel = useCallback(
		e => {
			if (e.deltaY < 0) {
				unpin();
			}
		},
		[unpin]
	);

	const handleTouchMove = useCallback(() => {
		unpin();
	}, [unpin]);

	const [inViewRef, isInView] = useInView({ rootMargin: "64px 0px 0px 0px" });

	const wasInView = usePrevious(isInView);

	useEffect(() => {
		if (!wasInView && isInView) {
			pin();
		}
	}, [isInView, pin, wasInView]);

	const logSize = logs.length;
	const lastId = last(logs)?.id;
	const previousLastId = usePrevious(lastId);

	useLayoutEffect(() => {
		if (
			logSize &&
			lastId !== previousLastId &&
			isPinned &&
			scrollContainer.current
		) {
			scrollContainer.current.scrollTop = scrollContainer.current.scrollHeight;
		}
	}, [lastId, isPinned, logSize, previousLastId]);

	return (
		<>
			<pre
				className={classes.logContainer}
				onWheel={handleWheel}
				onTouchMove={handleTouchMove}
				ref={scrollContainer}
			>
				<Box px={2} py={1} className={classes.logBox}>
					{isLoading ? <Ansi>{`Waiting for ${description}…`}</Ansi> : null}
					{!isLoading && !logs.length ? (
						<Ansi>{`No ${description} logs.`}</Ansi>
					) : null}

					{logs.map(({ id, value }) => (
						<Ansi id={id} key={id}>
							{value}
						</Ansi>
					))}
					<div ref={inViewRef} />
				</Box>
			</pre>
		</>
	);
};

const LogLines = memo(_LogLines);

const useStyles = makeStyles({
	logContainer: {
		height: "80vh",
		overflowY: "auto",
		background: solarized.base03,
		color: solarized.base2
	},
	logBox: {
		fontSize: 13,
		fontFamily: "'Fira Code', Consolas, monospace",
		whiteSpace: "pre-wrap"
	}
});

const FileDownloadIcon = props => (
	<SvgIcon {...props}>
		<path d={mdiFileDownload} />
	</SvgIcon>
);

const RunLogMemo = memo(RunLog);
export default RunLogMemo;

const LogDownload = ({ info }) => {
	return info?.size ? (
		<Button
			variant="outlined"
			style={{ marginLeft: "auto" }}
			href={info.downloadUrl}
			startIcon={<FileDownloadIcon />}
		>
			Download ({prettyBytes(info.size)})
		</Button>
	) : null;
};
