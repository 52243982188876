import React from "react";
import { useLocation } from "@reach/router";

import { MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";

import MoreMenu from "../more-menu";
import RefableLink from "../refable-link";
import { useAccount } from "../account/context";

const MemberMenu = ({ org, member, handleRemoveClick }) => {
	const me = useAccount();
	const isRemovalDisabled = me.id == member.userId;

	const location = useLocation();

	return (
		<MoreMenu>
			<MenuItem
				component={RefableLink}
				display="block"
				to={`/dashboard/${org}/members/${member.id}`}
				state={{ returnUrl: location.pathname }}
			>
				<ListItemIcon>
					<EditIcon />
				</ListItemIcon>
				<ListItemText primary="Change Role" />
			</MenuItem>
			<MenuItem onClick={handleRemoveClick} disabled={isRemovalDisabled}>
				<ListItemIcon>
					<DeleteIcon />
				</ListItemIcon>
				<ListItemText
					primary="Remove Member"
					secondary={isRemovalDisabled ? "Cannot remove yourself" : null}
				/>
			</MenuItem>
		</MoreMenu>
	);
};

export default MemberMenu;
