import React from "react";
import {
	Dialog,
	DialogContent,
	FormControlLabel,
	Checkbox,
	DialogTitle,
	DialogContentText,
	DialogActions,
	Button
} from "@material-ui/core";

export const ShutdownDialog = ({
	shutdownDialog,
	cancelJobs,
	handleCancelJobsChange,
	handleConfirmShutdown
}) => {
	return (
		<Dialog {...shutdownDialog.dialogProps}>
			<DialogTitle>Shut Down Node</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Are you sure you want to shut down this node?
					<br />
					You will not be able to turn it back on from Runly.
				</DialogContentText>
				<FormControlLabel
					label="Cancel jobs running on this node"
					control={
						<Checkbox checked={cancelJobs} onChange={handleCancelJobsChange} />
					}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={shutdownDialog.hide}>Don't shut down</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={handleConfirmShutdown}
				>
					Shut down
				</Button>
			</DialogActions>
		</Dialog>
	);
};
