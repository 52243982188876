import { useFetchRunHistory } from "../../api";

import { usePager } from "./pager";

const useRuns = ({ org, query }) => {
	const runsHistoryRequest = useFetchRunHistory({
		org,
		query
	});
	const runs = runsHistoryRequest?.body?.data || [];
	const hasMore = runsHistoryRequest?.body?.hasMore;

	const pager = usePager({
		hasMore,
		query,
		runs
	});

	return {
		runsHistoryRequest,

		runs,
		pager
	};
};

export default useRuns;
