import React, { useState, useCallback, useEffect } from "react";

import {
	Card,
	DialogActions,
	CardContent,
	Button,
	TextField,
	CardHeader
} from "@material-ui/core";

import FetchButton from "../../../busy-button/fetch-button";

import { useUpdateOrg } from "../../api";

const OrgEditSettings = ({ organization, onCancelEdit, onEdited }) => {
	const [name, setName] = useState(organization.name);
	const onChangeName = useCallback(evt => setName(evt.target.value), []);

	const { fetch: save, ...req } = useUpdateOrg(organization.id);

	useEffect(() => {
		if (req.isFetched) {
			onEdited(req.body);
		}
	}, [onEdited, req.body, req.isFetched]);

	return (
		<Card>
			<form
				onSubmit={useCallback(
					ev => {
						ev.preventDefault();
						save({ name });
					},
					[name, save]
				)}
			>
				<CardHeader title={`Edit ${organization.name} settings`} />
				<CardContent>
					<TextField
						id="org-name"
						label="Name"
						value={name}
						onChange={onChangeName}
						fullWidth
					/>
				</CardContent>
				<DialogActions>
					<Button disabled={req.isFetching} onClick={onCancelEdit}>
						Don't save
					</Button>
					<FetchButton
						type="submit"
						variant="contained"
						color="primary"
						data-testid="save-btn"
						req={req}
						busyChildren={<>Saving…</>}
					>
						Save settings
					</FetchButton>
				</DialogActions>
			</form>
		</Card>
	);
};

export default OrgEditSettings;
