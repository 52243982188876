import React, { useCallback, useState, useMemo } from "react";
import { Router } from "@reach/router";
import { Helmet } from "react-helmet";

import { Grid, Paper, Box } from "@material-ui/core";

import Layout from "../layout";

import { useFetchAccount, useFetchOrgAccounts } from "./api";

import FakeSuspense from "../fake-suspense";

import Summary from "./summary";
import Settings from "./settings";
import OrgSettings from "./orgs";
import ChangeOrgSettings from "./orgs/settings";
import { SkeletonCardHeader } from "../skeleton/card";
import { SkeletonListItem } from "../skeleton/list";
import NavTabs from "../layout/nav-tabs";
import Apps from "./apps";

const useOrgAccountState = orgReq => {
	const [changedEmails, setChangedEmails] = useState({});

	const orgs = useMemo(() => {
		let orgs = orgReq.body;
		if (!orgs) return orgs;

		return orgs.map(o => {
			if (!changedEmails[o.id]) return o;

			return {
				...o,
				email: changedEmails[o.id]
			};
		});
	}, [changedEmails, orgReq.body]);

	const onEmailChanged = useCallback((org, email) => {
		setChangedEmails(s => ({ ...s, [org]: email }));
	}, []);

	return { orgs, onEmailChanged };
};

const useAccountState = userReq => {
	const [changes, setChanges] = useState({});

	const onInfoChange = useCallback(
		newInfo => setChanges(u => ({ ...u, ...newInfo })),
		[]
	);

	const user = useMemo(() => {
		const user = userReq.body;
		if (!user) return user;
		return { ...user, ...changes };
	}, [changes, userReq.body]);

	return { user, onInfoChange };
};

const Account = ({ location }) => {
	const userReq = useFetchAccount();
	const { user, onInfoChange } = useAccountState(userReq);

	const orgReq = useFetchOrgAccounts();
	const { orgs, onEmailChanged } = useOrgAccountState(orgReq);

	return (
		<Layout
			location={location}
			maxWidth="md"
			navTabs={
				<NavTabs
					tabs={[
						{ href: "/dashboard/account", label: "Account" },
						{ href: "/dashboard/account/apps", label: "Apps" }
					]}
					location={location}
				/>
			}
		>
			<Helmet>
				<title>Account Settings</title>
			</Helmet>

			<FakeSuspense
				req={userReq}
				description="Account"
				placeholder={
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<Paper>
								<SkeletonCardHeader />
								<SkeletonListItem cols={2} />
							</Paper>
						</Grid>
						<Grid item xs={12} md={12}>
							<Box clone height={200}>
								<Paper />
							</Box>
						</Grid>
						<Grid item xs={12} md={12}>
							<Box clone height={120}>
								<Paper />
							</Box>
						</Grid>
						<Grid item xs={12} md={12}>
							<Box clone height={120}>
								<Paper />
							</Box>
						</Grid>
					</Grid>
				}
			>
				<FakeSuspense req={orgReq} description="Organizations">
					<Router>
						<Apps path="apps" orgs={orgs} />
						<ChangeOrgSettings
							path="/orgs/:org"
							orgs={orgs}
							onChange={onEmailChanged}
						/>
						<AccountRoot
							path="/*"
							user={user}
							orgs={orgs}
							onChange={onInfoChange}
						/>
					</Router>
				</FakeSuspense>
			</FakeSuspense>
		</Layout>
	);
};

const AccountRoot = ({ user, orgs, onChange }) => {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={6}>
				<Summary user={user} orgs={orgs} />
			</Grid>
			<Grid item xs={12} md={12}>
				<Settings user={user} orgs={orgs} onChange={onChange} />
			</Grid>
			<Grid item xs={12} md={12}>
				<OrgSettings orgs={orgs} />
			</Grid>
		</Grid>
	);
};

export default Account;
