import React from "react";
import { Helmet } from "react-helmet";

import { some } from "lodash";

import { Paper, Box, Card, List } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import { SkeletonListItem } from "../../skeleton/list";
import { FadeIn } from "../../skeleton/fade-in";

import ProgressCategories from "../progress/categories";

const RunResults = ({ org, env, run }) => {
	const isNotUsingCategories =
		run?.progress?.categories &&
		((run.progress.categories.length == 1 &&
			run.progress.categories[0].category == "Successful") ||
			(run.progress.categories.length == 2 &&
				some(run.progress.categories, c => c.category == "Successful") &&
				some(run.progress.categories, c => c.category == "Failed")));

	return (
		<>
			<Helmet>
				<title>Run Results</title>
			</Helmet>

			<Box my={2}>
				{run ? (
					run.progress &&
					(run.progress.success ||
						run.progress.failed ||
						run.progress.total) ? (
						<Box mb={2}>
							<Card>
								<ProgressCategories
									{...{ org, env }}
									runId={run.id}
									{...run.progress}
								/>
							</Card>
						</Box>
					) : null
				) : (
					<SmallSkeleton secondaryAction />
				)}
			</Box>

			{isNotUsingCategories ? (
				<Box mb={2}>
					<Alert severity="info">
						<AlertTitle>Make Use of Result Categories</AlertTitle>
						You are currently using the default categories of{" "}
						<em>Successful</em> and <em>Failed</em>. You can return custom
						categories to segment your job's results into more granular items.
					</Alert>
				</Box>
			) : null}
		</>
	);
};

const CardSkeleton = ({ py = 1.5, ...props }) => (
	<FadeIn>
		<Box clone mb={2} py={py}>
			<Paper>
				<List>
					<SkeletonListItem {...props} />
				</List>
			</Paper>
		</Box>
	</FadeIn>
);

const SmallSkeleton = props => (
	<CardSkeleton avatarSize={24} py={0} featuredCol {...props} />
);

export default RunResults;
