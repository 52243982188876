import React, { useCallback } from "react";

import {
	TextField,
	MenuItem,
	ListItemText,
	ListItemIcon,
	Box
} from "@material-ui/core";

import { startCase } from "lodash";
import RunStatusIcon from "../../status-icon";

const DISPOSITIONS = [
	{ value: "All" },
	{ value: "Successful", isSuccessful: true },
	{ value: "NotSuccessful", isSuccessful: false },
	{ value: "Failed", isSuccessful: false },
	{ value: "Cancelled", isSuccessful: false },
	{ value: "TimedOut", isSuccessful: false },
	{ value: "Error", isSuccessful: false }
];

const RunsDispositionFilter = ({ queryParams, onValueChange }) => {
	const selectedValue = queryParams.disposition;

	const onDispositionChange = useCallback(
		ev =>
			onValueChange(
				"disposition",
				ev.target.value != "All" ? ev.target.value : ""
			),
		[onValueChange]
	);

	return (
		<>
			<TextField
				fullWidth
				select
				variant="outlined"
				label="Disposition"
				value={selectedValue}
				SelectProps={{
					renderValue
				}}
				onChange={onDispositionChange}
			>
				{DISPOSITIONS.map(d => (
					<MenuItem key={d.value} value={d.value}>
						{d.value !== "All" ? (
							<ListItemIcon>
								<RunStatusIcon size={16} position="relative" state={d.value} />
							</ListItemIcon>
						) : null}
						<ListItemText>{startCase(d.value)}</ListItemText>
					</MenuItem>
				))}
			</TextField>
		</>
	);
};

export default RunsDispositionFilter;

const renderValue = v => {
	const isAll = v === "All";
	return (
		<Box display="flex" alignItems="center">
			{isAll ? null : (
				<Box mr={0.5}>
					<RunStatusIcon
						size={24}
						position="absolute"
						top={6}
						left={8}
						state={v}
					/>
				</Box>
			)}
			<Box ml={isAll ? 0 : 3}>{startCase(v)}</Box>
		</Box>
	);
};
