import React, { useCallback } from "react";
import { Router } from "@reach/router";
import { Helmet } from "react-helmet";

import { Box, Card, CardHeader } from "@material-ui/core";

import FakeSuspense from "../../fake-suspense";
import useMutationHandler from "../../mutation-handler";

import Layout from "../../layout";

import { useSaveNewCluster } from "../api";
import useNewClusterState from "./state";

import { useFetchOrgUsage } from "../../org/api";
import UpgradeCard from "../../billing/upgrade-card";

import Wizard from "../../wizard";
import NameStep from "./name";
import EnvStep from "./env";

const TITLE = "Configure New Cluster";
const STEPS = [
	{ label: "Choose ID", slug: "/" },
	{ label: "Assign to Environments", slug: "env" }
];

const NewClusterPage = ({ org, location }) => {
	const usageReq = useFetchOrgUsage(org);

	return (
		<Layout org={org} location={location} navTabs={false} maxWidth="md">
			<Helmet>
				<title>{TITLE}</title>
			</Helmet>

			<FakeSuspense req={usageReq} description="Usage">
				<NewClusterRoot org={org} location={location} usage={usageReq.body} />
			</FakeSuspense>
		</Layout>
	);
};

const NewClusterRoot = ({
	org,
	location,
	usage: {
		cluster: { limit, current }
	}
}) => {
	if (Number.isInteger(limit) && current >= limit) {
		return <UpgradeCard org={org} entity="cluster" limit={limit} />;
	}

	return <NewClusterForm org={org} location={location} />;
};

const NewClusterForm = ({ org, location: { state: locationState } }) => {
	const returnUrl = locationState?.env
		? `/dashboard/${org}/env/${locationState.env}/settings/clusters`
		: `/dashboard/${org}`;

	const { state, onChangeId, onToggleEnv } = useNewClusterState(
		locationState?.env
	);

	const { fetch: saveCluster, ...clusterSaveReq } = useSaveNewCluster(
		org,
		state
	);
	const onSave = useCallback(() => saveCluster(), [saveCluster]);

	const newClusterId = clusterSaveReq?.body?.id;

	useMutationHandler({
		req: clusterSaveReq,
		successRedirectUrl: returnUrl,
		successNotification: {
			messageId: newClusterId,
			text: `Created cluster ${newClusterId}`,
			actionLink: `/dashboard/${org}/clusters/${newClusterId}`,
			actionText: "View cluster"
		}
	});

	return (
		<>
			<Box mb={2}>
				<Card>
					<CardHeader
						title={TITLE}
						subheader="Two easy steps to segment your nodes."
					/>
				</Card>
			</Box>

			<Box>
				<Card>
					<Router>
						<Wizard path="/" steps={STEPS}>
							<NameStep path="/" {...{ state, onChangeId, returnUrl }} />
							<EnvStep
								path="env"
								{...{ state, onToggleEnv, clusterSaveReq, onSave }}
							/>
						</Wizard>
					</Router>
				</Card>
			</Box>
		</>
	);
};

export default NewClusterPage;
