import React from "react";
import EmptyMessage from "../../empty-message";
import RunsList from "../list";
import { take } from "lodash";

const ActiveRunsList = ({ org, env, runs, location }) => {
	if (!runs || !runs.length) {
		return <EmptyMessage>No active runs to show.</EmptyMessage>;
	}

	const _runs = take(runs, 5);

	return <RunsList {...{ org, env, runs: _runs, location }} />;
};

export default ActiveRunsList;
