import React, { useState, useEffect, useCallback } from "react";
import { Box, Grid, Typography, Link } from "@material-ui/core";
import { yellow } from "@material-ui/core/colors";
import {
	Warning as WarningIcon,
	CheckCircle as CheckCircleIcon,
	ArrowRightAlt as ArrowRightAltIcon
} from "@material-ui/icons";
import { useUpdateEnvPackage } from "../api";
import semver from "semver";
import useVersionOptions from "./version-picker/api";
import VersionPicker from "./version-picker";
import useMutationHandler from "../../../../../mutation-handler";

const EnvPackage = ({ org, env, pkg }) => {
	const [versionRange, setVersionRange] = useState(pkg.versionRange);
	useEffect(() => {
		setVersionRange(pkg.versionRange);
	}, [pkg.versionRange]);

	const [isFocusDirty, setIsFocusDirty] = useState(false);
	const onFocus = useCallback(() => {
		setIsFocusDirty(true);
	}, []);
	const onChange = useCallback((e, newValue) => {
		if (newValue) {
			if (typeof newValue === "object") {
				setVersionRange(newValue.label || newValue.version);
			} else {
				setVersionRange(newValue);
			}
		} else {
			setVersionRange(null);
		}
	}, []);
	const onInputChange = useCallback((e, value) => {
		setVersionRange(value);
	}, []);

	const { packageId } = pkg;

	const updatePkgReq = useUpdateEnvPackage({
		org,
		env,
		packageId,
		value: { versionRange }
	});

	useMutationHandler({
		req: updatePkgReq,
		errorNotification: { verb: "updating", description: "version range" }
	});

	const onSubmit = useCallback(
		e => {
			e.preventDefault?.();
			updatePkgReq.fetch();
		},
		[updatePkgReq]
	);

	const displayedPkg = (!updatePkgReq.error && updatePkgReq.body) || pkg;

	const isValueDirty = versionRange !== displayedPkg.versionRange;

	const pkgVersions = useVersionOptions({
		value: versionRange,
		isFocusDirty,
		org,
		packageId
	});

	const invalidRange =
		!semver.valid(semver.coerce(versionRange)) && versionRange !== "*";

	const semverLink = (
		<Link
			href="https://semver.org/"
			target="_blank"
			rel="noopener"
			color="inherit"
			underline="always"
		>
			SemVer
		</Link>
	);

	return (
		<>
			<Grid container spacing={3}>
				<Grid item md={4} sm={12} xs={12}>
					<Box pt={1}>
						<Typography variant="caption" color="textSecondary">
							Package ID
						</Typography>
						<Typography variant="h5" component="h2">
							{packageId}
						</Typography>
					</Box>
				</Grid>
				<Grid item md={4} sm={6} xs={12} component="form" onSubmit={onSubmit}>
					<Box pt={1}>
						<VersionPicker
							{...{
								onFocus,
								onChange,
								onInputChange,
								pkgVersions,
								invalidRange,
								semverLink,
								isFocusDirty,
								isValueDirty,
								updatePkgReq,
								displayedPkg,
								packageId,
								versionRange
							}}
						/>
					</Box>
				</Grid>
				<Grid item md={4} sm={6} xs={12}>
					<EnvPackageSummary
						{...displayedPkg}
						isFetching={updatePkgReq.isFetching}
					/>
				</Grid>
			</Grid>
		</>
	);
};

const EnvPackageSummary = ({ resolvedVersion, versionRange, isFetching }) => {
	return (
		<Box pt={1} style={isFetching ? { opacity: 0.8 } : null}>
			<Typography variant="caption" color="textSecondary">
				{versionRange !== resolvedVersion ? (
					<>Resolved Version</>
				) : (
					<>Version</>
				)}
			</Typography>
			<Typography>
				{resolvedVersion ? (
					<>
						<CheckCircleIcon style={{ fontSize: "1em" }} />
						{versionRange !== resolvedVersion ? (
							<>
								{versionRange} <ArrowRightAltIcon style={{ fontSize: "1em" }} />
							</>
						) : null}{" "}
						{resolvedVersion}
					</>
				) : (
					<>
						<WarningIcon style={{ fontSize: "1em", color: yellow["A400"] }} />{" "}
						Nothing resolved for <kbd>{versionRange}</kbd>
					</>
				)}
			</Typography>
		</Box>
	);
};

export default EnvPackage;
