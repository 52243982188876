import React from "react";

import { LoadingIndicator } from "@runly/gatsby-theme";

import ErrorMessage from "./error-message";

const SocketIndicator = ({
	connection,
	body,
	verb = "loading",
	description = "items",
	placeholder = <LoadingIndicator>Loading {description}…</LoadingIndicator>,
	children
}) => {
	if (!body && !connection.error) {
		return placeholder;
	}

	if (!body && connection.error) {
		return <ErrorMessage req={connection} {...{ verb, description }} />;
	}

	if (body && !connection.error) {
		return children;
	}

	if (body && connection.error) {
		return (
			<>
				{children}
				<ErrorMessage req={connection} {...{ verb, description }} />
			</>
		);
	}

	return null;
};

export default SocketIndicator;
