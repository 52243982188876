import React, { useMemo } from "react";

import { Box, IconButton, Paper } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

import { last } from "lodash";

import RefableLink from "../../refable-link";

import URI from "urijs";
import { CURS } from "./state/cursor";
import RunsList from "../list";
import KeyboardHelp from "../keyboard-help";

const RunsRoot = ({ org, env, hasMore, runs, pager, location }) => {
	return (
		<Paper>
			<RunsPager {...{ pager, location, runs }} />
			<RunsList {...{ org, env, hasMore, runs, location }} />
		</Paper>
	);
};

export const RunsPager = ({ pager, location, runs = [] }) => {
	const uri = useMemo(() => URI(`${location.pathname}${location.search}`), [
		location.pathname,
		location.search
	]);

	const prevPageLink = uri
		.removeSearch(CURS.AFTER)
		.setSearch(CURS.BEFORE, runs[0]?.id)
		.toString();

	const nextPageLink = uri
		.removeSearch(CURS.BEFORE)
		.setSearch(CURS.AFTER, last(runs)?.id)
		.toString();

	return (
		<>
			<Box display="flex" component="nav" aria-label="pagination" px={2}>
				<IconButton
					disabled={!pager.hasPrev}
					component={RefableLink}
					to={prevPageLink}
					title="Previous page"
					size="medium"
					edge="start"
				>
					<KeyboardArrowLeft />
				</IconButton>
				<IconButton
					disabled={!pager.hasNext}
					wrapperProps={{ style: { marginLeft: "auto" } }}
					component={RefableLink}
					to={nextPageLink}
					title="Next page"
					size="medium"
					edge="end"
				>
					<KeyboardArrowRight />
				</IconButton>
			</Box>
			<KeyboardHelp
				shortcuts={[
					{
						shortcut: "j",
						description: "Go to next page",
						navigateTo: nextPageLink,
						enabled: pager.hasNext
					},
					{
						shortcut: "k",
						description: "Go to previous page",
						navigateTo: prevPageLink,
						enabled: pager.hasPrev
					}
				]}
			/>
		</>
	);
};
export default RunsRoot;
