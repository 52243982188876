import React from "react";
import { Autocomplete } from "@material-ui/lab";
import renderOption from "./option";
import createRenderInput from "./input";
import filterOptions from "./filter";
import semver from "semver";

const VersionPicker = ({
	onFocus,
	onChange,
	onInputChange,
	pkgVersions,
	invalidRange,
	semverLink,
	isFocusDirty,
	isValueDirty,
	updatePkgReq,
	displayedPkg,
	packageId,
	versionRange
}) => {
	let options = pkgVersions.versions.map(version => ({ version }));
	const maxSatisfying = semver.maxSatisfying(
		options.map(o => o.version),
		versionRange
	);

	const firstOption =
		versionRange && maxSatisfying
			? { label: versionRange, preview: maxSatisfying }
			: undefined;

	if (firstOption) {
		options = [firstOption, ...options];
	}

	return (
		<Autocomplete
			id={`version-picker-${packageId}`}
			freeSolo
			autoHighlight
			includeInputInList
			onFocus={onFocus}
			onChange={onChange}
			onInputChange={onInputChange}
			value={{ version: versionRange }}
			options={options}
			noOptionsText="No matching version"
			getOptionLabel={o => o.label || o.version || o}
			getOptionSelected={(option, value) => {
				return (
					option.version === value.version || option.label === value.version
				);
			}}
			renderOption={renderOption}
			filterOptions={filterOptions(versionRange)}
			loading={pkgVersions?.req?.isFetching}
			renderInput={createRenderInput({
				invalidRange,
				semverLink,
				isFocusDirty,
				displayedPkg,
				isValueDirty,
				updatePkgReq
			})}
		/>
	);
};

export default VersionPicker;
