import React, { useCallback } from "react";

import {
	TextField,
	Typography,
	DialogActions,
	CardContent
} from "@material-ui/core";
import { ArrowRightAlt as ArrowRightIcon } from "@material-ui/icons";

import { Button } from "@runly/gatsby-theme";

import PlanStep from "./plan";

const StepContent = ({ navigate, name, setName }) => {
	const onNameChange = useCallback(
		ev => {
			setName(ev.target.value);
		},
		[setName]
	);

	const onSubmit = useCallback(
		ev => {
			navigate(PlanStep.slug);
			ev.preventDefault();
		},
		[navigate]
	);

	return (
		<form onSubmit={onSubmit}>
			<CardContent>
				<Typography variant="h5" component="h2">
					What’s your organization's name?
				</Typography>
				<Typography paragraph>
					Don't stress. You can change it later if you need to.
				</Typography>

				<TextField
					id="orgname"
					label="Organization Name"
					margin="normal"
					value={name}
					onChange={onNameChange}
					required
				/>
			</CardContent>

			<DialogActions>
				<Button to="/dashboard">Cancel</Button>
				<Button variant="contained" color="primary" type="submit">
					Pick a Plan
					<ArrowRightIcon />
				</Button>
			</DialogActions>
		</form>
	);
};

StepContent.label = "Configure your organization";
StepContent.slug = "/";

export default StepContent;
