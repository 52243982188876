import React, { useState, useCallback } from "react";
import { Card, List } from "@material-ui/core";

import useEventConnection from "./channel";

import RunEvent from "./event";

const RunEventTimeline = ({ connection }) => {
	const events = useEventConnection(connection);

	const [selected, setSelected] = useState();
	const handleSelectItem = useCallback(
		id => setSelected(current => setSelected(current === id ? null : id)),
		[]
	);

	return (
		<Card>
			<List>
				{events.map(ev => (
					<RunEvent
						key={ev.id}
						{...ev}
						selected={ev.id === selected}
						handleSelectItem={handleSelectItem}
					/>
				))}
			</List>
		</Card>
	);
};

export default RunEventTimeline;
