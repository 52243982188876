import React, { useImperativeHandle } from "react";
import { fade, useTheme } from "@material-ui/core/styles";
import { omit } from "lodash";

export default function StripeInput({
	component: StripeComponent,
	inputRef,
	isFocused,
	...rest
}) {
	const stripeInputProps = omit(rest, [
		"aria-invalid",
		"aria-describedby",
		"defaultValue",
		"required",
		"onKeyDown",
		"onKeyUp",
		"readOnly",
		"autoComplete",
		"autoFocus",
		"type",
		"name",
		"rows"
	]);

	const theme = useTheme();
	const [mountNode, setMountNode] = React.useState(null);

	useImperativeHandle(
		inputRef,
		() => ({
			focus: () => mountNode.focus()
		}),
		[mountNode]
	);

	return (
		<StripeComponent
			onReady={setMountNode}
			style={{
				base: {
					color: theme.palette.text.primary,
					fontSize: `${theme.typography.fontSize + 2}px`,
					fontFamily: theme.typography.fontFamily,
					"::placeholder": {
						color: isFocused
							? fade(theme.palette.text.primary, 0.42)
							: "transparent"
					}
				},
				invalid: {
					color: theme.palette.text.primary
				}
			}}
			{...stripeInputProps}
		/>
	);
}
