import { useMemo, useEffect } from "react";
import { navigate } from "@reach/router";

import usePaymentMethodEditorState from "../payment-method/state";
import { useFetchCheckoutPreview, useCheckout } from "./api";

const useCheckoutState = (org, planId, onCheckout) => {
	const {
		checkoutPreview,
		isFetching,
		isFetched,
		error: loadError
	} = useFetchCheckoutPreview(org, planId);

	const { checkout, isCheckedOut, ...checkoutResults } = useCheckout(
		org,
		planId
	);

	useEffect(() => {
		if (isCheckedOut && checkout) {
			onCheckout(checkout);
			navigate(`/dashboard/${org}/settings/billing`);
		}
	}, [checkout, isCheckedOut, onCheckout, org]);

	const { savedBilling, ...editorState } = usePaymentMethodEditorState(org);

	const result = useMemo(() => {
		if (!checkoutPreview) return null;

		return {
			...checkoutPreview,
			paymentMethod:
				savedBilling?.paymentMethod || checkoutPreview?.paymentMethod
		};
	}, [checkoutPreview, savedBilling]);

	return {
		isFetching,
		isFetched,
		loadError,

		...checkoutResults,
		checkout,
		isCheckedOut,

		...editorState,

		checkoutPreview: result
	};
};

export default useCheckoutState;
