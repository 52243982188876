import React from "react";
import { Helmet } from "react-helmet";

import { Card, CardHeader, List } from "@material-ui/core";

import { useFetchEnvClusters } from "./api";

import AddFab from "../../../../add-fab";

import { EnvClustersTitle, EnvClustersDescription } from "../../../../blurbs";

import EnvClusterListItem from "./item";
import FakeSuspense from "../../../../fake-suspense";
import { SkeletonList } from "../../../../skeleton/list";
import { FadeIn } from "../../../../skeleton/fade-in";

const EnvClusters = ({ org, env, environment }) => {
	const clustersReq = useFetchEnvClusters({
		org,
		selectedClusters: environment.clusters
	});

	return (
		<>
			<Helmet>
				<title>Configure Clusters</title>
			</Helmet>

			<Card>
				<CardHeader
					title={<EnvClustersTitle env={env} />}
					subheader={<EnvClustersDescription env={env} />}
					action={
						<AddFab
							title="Add New Cluster"
							to={`/dashboard/${org}/clusters/new`}
							state={{ env }}
						/>
					}
				/>
				<FakeSuspense
					req={clustersReq}
					description={<>{env} clusters</>}
					placeholder={
						<FadeIn>
							<SkeletonList size={3} secondaryAction />
						</FadeIn>
					}
				>
					<EnvClustersList {...{ org, env, clusters: clustersReq.body }} />
				</FakeSuspense>
			</Card>
		</>
	);
};

const EnvClustersList = ({ org, env, clusters }) => {
	return (
		<List data-testid="cluster-list">
			{clusters.map(cluster => (
				<EnvClusterListItem
					key={`${cluster.id}-${cluster.isSelected}`}
					org={org}
					env={env}
					{...cluster}
				/>
			))}
		</List>
	);
};

export default EnvClusters;
