import React, { useCallback } from "react";

import {
	ListItem,
	ListItemSecondaryAction,
	Typography,
	ListItemText,
	ListItemIcon,
	Divider,
	Collapse
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { format, parseISO } from "date-fns";

import Duration from "../duration";
import { eventDescriptions } from "./event-descriptions";
import ResultIcon from "./icon";

const RunEvent = props => {
	const classes = useRowStyles();

	return (
		<div className={classes.root}>
			<RowSummary {...props} />
			<RowDetail {...props} />
		</div>
	);
};

const useRowStyles = makeStyles(theme => ({
	root: {
		transition: theme.transitions.create(["transform", "height"]),
		overflow: "hidden"
	}
}));

const useRowSummaryStyles = makeStyles(theme => {
	return {
		listItemText: {
			[theme.breakpoints.up("md")]: {
				display: "flex"
			}
		},
		listItemTextPrimary: {
			[theme.breakpoints.up("md")]: {
				width: "50%"
			}
		}
	};
});

const RowSummary = ({ selected, handleSelectItem, ...ev }) => {
	const onClick = useCallback(() => {
		if (ev.error) {
			handleSelectItem(ev.id);
		}
	}, [ev.error, ev.id, handleSelectItem]);

	const hasDescriptionComponent = !!eventDescriptions[ev.type];
	const Description = eventDescriptions[ev.type] || "div";

	const classes = useRowSummaryStyles();

	return (
		<ListItem
			selected={selected}
			button={!!ev.error}
			style={{ position: "relative" }}
			onClick={onClick}
			component="div"
			ContainerComponent="div"
		>
			<ListItemIcon title={ev.isSuccessful ? "Succeeded" : "Failed"}>
				<ResultIcon isSuccessful={ev.isSuccessful} />
			</ListItemIcon>
			<ListItemText
				className={classes.listItemText}
				classes={{ primary: classes.listItemTextPrimary }}
				primary={
					<>{hasDescriptionComponent ? <Description {...ev} /> : null}</>
				}
				secondary={
					ev.time ? (
						<time
							title={format(parseISO(ev.time), "M/d/yyyy HH:mm:ss'.'SSS")}
							dateTime={ev.time}
						>
							{format(parseISO(ev.time), "M/d/yyyy h:mm:ss a")}
						</time>
					) : null
				}
			/>
			{ev.duration ? (
				<ListItemSecondaryAction>
					<Typography variant="caption" color="textSecondary">
						<Duration ticks={ev.duration} />
					</Typography>
				</ListItemSecondaryAction>
			) : (
				<ListItemSecondaryAction />
			)}
		</ListItem>
	);
};

const RowDetail = ({ selected, isLast, error }) => (
	<>
		{error ? (
			<Collapse in={selected} unmountOnExit mountOnEnter>
				<Error {...error} />
			</Collapse>
		) : null}
		{selected || isLast ? null : <Divider variant="inset" />}
	</>
);

const Error = ({ message, trace }) => {
	const classes = useErrorStyles();

	return (
		<Typography className={classes.root} component="div">
			<strong>{message}</strong>
			<pre>{trace}</pre>
		</Typography>
	);
};

const useErrorStyles = makeStyles(theme => ({
	root: {
		overflowX: "auto",
		padding: theme.spacing(2, 4)
	}
}));

export default RunEvent;
