import React, { useCallback } from "react";

import { DialogActions, CardContent, Typography } from "@material-ui/core";
import { ArrowRightAlt as ArrowRightIcon } from "@material-ui/icons";

import { Button } from "@runly/gatsby-theme";

import SlugEditor from "../../slug/editor";

const NameStep = ({ state, onChangeId, returnUrl, navigate }) => {
	return (
		<form
			onSubmit={useCallback(
				ev => {
					ev.preventDefault();
					navigate("env");
				},
				[navigate]
			)}
		>
			<CardContent>
				<Typography variant="h5" component="h2">
					How should this cluster be identified?
				</Typography>
				<Typography paragraph>
					This will be the identifier used in the API for integrations that you
					may build. You can change it later if you need to.
				</Typography>

				<SlugEditor value={state.id} onChange={onChangeId} autoFocus />
			</CardContent>

			<DialogActions>
				<Button to={returnUrl}>Cancel</Button>
				<Button type="submit" variant="contained" color="primary">
					Assign Environments
					<ArrowRightIcon />
				</Button>
			</DialogActions>
		</form>
	);
};

export default NameStep;
