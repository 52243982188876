import React from "react";
import { Helmet } from "react-helmet";
import { useFetchEnvPackages } from "./api";
import { Button, Paper, Box } from "@material-ui/core";
import FakeSuspense from "../../../../fake-suspense";
import EnvPackage from "./item";
import { Link } from "@runly/gatsby-theme";
import { SkeletonList } from "../../../../skeleton/list";
import { FadeIn } from "../../../../skeleton/fade-in";

const EnvPackages = ({ org, env }) => {
	const pkgsRequest = useFetchEnvPackages({
		org,
		env
	});

	return (
		<>
			<Helmet>
				<title>Package</title>
			</Helmet>
			<FakeSuspense
				req={pkgsRequest}
				description={<>{env} packages</>}
				placeholder={
					<FadeIn>
						<Paper>
							<SkeletonList avatar={false} featuredCol cols={3} />
						</Paper>
					</FadeIn>
				}
				emptyAction={
					<Button
						size="large"
						color="primary"
						component={Link}
						underline="none"
						to={`/dashboard/${org}/pkg`}
					>
						Upload packages
					</Button>
				}
			>
				<Paper>
					<Box px={3} py={1}>
						{pkgsRequest.body?.map?.(pkg => (
							<EnvPackage key={pkg.packageId} {...{ org, env, pkg }} />
						))}
					</Box>
				</Paper>
			</FakeSuspense>
		</>
	);
};

export default EnvPackages;
