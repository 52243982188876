import React, { useMemo } from "react";
import { Typography, Box } from "@material-ui/core";

import { useFetchPackages } from "../../pkg/api";

import PkgList from "../../pkg/list";
import FakeSuspense from "../../fake-suspense";
import { SkeletonList } from "../../skeleton/list";

const UserPackages = ({ org, userId }) => {
	const pkgsReq = useFetchPackages(org);

	const userPkgs = useMemo(
		() => pkgsReq?.body?.filter(pkg => pkg.updatedBy === userId) || null,
		[pkgsReq, userId]
	);

	if (pkgsReq.isFetched && !userPkgs?.length) return null;

	return (
		<>
			<Box px={2}>
				<Typography variant="h5" component="h3">
					Packages
				</Typography>
			</Box>
			<FakeSuspense req={pkgsReq} placeholder={<SkeletonList cols={2} />}>
				<PkgList pkgs={userPkgs} org={org} />
			</FakeSuspense>
		</>
	);
};

export default UserPackages;
