import { useFetch, useLazyFetch } from "react-fetch-hooks";

import { useRunlyConfig } from "@runly/ui";

export const useFetchEnvPackages = ({ org, env }) => {
	const { url, token: bearerToken } = useRunlyConfig();

	const pkgsRequest = useFetch(
		url && bearerToken ? `${url}/${org}/environments/${env}/packages/` : null,
		{
			method: "GET",
			bearerToken
		}
	);

	return pkgsRequest;
};

export const useUpdateEnvPackage = ({ org, env, packageId, value }) => {
	const { url, token: bearerToken } = useRunlyConfig();

	const pkgPatchRequest = useLazyFetch(
		url && bearerToken
			? `${url}/${org}/environments/${env}/packages/${packageId}`
			: null,
		{
			method: "PATCH",
			body: JSON.stringify(value), // eg { versionRange: "1.x" }
			bearerToken
		}
	);

	return pkgPatchRequest;
};
