import { useFetch } from "react-fetch-hooks";
import { useRunlyConfig } from "@runly/ui";

export const useFetchPackage = ({ org, id }) => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useFetch(
		url && bearerToken && org && id
			? `${url}/${org}/packages/${id}/`
			: undefined,
		{
			method: "GET",
			bearerToken
		}
	);
};

export const useFetchVersions = ({ org, id }) => {
	const { url, token: bearerToken } = useRunlyConfig();

	return useFetch(
		url && bearerToken && org && id
			? `${url}/${org}/packages/${id}/versions`
			: undefined,
		{
			method: "GET",
			bearerToken
		}
	);
};
