import React from "react";
import { Box } from "@material-ui/core";
import { green, red, grey } from "@material-ui/core/colors";
import { useTheme } from "@material-ui/core/styles";
import { useCategory } from "./use-category";

export const CategoryBar = ({
	setActiveCategory,
	category,
	activeCategory,
	classes,
	count,
	total,
	isSuccessful
}) => {
	const theme = useTheme();
	const barGap = theme.spacing(0.25);
	const { onSelect } = useCategory({ setActiveCategory, category });
	return (
		<Box
			onMouseEnter={onSelect}
			className={
				activeCategory === category ? classes.activeItem : classes.baseItem
			}
			pt={0.25}
			style={{
				margin: `0 ${barGap}px`,
				width: `calc(100% * ${count}/${total} - ${barGap}px)`,
				backgroundColor: (isSuccessful === true
					? green
					: isSuccessful === false
					? red
					: grey)[activeCategory === category ? 300 : 500]
			}}
		/>
	);
};
