import React from "react";
import { Box, Typography } from "@material-ui/core";

const renderOption = ({ label, version, preview }) => {
	return (
		<Box display="flex" style={{ width: "100%" }}>
			{label || version}{" "}
			{preview ? (
				<Typography variant="caption" style={{ marginLeft: "auto" }}>
					Resolves to {preview}
				</Typography>
			) : null}
		</Box>
	);
};

export default renderOption;
