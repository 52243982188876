import React from "react";

import {
	Typography,
	DialogActions,
	CardContent,
	List,
	ListSubheader,
	ListItem,
	Checkbox,
	ListItemIcon,
	ListItemText
} from "@material-ui/core";

import { Button } from "@runly/gatsby-theme";

import FetchButton from "../../busy-button/fetch-button";
import FakeSuspense from "../../fake-suspense";

import { useFetchClusters } from "../../cluster/api";

import { EnvClustersTitle, EnvClustersDescription } from "../../blurbs";

import SlugList from "../../slug/list";

const ClustersStep = ({ org, state, onToggleCluster, envPatchReq, onSave }) => {
	const clustersReq = useFetchClusters({ org });

	return (
		<>
			<CardContent>
				<Typography variant="h5" component="h2">
					<EnvClustersTitle env={state.id} />
				</Typography>
				<Typography paragraph>
					<EnvClustersDescription env={state.id} />
				</Typography>

				<FakeSuspense description="clusters" req={clustersReq}>
					<List disablePadding>
						{clustersReq?.body ? <ListSubheader>Clusters</ListSubheader> : null}
						{clustersReq?.body?.map(c => (
							<ClusterToggle
								key={c.id}
								isSelected={state.clusters.includes(c.id)}
								{...{ onToggleCluster }}
								{...c}
							/>
						))}
					</List>
				</FakeSuspense>
			</CardContent>
			<DialogActions>
				<Button
					disabled={envPatchReq.isFetching}
					to={`/dashboard/${org}/env/new/access`}
				>
					Back
				</Button>
				<FetchButton
					disabled={!state.id?.trim()}
					variant="contained"
					color="primary"
					data-testid="save-btn"
					req={envPatchReq}
					busyChildren={<>Saving…</>}
					onClick={onSave}
				>
					Save new environment
				</FetchButton>
			</DialogActions>
		</>
	);
};

const ClusterToggle = ({ id, environments, isSelected, onToggleCluster }) => {
	const checkboxId = `cluster-toggle-checkbox-${id}`;

	return (
		<ListItem button key={id} component="label" htmlFor={checkboxId}>
			<ListItemIcon>
				<Checkbox
					id={checkboxId}
					checked={isSelected}
					onChange={onToggleCluster(id)}
				/>
			</ListItemIcon>
			<ListItemText
				primary={id}
				secondary={
					environments.length ? (
						<>
							Also hosting <SlugList items={environments.map(e => e.id)} />
						</>
					) : null
				}
			/>
		</ListItem>
	);
};

export default ClustersStep;
