import React from "react";
import { Link } from "@runly/gatsby-theme";
import { ListItem, ListItemSecondaryAction, Tooltip } from "@material-ui/core";
import { ListCol } from "../../../account/apps/app-item";
import UserActivity from "../../../user-profile/activity";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { BusyIconButton } from "../../../busy-button";
import cronstrue from "cronstrue";

const JobTemplateListItem = ({
	org,
	env,
	id,
	allowRestrictedApps,
	job,
	schedule,
	createdBy,
	createdAt,
	updatedBy,
	updatedAt,
	lastUsedBy,
	lastUsedAt,
	listItemProps,
	deleteButtonProps,
	isItemDeleting,
	style
}) => {
	return (
		<ListItem
			{...listItemProps}
			style={style}
			component={Link}
			button
			key={id}
			to={`/dashboard/${org}/env/${env}/templates/${id}`}
		>
			<ListCol
				primary={id}
				secondary={
					<>
						{allowRestrictedApps
							? "Restricted apps allowed"
							: "Restricted apps not allowed"}
						{schedule ? (
							<>
								<br />
								Runs {cronstrue.toString(schedule)}
							</>
						) : null}
					</>
				}
			/>
			<ListCol
				primary={job.type.split(".").join(".\u200B")}
				secondary={
					<>
						{job.package} {job.version ? `v${job.version}` : ""}
					</>
				}
			/>
			<ListCol
				primary={
					updatedAt !== createdAt ? (
						<UserActivity verb="updated" at={updatedAt} by={updatedBy} />
					) : (
						<UserActivity verb="created" at={createdAt} by={createdBy} />
					)
				}
				secondary={<UserActivity verb="used" at={lastUsedAt} by={lastUsedBy} />}
			/>
			<ListItemSecondaryAction>
				<Tooltip title="Delete template">
					<BusyIconButton
						isBusy={isItemDeleting}
						size="medium"
						{...deleteButtonProps}
					>
						<DeleteIcon />
					</BusyIconButton>
				</Tooltip>
			</ListItemSecondaryAction>
		</ListItem>
	);
};

export default JobTemplateListItem;
