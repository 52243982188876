import React, { useState, useCallback } from "react";
import { Helmet } from "react-helmet";

import {
	TextField,
	Card,
	CardHeader,
	CardContent,
	DialogActions
} from "@material-ui/core";

import { Button } from "@runly/gatsby-theme";

import { makeStyles } from "@material-ui/core/styles";

import Layout from "../layout";
import NavTabs from "../layout/nav-tabs";
import FakeSuspense from "../fake-suspense";
import FetchButton from "../busy-button/fetch-button";

import { useFetchOrgLimits, useFetchOrgUsage } from "../org/api";
import { useInviteMember } from "./api";

import RoleChooser from "./role/chooser";
import { Role as RoleBlurb } from "../blurbs";

import useMutationHandler from "../mutation-handler";
import RoleUpgradeCard from "./role/upgrade-card";
import UpgradeCard from "../billing/upgrade-card";

const NewMemberPage = ({ org, location }) => {
	const orgReq = useFetchOrgLimits(org);
	const usageReq = useFetchOrgUsage(org);

	return (
		<Layout
			org={org}
			location={location}
			navTabs={
				<NavTabs
					tabs={[
						{
							href: `/dashboard/${org}/settings/members`,
							isGoBack: true,
							label: "Members"
						}
					]}
					location={location}
				/>
			}
		>
			<Helmet>
				<title>Invite New Member</title>
			</Helmet>

			<FakeSuspense req={orgReq} description="Organization">
				<FakeSuspense req={usageReq} description="Usage">
					<NewMemberRoot org={org} limits={orgReq.body} usage={usageReq.body} />
				</FakeSuspense>
			</FakeSuspense>
		</Layout>
	);
};

const NewMemberRoot = ({
	org,
	limits,
	usage: {
		member: { limit, current }
	}
}) => {
	const saveReq = useInviteMember(org);
	const inviteMember = saveReq.fetch;
	const onInvite = useCallback(member => inviteMember(member), [inviteMember]);

	useMutationHandler({
		req: saveReq,
		successRedirectUrl: `/dashboard/${org}/settings/members`,
		successNotification: {
			messageId: "new-member",
			text: `Invited ${saveReq.body?.email} to ${org}`
		},
		errorNotification: { verb: "inviting", description: "new member" }
	});

	if (Number.isInteger(limit) && current >= limit) {
		return <UpgradeCard org={org} entity="member" limit={limit} />;
	}

	return (
		<InviteForm org={org} limits={limits} onInvite={onInvite} req={saveReq} />
	);
};

const InviteForm = ({ org, limits, req, onInvite }) => {
	const classes = useStyles();

	const [email, setEmail] = useState("");
	const [isEmailDirty, setIsEmailDirty] = useState(false);
	const [isEmailValid, setIsEmailValid] = useState(false);
	const checkEmailValidity = useCallback(e => {
		const validity = e?.target?.checkValidity?.();
		setIsEmailValid(validity);
	}, []);
	const handleEmailChange = useCallback(ev => {
		setEmail(ev.target.value);
	}, []);
	const handleEmailBlur = useCallback(() => {
		setIsEmailDirty(true);
	}, []);

	const [role, setRole] = useState(limits.roles[0]);
	const handleRoleChange = useCallback(role => setRole(role), []);

	const handleSubmit = useCallback(
		e => {
			e.preventDefault();
			setIsEmailDirty(true);

			onInvite({ email: email, role: role.role });
		},
		[email, onInvite, role.role]
	);

	const cancelButton = (
		<Button to={`/dashboard/${org}/settings/members`}>Don't invite</Button>
	);

	return (
		<form onSubmit={handleSubmit}>
			<Card>
				<CardHeader
					title={`Invite New Member`}
					subheader={
						<>
							Send an invitation to the {org} organization. New members will get
							an email with a link to accept the invitation.
						</>
					}
				/>

				<CardContent>
					<div className={classes.formRow}>
						<TextField
							label="Send invitation to"
							type="email"
							className={classes.formControl}
							value={email}
							onChange={handleEmailChange}
							onBlur={handleEmailBlur}
							required
							inputProps={{ onInput: checkEmailValidity }}
							error={isEmailDirty && !isEmailValid}
							helperText={
								isEmailDirty && !isEmailValid ? "Enter a valid email" : "\u00A0"
							}
						/>
					</div>

					<div className={classes.formRow}>
						<RoleChooser
							roles={limits.roles}
							value={role}
							onChange={handleRoleChange}
							helpText={<RoleBlurb />}
						/>
					</div>
				</CardContent>

				<DialogActions>
					{role.isAvailable ? (
						<>
							{cancelButton}

							<FetchButton
								variant="contained"
								color="primary"
								type="submit"
								busyChildren={<>Sending invite…</>}
								req={req}
							>
								Send invite
							</FetchButton>
						</>
					) : (
						<RoleUpgradeCard org={org} {...role}>
							{cancelButton}
						</RoleUpgradeCard>
					)}
				</DialogActions>
			</Card>
		</form>
	);
};

const useStyles = makeStyles(theme => ({
	formRow: {
		margin: theme.spacing(5, 0)
	}
}));

export default NewMemberPage;
