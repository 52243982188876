import React from "react";
import { Helmet } from "react-helmet";

import FakeSuspense from "../../fake-suspense";

import RunsRoot, { RunsPager } from "./root";
import useRuns from "./state";

import { parse } from "query-string";
import { startCase } from "lodash";
import { SkeletonList } from "../../skeleton/list";
import { Paper } from "@material-ui/core";
import { FadeIn } from "../../skeleton/fade-in";

const EnvRuns = ({ org, env, location }) => {
	const { runsHistoryRequest, cursor, runs, pager } = useRuns({
		org,
		query: { ...parse(location.search), environment: env }
	});

	return (
		<>
			<Helmet>
				<title>{`${startCase(env)} Run History`}</title>
			</Helmet>

			<FakeSuspense
				req={runsHistoryRequest}
				description="run history"
				placeholder={
					<FadeIn>
						<Paper>
							<RunsPager {...{ pager, location, runs }} />
							<SkeletonList size={20} cols={3} secondaryAction />
						</Paper>
					</FadeIn>
				}
			>
				<RunsRoot
					{...{ org, env, runs, pager, location }}
					{...cursor}
					hasMore={!!runsHistoryRequest?.body?.hasMore}
				/>
			</FakeSuspense>
		</>
	);
};

export default EnvRuns;
