import React, { useMemo } from "react";
import {
	Box,
	ButtonBase,
	Typography,
	Paper,
	List,
	ListItem,
	ListItemText
} from "@material-ui/core";
import { maxBy, sortBy, startCase } from "lodash";
import { parseISO } from "date-fns";
import DateTime from "../time";
import PaperTip from "../paper-tip";

const RunStatus = ({
	state,
	acquiredAt,
	assignedAt,
	completedAt,
	enqueuedAt,
	cancellationRequestedAt,
	runningAt,
	startedAt,
	TypographyProps = {}
}) => {
	const { latestTime, gotTimes } = useMemo(() => {
		const dateTimes = {
			acquiredAt,
			assignedAt,
			completedAt,
			enqueuedAt,
			cancellationRequestedAt,
			runningAt,
			startedAt
		};

		let gotTimes = [];

		Object.keys(dateTimes).forEach(k => {
			const value = dateTimes[k] ? parseISO(dateTimes[k]).getTime() : 0;
			gotTimes.push({
				name: k,
				iso: dateTimes[k],
				value
			});
		});

		gotTimes = sortBy(gotTimes, "value");

		const latestTime = maxBy(gotTimes, "value");

		return { latestTime, dateTimes, gotTimes };
	}, [
		acquiredAt,
		assignedAt,
		cancellationRequestedAt,
		completedAt,
		enqueuedAt,
		runningAt,
		startedAt
	]);

	const popup = (
		<Paper>
			<List disablePadding dense>
				{gotTimes.map(({ iso, name }) => {
					const selected = name === latestTime.name;
					return iso ? (
						<ListItem key={name} selected={selected}>
							<ListItemText
								primary={
									<Box display="flex">
										<div style={{ marginRight: 8 }}>
											{startCase(name).split(" ")[0]}
										</div>
										<div style={{ marginLeft: "auto" }}>
											<DateTime value={iso} />
										</div>
									</Box>
								}
							/>
						</ListItem>
					) : null;
				})}
			</List>
		</Paper>
	);
	// {startCase(latestTime.name).split(" ")[0]}{" "}
	// <DateTime value={dateTimes[latestTime.name]} />

	return (
		<>
			<PaperTip title={popup}>
				<ButtonBase focusRipple>
					<Typography {...TypographyProps}>{startCase(state)}</Typography>
				</ButtonBase>
			</PaperTip>
		</>
	);
};

export default RunStatus;
