import React from "react";
import {
	Card,
	CardContent,
	CardHeader,
	CardActions,
	Container
} from "@material-ui/core";

import { Button } from "@runly/gatsby-theme";

const UpgradeCard = ({ org, entity, limit }) => {
	return (
		<Container maxWidth="sm">
			<Card>
				<CardHeader title="Upgrade Required" />
				<CardContent>
					Your current plan only allows {limit} {entity}
					{limit != 1 ? "s" : ""}. You have reached this limit. You must upgrade
					your plan to continue.
				</CardContent>
				<CardActions>
					<Button
						variant="contained"
						color="primary"
						to={`/dashboard/${org}/settings/billing`}
					>
						Upgrade Now
					</Button>
				</CardActions>
			</Card>
		</Container>
	);
};

export default UpgradeCard;
