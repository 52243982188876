import { useCallback, useState } from "react";

export const useNewAppState = createAppReq => {
	const [name, setName] = useState("");
	const handleNameChange = useCallback(e => {
		setName(e.target.value);
	}, []);

	const [isEditing, setIsEditing] = useState(false);
	const toggleIsEditing = useCallback(() => {
		setIsEditing(v => !v);
	}, []);

	const handleSubmit = useCallback(
		e => {
			e?.preventDefault?.();
			createAppReq.fetch({ name });
		},
		[name, createAppReq]
	);

	const reset = useCallback(() => {
		setIsEditing(false);
		setName("");
	}, []);

	return {
		name,
		setName,
		handleNameChange,
		isEditing,
		setIsEditing,
		toggleIsEditing,
		handleSubmit,
		reset
	};
};
