import { makeStyles } from "@material-ui/core/styles";

const baseItem = theme => ({
	display: "block",
	transition: theme.transitions.create(["width", "transform", "box-shadow"])
});
const activeItem = theme => ({
	...baseItem(theme)
});
export const useStyles = makeStyles(theme => {
	return {
		barContainer: {},
		baseItem: baseItem(theme),
		activeItem: activeItem(theme)
	};
});
