import React from "react";

import { CircularProgress } from "@material-ui/core";
import { Check as CheckIcon, Error as ErrorIcon } from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

const useIconStyles = makeStyles(theme => ({
	success: {
		color: green[500]
	},
	fail: {
		color: theme.palette.error.main
	}
}));

const ResultIcon = ({ isSuccessful, ...props }) => {
	const classes = useIconStyles();

	const WhichIcon =
		isSuccessful === true
			? CheckIcon
			: isSuccessful === false
			? ErrorIcon
			: CircularProgress;

	const whichCx =
		isSuccessful === true
			? classes.success
			: isSuccessful === false
			? classes.fail
			: classes.notProcessed;

	return (
		<WhichIcon
			style={{ width: 24, height: 24 }}
			className={whichCx}
			{...props}
		/>
	);
};

export default ResultIcon;
