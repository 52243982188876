import React from "react";

import {
	Card,
	CardHeader,
	Grid,
	IconButton,
	Typography,
	Box
} from "@material-ui/core";
import { Settings as SettingsIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import SocketIndicator from "../../socket-indicator";

import useEnvironmentConnection from "../hub";

import QueueProcess from "./queue-process";
import ActiveRuns from "../../run/active";
import Clusters from "../../cluster/list";

import { Helmet } from "react-helmet";
import { startCase } from "lodash";
import RefableLink from "../../refable-link";

import { useCanEditEnv, useCanQueue } from "../../org/permissions";
import { SkeletonList } from "../../skeleton/list";
import { FadeIn } from "../../skeleton/fade-in";

const EnvOverview = ({ org, env, location, environmentReq, ...props }) => {
	const environment = environmentReq?.body;
	const description = environment?.description;

	const { runs, clusters, ...connection } = useEnvironmentConnection({
		org,
		env
	});

	return (
		<>
			<Helmet>
				<title>{`${startCase(env)} overview`}</title>
			</Helmet>

			<Box mb={3}>
				<Typography color={description ? "textPrimary" : "textSecondary"}>
					{environmentReq.isFetching ? (
						"\u00A0"
					) : (
						<>{description || "No description"}</>
					)}
				</Typography>
			</Box>

			<SocketIndicator
				connection={connection}
				body={runs && clusters}
				description="runs & clusters"
				placeholder={
					<EnvOverviewPlaceholder
						{...{
							org,
							env,
							environment,
							location
						}}
						{...props}
					/>
				}
			>
				<EnvOverviewRoot
					{...{ org, env, environment, runs, clusters, location }}
					{...props}
				/>
			</SocketIndicator>
		</>
	);
};

const EnvOverviewPlaceholder = ({
	org,
	env,
	environment,
	location,
	dropzoneProps
}) => {
	return (
		<FadeIn>
			<Grid container spacing={3}>
				<EnvOverviewCard>
					<ActiveRunsHeader
						{...{ org, env, environment, location, dropzoneProps }}
					/>
					<SkeletonList cols={3} secondaryAction />
				</EnvOverviewCard>
				<EnvOverviewCard>
					<ClustersHeader {...{ org, env }} />
					<SkeletonList cols={2} secondaryAction />
				</EnvOverviewCard>
			</Grid>
		</FadeIn>
	);
};

const EnvOverviewRoot = ({
	org,
	env,
	environment,
	runs,
	clusters,
	dropzoneProps,
	location
}) => {
	return (
		<Grid container spacing={3}>
			<EnvOverviewCard>
				<ActiveRunsHeader
					{...{ org, env, environment, location, dropzoneProps }}
				/>
				<ActiveRuns {...{ org, env, runs, location }} />
			</EnvOverviewCard>
			<EnvOverviewCard>
				<ClustersHeader {...{ org, env }} />
				<Clusters
					{...{ org, env, clusters }}
					ListItemProps={{
						state: { clusterReturn: { url: location.pathname, label: env } }
					}}
				/>
			</EnvOverviewCard>
		</Grid>
	);
};

const EnvOverviewCard = ({ children }) => {
	const classes = useStyles();

	return (
		<Grid item xs={12} md={6} className={classes.sameHeightGrid}>
			<Card className={classes.sameHeightCard}>{children}</Card>
		</Grid>
	);
};

const useStyles = makeStyles({
	sameHeightGrid: {
		display: "flex"
	},
	sameHeightCard: {
		width: "100%"
	}
});

export default EnvOverview;
const ActiveRunsHeader = ({
	org,
	env,
	environment,
	location,
	dropzoneProps
}) => {
	const canQueue = useCanQueue(org, environment?.isProduction);

	return (
		<CardHeader
			title="Active Runs"
			action={
				canQueue ? (
					<QueueProcess
						{...{
							org,
							env,
							location,
							dropzoneProps
						}}
					/>
				) : null
			}
		/>
	);
};
const ClustersHeader = ({ org, env }) => {
	const canEditEnv = useCanEditEnv(org);
	return (
		<CardHeader
			title={<>Clusters</>}
			action={
				canEditEnv ? (
					<IconButton
						aria-label="Settings"
						component={RefableLink}
						to={`/dashboard/${org}/env/${env}/settings/clusters`}
						title="Configure Clusters"
					>
						<SettingsIcon />
					</IconButton>
				) : null
			}
		/>
	);
};
