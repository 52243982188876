import React, { useState, useCallback } from "react";
import { Helmet } from "react-helmet";

import { useFetch } from "react-fetch-hooks";
import { StripeProvider, Elements } from "react-stripe-elements";

import FakeSuspense from "../../fake-suspense";

const JS_SCRIPT_URL = "https://js.stripe.com/v3/";

const useFetchApiInfo = () =>
	useFetch(`${process.env.API_URL}`, { method: "GET" });

const Provider = props => {
	const req = useFetchApiInfo();

	return (
		<FakeSuspense req={req} description="Billing Info">
			<ProviderWithApiKey apiKey={req.body?.stripeApiKey} {...props} />
		</FakeSuspense>
	);
};

const ProviderWithApiKey = ({ apiKey, children }) => {
	const [stripe, setStripe] = useState(() =>
		window.Stripe ? window.Stripe(apiKey) : null
	);

	// https://github.com/nfl/react-helmet/issues/146#issuecomment-513793628
	const handleChangeClientState = useCallback(
		(newState, addedTags) => {
			if (!window.Stripe) {
				if (addedTags?.scriptTags) {
					const foundScript = addedTags.scriptTags.find(
						({ src }) => src === JS_SCRIPT_URL
					);

					if (foundScript) {
						foundScript.addEventListener(
							"load",
							() => {
								setStripe(window.Stripe(apiKey));
							},
							{ once: true }
						);
					}
				}
			}
		},
		[apiKey]
	);

	return (
		<>
			<Helmet onChangeClientState={handleChangeClientState}>
				<script src="https://js.stripe.com/v3/" async defer></script>
			</Helmet>
			<StripeProvider stripe={stripe}>
				<Elements>{children}</Elements>
			</StripeProvider>
		</>
	);
};

export default Provider;
