import { useEffect, useReducer, useMemo, useRef } from "react";
import { orderBy, merge } from "lodash";

import { useChannel, useRunlyConfig } from "@runly/ui";

const COMPLETE_TIMEOUT = 30000;

const reducer = (state, { type, payload }) => {
	switch (type) {
		case "all_runs": {
			const runs = {};

			payload.forEach(run => {
				runs[run.id] = run;
			});

			return { ...state, runs };
		}

		case "run_status": {
			const existingRun = state.runs[payload.id];
			const newRun = merge({}, existingRun, payload);

			const runs = {
				...state.runs,
				[newRun.id]: newRun
			};

			return { ...state, runs };
		}

		case "clear_run": {
			const runs = { ...state.runs };
			delete runs[payload];

			return { ...state, runs };
		}

		case "all_clusters": {
			const clusters = {};

			payload.forEach(cluster => {
				clusters[cluster.id] = cluster;
			});

			return { ...state, clusters };
		}

		case "cluster_status": {
			const clusters = {
				...state.clusters,
				[payload.id]: payload
			};

			return { ...state, clusters };
		}

		default:
			return state;
	}
};

const useEnvironmentConnection = ({ org, env }) => {
	const { url, token } = useRunlyConfig();

	const { connection, ...connectionState } = useChannel(
		`${url}/${org}/environments/${env}/channel`,
		token
	);

	const timers = useRef({});

	const [{ runs, clusters }, dispatch] = useReducer(reducer, {});

	useEffect(
		() => () => {
			// clean up timers when component disposed
			for (let runId in timers.current) {
				clearTimeout(timers.current[runId]);
			}
		},
		[]
	);

	useEffect(() => {
		if (connection) {
			connection.on("Runs", activeRuns => {
				dispatch({ type: "all_runs", payload: activeRuns });
			});

			connection.on("RunStatus", run => {
				dispatch({ type: "run_status", payload: run });

				if (run.completedAt && !timers.current[run.id]) {
					// clear the completed run after configured timeout
					timers.current[run.id] = setTimeout(() => {
						dispatch({ type: "clear_run", payload: run.id });
						delete timers.current[run.id];
					}, COMPLETE_TIMEOUT);
				}
			});

			connection.on("Clusters", nodes => {
				dispatch({ type: "all_clusters", payload: nodes });
			});

			connection.on("ClusterStatus", node => {
				dispatch({ type: "cluster_status", payload: node });
			});
		}
	}, [connection]);

	const sortedRuns = useMemo(() => {
		if (!runs) return runs;

		let sortedRuns = [];
		for (let runId in runs) {
			sortedRuns.push(runs[runId]);
		}

		sortedRuns = orderBy(sortedRuns, ["enqueuedAt"], ["desc"]);

		return sortedRuns;
	}, [runs]);

	const sortedClusters = useMemo(() => {
		if (!clusters) return clusters;

		let sortedClusters = [];
		for (let clusterId in clusters) {
			sortedClusters.push(clusters[clusterId]);
		}

		sortedClusters = orderBy(sortedClusters, ["statusLastChangedAt"], ["desc"]);

		return sortedClusters;
	}, [clusters]);

	return { runs: sortedRuns, clusters: sortedClusters, ...connectionState };
};

export default useEnvironmentConnection;
