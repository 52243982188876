import { useCallback } from "react";

export const useCategory = ({
	setActiveCategory = Function.prototype,
	category
}) => {
	const onSelect = useCallback(() => {
		setActiveCategory(category);
	}, [category, setActiveCategory]);
	const onDeselect = useCallback(() => {
		setActiveCategory(null);
	}, [setActiveCategory]);
	return { onSelect, onDeselect };
};
