import React, { useCallback } from "react";
import { Router, navigate } from "@reach/router";

import Layout from "./layout";

import PageHeader, { HeaderIcon } from "../../page-header";

import EnvIcon from "../icon";

import EnvOverview from "./overview";
import EnvSettings from "./settings";
import RunsHistory from "../../run/history";
import RunDetail from "../../run/detail";

import useEnvState from "./state";
import { useDropzone } from "react-dropzone";
import QueueProcessDialog from "./queue-process/dialog";
import useConfigEditorState from "./queue-process/state";
import FakeSuspense from "../../fake-suspense";
import { Skeleton } from "@material-ui/lab";
import JobTemplates from "../job-templates";

const EnvironmentDetails = ({ org, env }) => {
	const {
		configEditor,
		dropzoneProps,
		rootProps
	} = useConfigEditorWithDropzone({ org, env });

	return (
		<div {...rootProps}>
			<Router>
				<Layout path="/">
					<EnvironmentDetailsRoot {...{ dropzoneProps }} path="/*" />
				</Layout>

				<QueueProcessDialog path="runs/new" {...{ configEditor }} />
				<RunDetail path="runs/:runId/*" configEditor={configEditor} />
			</Router>
		</div>
	);
};

const EnvironmentDetailsRoot = ({ org, env, dropzoneProps }) => {
	const envState = useEnvState(org, env);

	return (
		<>
			<PageHeader>
				<HeaderIcon>
					<FakeSuspense
						req={envState.fetchReq}
						placeholder={<Skeleton variant="circle" width="1em" height="1em" />}
					>
						<EnvIcon isProduction={envState.fetchReq.body?.isProduction} />
					</FakeSuspense>
				</HeaderIcon>{" "}
				{env}
			</PageHeader>

			<Router>
				<EnvOverview
					{...{ dropzoneProps }}
					environmentReq={envState.fetchReq}
					path="/"
				/>

				<RunsHistory path="runs" />

				<JobTemplates path="templates/*" />

				<EnvSettings path="settings/*" envState={envState} />
			</Router>
		</>
	);
};

export const useConfigEditorWithDropzone = ({ org, env }) => {
	const configEditor = useConfigEditorState({ org, env });
	const handleDrop = useCallback(
		files => {
			const reader = new FileReader();
			reader.onload = () => {
				configEditor.setValue(reader.result);
				configEditor.setIsDirty(true);
				navigate(`/dashboard/${org}/env/${env}/runs/new`);
			};
			files.forEach(file => {
				reader.readAsText(file);
			});
		},
		[configEditor, env, org]
	);

	const { getRootProps, ...dropzoneRest } = useDropzone({
		onDrop: handleDrop,
		multiple: false
	});

	const { onClick, ...rootProps } = getRootProps();

	const dropzoneProps = { onClick, ...dropzoneRest };

	return { configEditor, dropzoneProps, rootProps };
};

export default EnvironmentDetails;
