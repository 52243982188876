import React, { useCallback, useMemo } from "react";
import { navigate } from "@reach/router";

import { Grid, Box } from "@material-ui/core";
import { Button } from "@runly/gatsby-theme";

import URI from "urijs";

import ProcessFilter from "./process";
import DispositionFilter from "./disposition";
import TriggerFilter from "./trigger";
import { CURS } from "../state/cursor";

const RunsFilter = ({ org, location }) => {
	const uri = useMemo(() => URI(`${location.pathname}${location.search}`), [
		location.pathname,
		location.search
	]);

	const hasFilters =
		!!location.search &&
		Object.keys(uri.search(true)).some(
			k => k !== CURS.BEFORE && k !== CURS.AFTER
		);

	const queryParams = useMemo(() => uri.search(true), [uri]);

	const onValueChange = useCallback(
		(key, value) => {
			if (value) {
				uri.setSearch(key, value);
			} else {
				uri.removeSearch(key);
			}
			uri.removeSearch([CURS.BEFORE, CURS.AFTER]);
			navigate(uri.toString());
		},
		[uri]
	);

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12} md={6}>
					<ProcessFilter
						org={org}
						queryParams={queryParams}
						onValueChange={onValueChange}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<TriggerFilter
						org={org}
						queryParams={queryParams}
						onValueChange={onValueChange}
					/>
				</Grid>
				<Grid item xs={12} md={2}>
					<DispositionFilter
						queryParams={queryParams}
						onValueChange={onValueChange}
					/>
				</Grid>
			</Grid>
			<Box>
				{hasFilters ? (
					<Button to={location.pathname}>Reset Filters</Button>
				) : (
					<Box p={0.75}>{"\u00A0"}</Box>
				)}
			</Box>
		</>
	);
};

export default RunsFilter;
