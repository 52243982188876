import { useFetchBillingInfo } from "./api";

import usePaymentMethodEditorState from "./payment-method/state";

const useBillingState = org => {
	const {
		billing: loadedBilling,
		isFetching,
		isFetched,
		error: loadError
	} = useFetchBillingInfo(org);

	const { savedBilling, ...editorState } = usePaymentMethodEditorState(org);

	return {
		isFetching,
		isFetched,
		loadError,

		...editorState,
		billing: savedBilling || loadedBilling
	};
};

export default useBillingState;
