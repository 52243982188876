import React from "react";
import { Box, Paper } from "@material-ui/core";

import { useFetchUserProfile } from "../api";

import Layout from "../../layout";
import NavTabs from "../../layout/nav-tabs";

import UserDetailsRoot from "./root";
import UserPackages from "./packages";
import UserEnvRuns from "./runs";
import FakeSuspense from "../../fake-suspense";
import { SkeletonCardHeader } from "../../skeleton/card";
import { SkeletonListItem } from "../../skeleton/list";

const UserDetails = ({ org, userId, location }) => {
	const userProfileReq = useFetchUserProfile(userId);

	const isSystem = userId.toLowerCase() === "system";

	return (
		<Layout
			org={org}
			location={location}
			navTabs={
				<NavTabs
					tabs={[
						{
							href: location?.state?.returnUrl
								? location.state.returnUrl
								: `/dashboard/${org}/settings/members`,
							isGoBack: true,
							label: extractLabel(
								location?.state?.returnLabel,
								location?.state?.returnUrl
							),
							linkState: location?.state?.returnState
						}
					]}
					location={location}
				/>
			}
		>
			<Paper>
				<FakeSuspense
					req={userProfileReq}
					description="User profile"
					placeholder={
						<>
							<SkeletonCardHeader avatar />
							<SkeletonListItem cols={3} />
						</>
					}
				>
					<UserDetailsRoot {...{ org, isSystem }} {...userProfileReq} />
				</FakeSuspense>
				<Box py={2}>
					<UserPackages {...{ org, userId }} />
					<UserEnvRuns {...{ org, userId }} />
				</Box>
			</Paper>
		</Layout>
	);
};

function extractLabel(lbl, url) {
	if (!lbl && url) return "Back";
	if (!lbl && !url) return "Members";

	if (lbl.id && lbl.label) {
		return (
			<>
				{lbl.id} {lbl.label}
			</>
		);
	}

	return lbl;
}

export default UserDetails;
