import React, { useCallback } from "react";
import { Helmet } from "react-helmet";

import { Typography } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";

import DangerConfirm, {
	useDangerConfirmState,
	useDangerButtonStyles
} from "../../danger/confirm";
import DangerZone from "../../danger/zone";
import DangerItem from "../../danger/item";

import FetchButton from "../../busy-button/fetch-button";
import useMutationHandler from "../../mutation-handler";

import { useDeleteCluster } from "../api";

const ClusterSettings = ({ org, cluster, location }) => {
	const { fetch: onDelete, ...deleteReq } = useDeleteCluster(org, cluster);

	useMutationHandler({
		req: deleteReq,
		successRedirectUrl: location.state?.clusterReturn?.url
			? location.state?.clusterReturn?.url
			: `/dashboard/${org}`,
		successNotification: {
			severity: "info",
			text: `Deleted ${cluster}.`
		},
		errorNotification: { verb: "deleting", description: "cluster" }
	});

	const deleteClusterDialog = useDangerConfirmState({
		magicWord: cluster,
		onConfirm: useCallback(() => onDelete(), [onDelete])
	});

	const dangerButtonClasses = useDangerButtonStyles({ isAction: true });

	return (
		<>
			<Helmet>
				<title>{`${cluster} Cluster Settings`}</title>
			</Helmet>

			<DangerZone>
				<DangerItem
					title="Delete Cluster"
					action={
						<FetchButton
							req={deleteReq}
							classes={dangerButtonClasses}
							variant="outlined"
							startIcon={<DeleteIcon />}
							{...deleteClusterDialog.buttonProps}
							busyChildren={<>Deleting</>}
						>
							Delete cluster
						</FetchButton>
					}
				>
					Permanently delete this cluster condemning it to the digital dustbin
					of history. This cannot be undone.
				</DangerItem>
			</DangerZone>

			<DangerConfirm
				{...deleteClusterDialog}
				title="Confirm Delete Cluster"
				cancelText="Don't delete"
				actionText="Delete cluster"
				preamble={
					<>
						<Typography paragraph>
							This action <strong>cannot</strong> be undone. This will
							permanently delete the <strong>{cluster}</strong> cluster along
							with all associated runs (pending or complete) and history of node
							activity. Any nodes connected to this cluster{" "}
							<strong>will be disconnected</strong> and not be able to run jobs
							anymore.
						</Typography>
						<Typography paragraph>
							Any environments that are configured to use only this cluster will{" "}
							<strong>no longer be able to run any jobs</strong>.
						</Typography>
						<Typography paragraph>
							This will not change your billing plan. If you want to downgrade
							after deleting this cluster, you must do so in your Billing
							Settings.
						</Typography>
					</>
				}
			/>
		</>
	);
};

export default ClusterSettings;
