import React, { useCallback } from "react";
import { Collapse } from "@material-ui/core";

const useListItemProps = ({ isLastUpdated, isItemDeleting }) => {
	return { selected: isLastUpdated, disabled: isItemDeleting };
};

const useDeleteButtonProps = ({
	handleSelectDeleteItem,
	id,
	isItemDeleting
}) => {
	const onClick = useCallback(() => {
		handleSelectDeleteItem(id);
	}, [handleSelectDeleteItem, id]);

	return {
		onClick,
		disabled: isItemDeleting
	};
};

const RemoteCollectionItemWrapper = ({
	item,
	collection,
	ItemComponent,
	itemProps
}) => {
	const isItemDeleting =
		collection.requests.deleteItem.isFetching &&
		collection.selections.deleteItem === item.id;

	const listItemProps = useListItemProps({ ...item, isItemDeleting });
	const deleteButtonProps = useDeleteButtonProps({
		isItemDeleting,
		...collection.handlers.deleteItem,
		id: item.id
	});

	const onExited = useCallback(() => {
		collection.handlers.deleteItem.handleExited(item.id);
	}, [collection.handlers.deleteItem, item.id]);
	return (
		<Collapse in={!item.remoteDeleted} onExited={onExited}>
			<ItemComponent
				{...itemProps}
				{...item}
				collection={collection}
				listItemProps={listItemProps}
				deleteButtonProps={deleteButtonProps}
				isItemDeleting={isItemDeleting}
			/>
		</Collapse>
	);
};

export default RemoteCollectionItemWrapper;
