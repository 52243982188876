import React from "react";
import { Router } from "@reach/router";

import { Helmet } from "react-helmet";
import { last } from "lodash";

import { useCancelRun } from "./api";
import { useRunConnection } from "@runly/ui";

import Layout from "../../layout";
import NavTabs from "../../layout/nav-tabs";

import { Box, Grid } from "@material-ui/core";
import {
	Timeline as TimelineIcon,
	Assignment as AssignmentIcon,
	DesktopWindows as DesktopWindowsIcon
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import { Link } from "@runly/gatsby-theme";

import DeepNav from "../../layout/deep-nav";
import SocketIndicator from "../../socket-indicator";

import RunHeader from "./header";
import RunOverview from "./overview";
import RunLog from "./log";
import RunResults from "./results";
import KeyboardHelp from "../keyboard-help";

import QueueProcessDialog from "../../env/details/queue-process/dialog";
import useConfigEditorState from "../../env/details/queue-process/state";

const RunDetail = ({ org, env, runId }) => {
	const canceler = useCancelRun({ org, env, runId });

	const configEditor = useConfigEditorState({ org, env, runId });

	return (
		<Router>
			<QueueProcessDialog path="requeue" {...{ configEditor }} />
			<RunLayout path="/*" {...{ configEditor, canceler }} />
		</Router>
	);
};

const RunLayout = ({ org, env, runId, location, ...props }) => {
	const { run, connection, ...connectionState } = useRunConnection(org, runId);

	const detailRoot = (
		<RunDetailRoot
			org={org}
			env={env}
			run={run}
			connection={connection}
			location={location}
			{...props}
		/>
	);

	return (
		<Layout
			path="*/"
			org={org}
			location={location}
			navTabs={
				<NavTabs
					tabs={[
						{
							href: location.state?.searchUrl
								? location.state.searchUrl
								: run?.completedAt
								? `/dashboard/${org}/env/${env}/runs`
								: `/dashboard/${org}/env/${env}`,
							isGoBack: true,
							label: "Runs"
						}
					]}
					location={location}
				/>
			}
			breadCrumb={
				<Link to={`/dashboard/${org}/env/${env}`} color="inherit">
					{env}
				</Link>
			}
		>
			<Helmet titleTemplate={`%s | ${run?.job?.name || "Run"} | Runly`} />

			<SocketIndicator
				connection={connectionState}
				body={run}
				description="run"
				placeholder={detailRoot}
			>
				{detailRoot}
			</SocketIndicator>
		</Layout>
	);
};

const RunDetailRoot = ({ org, env, location, canceler, run, connection }) => {
	const classes = useStyles();

	return (
		<>
			<RunHeader {...{ org, env, location, canceler, run }} />
			<Box>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={2}>
						{run ? (
							<DeepNav
								location={location}
								tabs={[
									{
										href: `/dashboard/${org}/env/${env}/runs/${run.id}`,
										label: (
											<div className={classes.navContainer}>
												<TimelineIcon /> Timeline
											</div>
										)
									},
									{
										href: `/dashboard/${org}/env/${env}/runs/${run.id}/results`,
										label: (
											<div className={classes.navContainer}>
												<AssignmentIcon /> Results
											</div>
										)
									},
									{
										href: `/dashboard/${org}/env/${env}/runs/${run.id}/logs`,
										label: (
											<div className={classes.navContainer}>
												<DesktopWindowsIcon /> Terminal
											</div>
										)
									}
								]}
							/>
						) : null}
					</Grid>
					<Grid item xs={12} sm={10}>
						<Router>
							<RunOverview path="/" {...{ run, connection }} />
							<RunLog path="logs" {...{ org, env, run, connection }} />
							<RunResults path="results" {...{ org, env, run }} />
						</Router>
					</Grid>
				</Grid>

				<RunDetailKeyboardShortcuts {...{ org, env, run, location }} />
			</Box>
		</>
	);
};

const useStyles = makeStyles(theme => ({
	navContainer: {
		display: "flex",
		alignItems: "center",

		"& svg": {
			marginRight: theme.spacing(1)
		}
	}
}));

const RunDetailKeyboardShortcuts = ({ org, env, run, location }) => {
	const tab = last(location.pathname.split("/"));
	const tabPath = tab && run && tab !== run.id ? `/${tab}` : "";
	const runIds = location?.state?.runIds || [];
	const referrerSearch = location?.state?.referrerSearch;
	const nextRunId = runIds[runIds.findIndex(r => r === run.id) + 1];
	const nextRunLink = `/dashboard/${org}/env/${env}/runs/${nextRunId}${tabPath}`;
	const prevRunId = runIds[runIds.findIndex(r => r === run.id) - 1];
	const prevRunLink = `/dashboard/${org}/env/${env}/runs/${prevRunId}${tabPath}`;

	return (
		<KeyboardHelp
			shortcuts={[
				{
					shortcut: "j",
					description: "Go to next run",
					navigateTo: nextRunLink,
					navigateOptions: {
						state: { run: null, runIds, referrerSearch, lastViewedRun: run }
					},
					enabled: !!nextRunId
				},
				{
					shortcut: "k",
					description: "Go to previous run",
					navigateTo: prevRunLink,
					navigateOptions: {
						state: { run: null, runIds, referrerSearch, lastViewedRun: run }
					},
					enabled: !!prevRunId
				}
			]}
		/>
	);
};

export default RunDetail;
