import React from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import FetchButton from "../../../../../../busy-button/fetch-button";

const createRenderInput = ({
	invalidRange,
	semverLink,
	isFocusDirty,
	displayedPkg,
	isValueDirty,
	updatePkgReq
}) => {
	let EnvPkgVersionAutoCompleteInput = params => (
		<TextField
			margin="dense"
			{...params}
			error={invalidRange}
			variant="outlined"
			fullWidth
			label={<>Version or range</>}
			helperText={
				invalidRange ? (
					<>Not a valid {semverLink} range</>
				) : isFocusDirty ? (
					<>Ranges are specified in {semverLink}.</>
				) : (
					<span> </span>
				)
			}
			placeholder={displayedPkg.versionRange}
			InputProps={getInputProps({
				params,
				isValueDirty,
				invalidRange,
				updatePkgReq
			})}
		/>
	);

	return EnvPkgVersionAutoCompleteInput;
};

export const getInputProps = ({
	params,
	isValueDirty,
	invalidRange,
	updatePkgReq
}) => {
	return {
		...params.InputProps,
		// .MuiAutocomplete-inputRoot adds 62px of right padding for some reason
		style: { paddingRight: 16 },
		endAdornment: isValueDirty ? (
			<InputAdornment>
				<FetchButton
					color="primary"
					style={{ marginRight: -12 }}
					disabled={invalidRange}
					type="submit"
					req={updatePkgReq}
				>
					Save
				</FetchButton>
			</InputAdornment>
		) : null
	};
};

export default createRenderInput;
