import React from "react";

import {
	Typography,
	DialogActions,
	CardContent,
	List,
	ListSubheader,
	ListItem,
	ListItemIcon,
	ListItemText,
	Checkbox
} from "@material-ui/core";

import { Button } from "@runly/gatsby-theme";

import FetchButton from "../../busy-button/fetch-button";
import FakeSuspense from "../../fake-suspense";

import { useFetchEnvironments } from "../../env/api";

import { ClustersEnvTitle, ClustersEnvDescription } from "../../blurbs";

import SlugList from "../../slug/list";

const EnvStep = ({ org, state, onToggleEnv, clusterSaveReq, onSave }) => {
	const envReq = useFetchEnvironments(org);

	return (
		<>
			<CardContent>
				<Typography variant="h5" component="h2">
					<ClustersEnvTitle cluster={state.id} />
				</Typography>
				<Typography paragraph>
					<ClustersEnvDescription cluster={state.id} />
				</Typography>

				<FakeSuspense description="clusters" req={envReq}>
					<List disablePadding>
						{envReq?.body ? (
							<ListSubheader inset>Environments</ListSubheader>
						) : null}
						{envReq?.body?.map(e => (
							<EnvToggle
								key={e.id}
								onToggleEnv={onToggleEnv}
								isSelected={state.environments.includes(e.id)}
								{...e}
							/>
						))}
					</List>
				</FakeSuspense>
			</CardContent>

			<DialogActions>
				<Button
					disabled={clusterSaveReq.isFetching}
					to={`/dashboard/${org}/clusters/new`}
				>
					Back
				</Button>
				<FetchButton
					variant="contained"
					color="primary"
					req={clusterSaveReq}
					busyChildren={<>Saving…</>}
					onClick={onSave}
				>
					Save new cluster
				</FetchButton>
			</DialogActions>
		</>
	);
};

const EnvToggle = ({ id, clusters, isSelected, onToggleEnv }) => {
	const checkboxId = `env-toggle-checkbox-${id}`;
	return (
		<ListItem
			button
			key={id}
			component="label"
			htmlFor={checkboxId}
			tabIndex={-1}
		>
			<ListItemIcon>
				<Checkbox
					edge="start"
					id={checkboxId}
					checked={isSelected}
					onChange={onToggleEnv(id)}
					disableRipple
				/>
			</ListItemIcon>
			<ListItemText
				primary={id}
				secondary={
					clusters.length ? (
						<>
							Also on <SlugList items={clusters} />
						</>
					) : null
				}
			/>
		</ListItem>
	);
};

export default EnvStep;
