import { useFetch } from "react-fetch-hooks";
import { useRunlyConfig } from "@runly/ui";

import { stringify } from "query-string";
import { isEmpty } from "lodash";

export const useFetchRunHistory = ({ org, query }) => {
	const { url, token: bearerToken } = useRunlyConfig();

	const hasQuery = !isEmpty(query);

	return useFetch(
		url && bearerToken
			? `${url}/${org}/runs${hasQuery ? `?${stringify(query)}` : ""}`
			: null,
		{
			method: "GET",
			bearerToken
		}
	);
};
