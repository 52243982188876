import React from "react";

import { Grid, Card, CardHeader, Avatar } from "@material-ui/core";

import SettingsItem from "../settings-property";
import DateTime from "../time";

const AccountSummary = ({ user, orgs }) => {
	return (
		<Card>
			<CardHeader
				title={user.name}
				subheader={user.email}
				avatar={<Avatar alt={user.nickname} src={user.picture} />}
			/>
			<Grid container>
				<Grid item md={6}>
					<SettingsItem title="Joined">
						<DateTime value={user.joinedAt} />
					</SettingsItem>
				</Grid>
				<Grid item md={6}>
					<SettingsItem title="Organizations">
						Member of {orgs.length} organization
						{orgs.length != 1 ? "s" : null}
					</SettingsItem>
				</Grid>
			</Grid>
		</Card>
	);
};

export default AccountSummary;
