import React, { useState, useEffect, useCallback } from "react";
import { Router } from "@reach/router";
import StripeProvider from "../../billing/stripe";

import BillingSummary from "../../billing/summary";
import Checkout from "../../billing/checkout";
import PaymentHistory from "../../billing/payments";

import Notification from "../../notification";

const BillingRoot = () => {
	const [newPlan, setNewPlan] = useState();
	const [displayedPlan, setDisplayedPlan] = useState(newPlan);
	useEffect(() => {
		if (newPlan) {
			setDisplayedPlan(newPlan);
		}
	}, [setDisplayedPlan, newPlan]);
	const onClose = useCallback(() => setNewPlan(false), []);
	const onExited = useCallback(() => setDisplayedPlan(null), []);

	const onCheckout = useCallback(({ planName }) => setNewPlan(planName), []);

	return (
		<>
			<Notification
				title="Success"
				isOpen={!!newPlan}
				handleClose={onClose}
				onExited={onExited}
			>
				Changed plan to {displayedPlan}.
			</Notification>

			<StripeProvider>
				<Router>
					<BillingSummary path="/" />
					<Checkout path="upgrade/:planId" onCheckout={onCheckout} />
					<PaymentHistory path="payments" />
				</Router>
			</StripeProvider>
		</>
	);
};

export default BillingRoot;
