import React, { useCallback } from "react";

import {
	ListItem,
	ListItemText,
	ListItemSecondaryAction
} from "@material-ui/core";

import { Button } from "@runly/gatsby-theme";

import FormatPrice from "./format-price";

const BillingPlan = ({ plan, onSelect, to }) => {
	if (to) {
		to = to(plan.id);
	}

	const onClick = useCallback(() => {
		if (onSelect) {
			onSelect(plan);
		}
	}, [onSelect, plan]);

	return (
		<ListItem>
			<ListItemText
				primary={plan.name}
				secondary={plan.description}
				style={{ flexBasis: "60%" }}
			/>
			<ListItemText
				primary={
					<FormatPrice
						price={plan.price}
						period="mo"
						bigVariant="body1"
						smallVariant="body2"
					/>
				}
				style={{ flexBasis: "30%" }}
			/>
			<ListItemSecondaryAction>
				<Button
					size="large"
					color={!plan.isCurrent ? "primary" : undefined}
					variant={
						plan.isCurrent
							? undefined
							: plan.isFeatured
							? "contained"
							: "outlined"
					}
					to={to}
					disabled={plan.isCurrent}
					onClick={onSelect ? onClick : undefined}
				>
					{plan.isCurrent ? "Current Plan" : `Pick ${plan.name}`}
				</Button>
			</ListItemSecondaryAction>
		</ListItem>
	);
};

export default BillingPlan;
