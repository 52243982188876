import React from "react";
import {
	Dialog,
	DialogContent,
	DialogActions,
	Button,
	Grow
} from "@material-ui/core";
import FetchButton from "../busy-button/fetch-button";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Grow ref={ref} {...props} />;
});

const DeleteItem = ({
	deleteReq,
	isOpen,
	description = "item",
	handleCancelDelete,
	handleConfirmDelete
}) => {
	return (
		<Dialog
			TransitionComponent={Transition}
			open={isOpen}
			onClose={handleCancelDelete}
		>
			<DialogContent>
				Are you sure you want to delete this {description}?
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancelDelete}>Don't delete</Button>
				<FetchButton
					req={deleteReq}
					variant="contained"
					color="primary"
					onClick={handleConfirmDelete}
				>
					Delete {description}
				</FetchButton>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteItem;
