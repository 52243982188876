import React, { useCallback } from "react";
import { partition } from "lodash";

import {
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	FormHelperText,
	ListItemText,
	Divider
} from "@material-ui/core";

const roleBlurbs = {
	Developer: <>Read access to production and full access to development</>,
	Ops: <>Create environments/clusters, run nodes</>,
	Owner: <>Full access to all features</>
};

const RoleChooser = ({ roles, value, helpText, onChange }) => {
	const handleChange = useCallback(ev => onChange?.(ev.target.value), [
		onChange
	]);

	const help = helpText ? <FormHelperText>{helpText}</FormHelperText> : null;

	const [availableRoles, unavailableRoles] = partition(
		roles,
		r => r.isAvailable
	);

	return (
		<>
			<FormControl>
				<InputLabel htmlFor="member-role">Role</InputLabel>
				<Select
					value={value}
					onChange={handleChange}
					inputProps={{
						name: "role",
						id: "member-role"
					}}
					renderValue={r => r.role}
				>
					{availableRoles?.map(r => (
						<MenuItem key={r.role} value={r}>
							<ListItemText primary={r.role} secondary={roleBlurbs[r.role]} />
						</MenuItem>
					))}
					{unavailableRoles.length ? <Divider /> : null}
					{unavailableRoles?.map(r => (
						<MenuItem key={r.role} value={r}>
							<ListItemText primary={r.role} secondary={roleBlurbs[r.role]} />
						</MenuItem>
					))}
				</Select>
				{help}
			</FormControl>
		</>
	);
};

export default RoleChooser;
