import React, { useCallback } from "react";

import { Typography, CardContent, Link } from "@material-ui/core";

import PaymentStep from "./payment";

import BillingPlans from "../../../billing/plans";

const StepContent = ({ name, setPlan, navigate }) => {
	const onChoosePlan = useCallback(
		plan => {
			setPlan(plan);
			navigate(`/dashboard/orgs/new/${PaymentStep.slug}`);
		},
		[navigate, setPlan]
	);

	return (
		<CardContent>
			<Typography variant="h5" component="h2">
				Choose a billing plan for {name}
			</Typography>
			<Typography paragraph>
				Pick the plan that best suits your organization's needs.{" "}
				<Link href="/pricing/" target="_blank">
					Compare plan features
				</Link>
				.
			</Typography>

			<BillingPlans noFree onSelect={onChoosePlan} />
		</CardContent>
	);
};

StepContent.label = "Select a Plan";
StepContent.slug = "plan";

export default StepContent;
