import React, { useState, useCallback } from "react";
import useDialog from "../use-dialog";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	Grow,
	useMediaQuery
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import tc from "tinycolor2";

const DangerConfirm = ({
	title = "Are you sure?",
	hide,
	dialogProps,
	onExited,
	formProps,
	cancelText = "Cancel",
	magicWord,
	preamble,
	magicWordHelperText = (
		<>
			Type <strong>{magicWord}</strong> to continue.
		</>
	),
	isDirty,
	textFieldProps,
	confirmButtonProps,
	actionText = "Confirm",
	children
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Dialog
			BackdropProps={{
				style: {
					backgroundColor: "rgba(128, 0, 0, 0.75)"
				}
			}}
			fullScreen={fullScreen}
			classes={useDangerDialogStyles()}
			fullWidth
			TransitionComponent={Grow}
			onExited={onExited}
			{...dialogProps}
		>
			<form
				style={{ display: "flex", flexDirection: "column", flex: "1 0 100%" }}
				{...formProps}
			>
				<DialogTitle>{title}</DialogTitle>

				<DialogContent style={{ overflowX: "visible" }}>
					{preamble ? (
						<>
							<Alert
								variant="filled"
								severity="warning"
								className={classes.preamble}
							>
								Unexpected bad things will happen if you don't read this!
							</Alert>
							{preamble}
						</>
					) : null}

					<TextField
						fullWidth
						variant="outlined"
						{...textFieldProps}
						error={isDirty && textFieldProps.value !== magicWord}
						helperText={magicWordHelperText}
					/>
					{children}
				</DialogContent>
				<DialogActions>
					<Button onClick={hide}>{cancelText}</Button>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						{...confirmButtonProps}
						classes={useDangerButtonStyles()}
					>
						{actionText}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

const useStyles = makeStyles(theme => ({
	"@keyframes heartBeat": {
		"0%": {
			transform: "scale(1)"
		},

		"14%": {
			transform: "scale(1.05)"
		},

		"28%": {
			transform: "scale(1)"
		},

		"42%": {
			transform: "scale(1.05)"
		},
		"70%": {
			transform: "scale(1)"
		}
	},
	preamble: {
		marginBottom: theme.spacing(2),
		animation: "$heartBeat 0.75s ease-in-out 1s"
	}
}));

export const useDangerButtonStyles = makeStyles(theme => {
	return {
		root: {
			[theme.breakpoints.down("xs")]: {
				display: ({ isAction }) => (isAction ? "flex" : undefined),
				width: ({ isAction }) => (isAction ? "100%" : undefined),
				padding: theme.spacing(1, 2)
			}
		},
		outlined: {
			color: theme.palette.error.main,
			borderColor: theme.palette.error.main,
			"&:hover, &:focus": {
				color: theme.palette.error.dark,
				borderColor: theme.palette.error.dark,
				backgroundColor: tc(theme.palette.error.main).setAlpha(0.2).toString()
			}
		},
		containedPrimary: {
			backgroundColor: theme.palette.error.main,
			color: theme.palette.getContrastText(theme.palette.error.main),
			"&:hover, &:focus": {
				backgroundColor: theme.palette.error.dark
			}
		}
	};
});

export const useDangerDialogStyles = makeStyles(theme => {
	return {
		paper: {
			borderWidth: 1,
			borderStyle: "solid",
			borderColor: theme.palette.error.main
		}
	};
});

export const useDangerConfirmState = ({
	magicWord,
	validate = () => true,
	onConfirm = Function.prototype()
}) => {
	const dialog = useDialog();
	const [value, setValue] = useState("");
	const [isDirty, setIsDirty] = useState(false);
	const onChange = useCallback(e => {
		setValue(e.target.value);
	}, []);
	const onBlur = useCallback(() => {
		setIsDirty(true);
	}, []);
	const onSubmit = useCallback(
		e => {
			e?.preventDefault?.();
			onConfirm();
			dialog.hide();
		},
		[dialog, onConfirm]
	);
	const onExited = useCallback(() => {
		setValue("");
		setIsDirty(false);
	}, []);

	const isValid = validate() && value === magicWord;

	return {
		magicWord,
		...dialog,
		onExited,
		formProps: { onSubmit },
		isDirty,
		textFieldProps: { value, onChange, onBlur },
		confirmButtonProps: { disabled: !isValid },
		isValid
	};
};

export default DangerConfirm;
