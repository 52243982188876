import React from "react";
import BusyButton, { BusyIconButton } from ".";

const FetchButton = ({ req = {}, ...props }) => {
	const { isFetching, error } = req;
	return <BusyButton isBusy={isFetching} isError={!!error} {...props} />;
};

export const FetchIconButton = ({ req = {}, ...props }) => {
	const { isFetching, error } = req;
	return <BusyIconButton isBusy={isFetching} isError={!!error} {...props} />;
};

export default FetchButton;
