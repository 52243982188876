import React, { useEffect } from "react";
import { IconButton, SvgIcon, Tooltip } from "@material-ui/core";

import { mdiContentCopy } from "@mdi/js";
import useClipboard from "react-use-clipboard";
import { navigate } from "@reach/router";
import { usePrevious } from "../../use-previous";

const CopyAppApiKeyButton = ({ name, value }) => {
	const [isCopied, setIsCopied] = useClipboard(value, {
		successDuration: 1000
	});

	const wasCopied = usePrevious(isCopied);

	useEffect(() => {
		if (isCopied && !wasCopied) {
			navigate(null, {
				state: {
					notification: {
						severity: "info",
						text: `${name} copied!`
					}
				}
			});
		}
	}, [isCopied, name, wasCopied]);

	return (
		<Tooltip title="Copy app API key">
			<IconButton onClick={setIsCopied}>
				<SvgIcon>
					<path d={mdiContentCopy} />
				</SvgIcon>
			</IconButton>
		</Tooltip>
	);
};

export default CopyAppApiKeyButton;
