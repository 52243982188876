import { useLazyFetch } from "react-fetch-hooks";

import { useRunlyConfig } from "@runly/ui";
import { useTimeZone } from "../api";

const useSaveNewOrg = () => {
	const { url, token: bearerToken } = useRunlyConfig();
	const timeZone = useTimeZone();

	return useLazyFetch(url && bearerToken ? `${url}/orgs/` : null, {
		method: "POST",
		bearerToken,
		body: JSON.stringify({ timeZone })
	});
};

export default useSaveNewOrg;
