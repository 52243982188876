import React from "react";

import { useFetchPayments } from "./api";

import FakeSuspense from "../../fake-suspense";
import PaymentsRoot from "./root";

const PaymentsPage = ({ org }) => {
	const paymentsRequest = useFetchPayments(org);

	return (
		<FakeSuspense req={paymentsRequest} description="Payments">
			<PaymentsRoot payments={paymentsRequest.body} />
		</FakeSuspense>
	);
};

export default PaymentsPage;
