import React from "react";

import {
	IconButton,
	List,
	ListItem,
	ListItemText,
	Paper,
	Box,
	Typography,
	Collapse,
	ListItemSecondaryAction
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import { Link, LoadingIndicator } from "@runly/gatsby-theme";

import useCheckoutState from "./state";

import PaymentMethod, { PaymentMethodChrome } from "../payment-method";

import { Flipper, Flipped } from "react-flip-toolkit";

import { format as formatDate } from "date-fns";
import FormatPrice from "../plans/format-price";

import BusyButton from "../../busy-button";
import EmptyMessage from "../../empty-message";

const CheckoutPage = ({ org, planId, onCheckout }) => {
	const {
		isEditingCard,
		toggleIsEditingCard,
		checkoutPreview,
		isFetching,
		loadError,
		isCheckingOut,
		doCheckout,
		onPaymentMethodSaved
	} = useCheckoutState(org, planId, onCheckout);

	const classes = useStyles();

	let inner;

	if (loadError) {
		inner = (
			<>
				<Flipped flipId="checkout-loading-indicator">
					<div className={classes.hiddenContent} />
				</Flipped>
				<Flipped flipId="checkout-content" transformOrigin="50% 50%">
					<div>
						<EmptyMessage>
							Problem loading checkout
							{loadError.message ? (
								<>
									:<br />
									{loadError.message}
								</>
							) : null}
							.
						</EmptyMessage>
					</div>
				</Flipped>
			</>
		);
	} else if (isFetching || !checkoutPreview) {
		inner = (
			<>
				<Flipped flipId="checkout-loading-indicator">
					<LoadingIndicator />
				</Flipped>
				<Flipped flipId="checkout-content">
					<div className={classes.hiddenContent} />
				</Flipped>
			</>
		);
	} else {
		const {
			planName,
			newTotal,
			dueToday,
			isProrated,
			nextBillingDate,
			paymentMethod
		} = checkoutPreview;

		inner = (
			<>
				<Flipped flipId="checkout-loading-indicator">
					<div className={classes.hiddenContent} />
				</Flipped>
				<Flipped flipId="checkout-content" transformOrigin="50% 50%">
					<Paper>
						<Box px={2} pt={1}>
							<Flipped flipId={`plan-name-${planName}`}>
								<Typography variant="h5" component="h1">
									Upgrade to {planName}
								</Typography>
							</Flipped>
						</Box>
						<List>
							<ListItem dense={false}>
								<ListItemText
									primary="New Total"
									secondary={<FormatPrice price={newTotal} />}
								/>
							</ListItem>
							<ListItem dense={false}>
								<ListItemText
									primary={<>Due Today {isProrated ? <>(Prorated)</> : null}</>}
									secondary={<FormatPrice price={dueToday} />}
								/>
							</ListItem>
							<ListItem dense={false}>
								<ListItemText
									primary={<>Next bill due</>}
									secondary={
										<Typography
											color="textSecondary"
											variant="h4"
											component="div"
										>
											{formatDate(nextBillingDate, "MMMM do, yyyy")}
										</Typography>
									}
								/>
							</ListItem>
							<ListItem divider />
							<ListItem dense={false}>
								<ListItemText
									primary={
										<span>
											{isEditingCard ? (
												<>Edit Payment Method</>
											) : (
												<>Payment Method</>
											)}{" "}
										</span>
									}
								/>
								<ListItemSecondaryAction>
									<Link
										onClick={toggleIsEditingCard}
										to="#change-payment-method"
									>
										{isEditingCard ? (
											<IconButton>
												<CloseIcon />
											</IconButton>
										) : (
											<>Change</>
										)}
									</Link>
								</ListItemSecondaryAction>
							</ListItem>
						</List>
						<PaymentMethodChrome isEditing={isEditingCard}>
							<PaymentMethod
								paymentMethod={paymentMethod}
								isEditing={isEditingCard}
								onCancelEdit={toggleIsEditingCard}
								onSaved={onPaymentMethodSaved}
							/>
						</PaymentMethodChrome>
						<Collapse in={!isEditingCard}>
							<BusyButton
								isBusy={isCheckingOut}
								fullWidth
								size="large"
								color="primary"
								variant="contained"
								onClick={isCheckingOut ? undefined : () => doCheckout()}
								busyChildren={<>Checking out…</>}
							>
								<>Upgrade now</>
							</BusyButton>
						</Collapse>
					</Paper>
				</Flipped>
			</>
		);
	}

	return <Flipper flipKey={isFetching}>{inner}</Flipper>;
};

const useStyles = makeStyles(theme => {
	return {
		hiddenContent: {
			opacity: 0,
			width: 0,
			height: 0,
			overflow: "hidden"
		},
		busy: {
			transition: theme.transitions.create(["opacity"]),
			opacity: 0.75
		}
	};
});

export default CheckoutPage;
