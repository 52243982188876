import React from "react";
import { CardContent, Typography } from "@material-ui/core";

import CreditCard from "./credit-card";
import CreditCardEditor from "./credit-card/editor";
import { Flipper } from "react-flip-toolkit";

const PaymentMethod = ({
	paymentMethod,
	isEditing,
	onCancelEdit,
	onSaved,
	typographyProps = { variant: "body1" }
}) => {
	const empty =
		!paymentMethod && !isEditing ? (
			<Typography {...typographyProps}>No Saved Card</Typography>
		) : null;
	const viewer =
		isEditing || !paymentMethod ? null : (
			<CreditCard {...{ typographyProps }} {...paymentMethod} />
		);
	const editor = isEditing ? (
		<CreditCardEditor onCancelEdit={onCancelEdit} onSaved={onSaved} />
	) : null;

	return (
		<Flipper flipKey={isEditing}>
			{empty}
			{viewer}
			{editor}
		</Flipper>
	);
};

export default PaymentMethod;

export const PaymentMethodChrome = ({
	isEditing,
	listenForEscape = Function.prototype,
	children
}) => {
	if (isEditing) return <div onKeyUp={listenForEscape}>{children}</div>;

	return <CardContent>{children}</CardContent>;
};
