import React, { useCallback, useMemo } from "react";

import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { useFetchOrgMembers } from "../../../member/api";
import UserProfile from "../../../user-profile";
import GrowPopper from "../../../grow-popper";

import { isString, find } from "lodash";

const RunsTriggerFilter = ({ org, queryParams, onValueChange }) => {
	const req = useFetchOrgMembers(org);

	const selectedTrigger = useMemo(() => {
		if (!req.body) return queryParams.user;
		return find(req.body, { userId: queryParams.user });
	}, [queryParams.user, req.body]);

	const onTriggerChange = useCallback(
		(ev, value) => onValueChange("user", value?.userId),
		[onValueChange]
	);

	return (
		<Autocomplete
			PopperComponent={GrowPopper}
			onChange={onTriggerChange}
			value={selectedTrigger || ""}
			options={req.body || []}
			loading={req.isFetching}
			getOptionLabel={o => (isString(o) ? o : o.name)}
			renderOption={MemberOption}
			renderInput={params => (
				<TextField
					{...params}
					label="Enqueued By"
					variant="outlined"
					fullWidth
				/>
			)}
			getOptionSelected={(option, value) =>
				option.userId && isString(value)
					? option.userId == value
					: option.userId == value.userId
			}
		/>
	);
};

const MemberOption = props => (
	<UserProfile
		variant="ListItem"
		button={false}
		component="div"
		disableGutters
		{...props}
	/>
);

export default RunsTriggerFilter;
