import React from "react";
import { CardHeader, Stepper, Step, StepLabel } from "@material-ui/core";

const Wizard = ({ steps, children, "*": path }) => {
	if (!path) path = "/";

	return (
		<>
			<CardHeader
				title={
					<Stepper activeStep={steps.findIndex(s => s.slug == path)}>
						{steps.map(({ label, slug }) => (
							<Step key={slug}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				}
			/>

			{children}
		</>
	);
};

export default Wizard;
