import React, { useState, useCallback } from "react";
import { Helmet } from "react-helmet";

import FakeSuspense from "../../../fake-suspense";

import ReadonlySettings from "./readonly";
import EditSettings from "./edit";

import { useFetchOrganization } from "../../api";

const OrgGeneralSettings = ({ org }) => {
	const req = useFetchOrganization(org);

	return (
		<>
			<Helmet>
				<title>General Settings</title>
			</Helmet>
			<FakeSuspense description="organization" req={req}>
				<OrgGeneralSettingsRoot organization={req.body} />
			</FakeSuspense>
		</>
	);
};

const OrgGeneralSettingsRoot = ({ organization }) => {
	const [isEditing, setIsEditing] = useState(false);
	const onEdit = useCallback(() => setIsEditing(true), []);
	const onCancelEdit = useCallback(() => setIsEditing(false), []);

	const [editedOrg, setEditedOrg] = useState();
	const onEdited = useCallback(o => {
		setEditedOrg(o);
		setIsEditing(false);
	}, []);

	organization = editedOrg || organization;

	if (!isEditing) {
		return <ReadonlySettings organization={organization} onEdit={onEdit} />;
	}

	return (
		<EditSettings
			organization={organization}
			onCancelEdit={onCancelEdit}
			onEdited={onEdited}
		/>
	);
};

export default OrgGeneralSettings;
