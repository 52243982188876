import { useMemo } from "react";

import { parseISO } from "date-fns";

const parseDates = item => ({
	...item,

	nextBillingDate: item.nextBillingDate
		? parseISO(item.nextBillingDate)
		: undefined,

	createdOn: item.createdOn ? parseISO(item.createdOn) : undefined
});

const useParsedDates = item =>
	useMemo(
		() =>
			item
				? Array.isArray(item)
					? item.map(parseDates)
					: parseDates(item)
				: null,
		[item]
	);

export default useParsedDates;
