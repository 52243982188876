import { useEffect, useCallback, useReducer } from "react";

export const FORM_ACTIONS = {
	EDIT: "EDIT",
	CANCEL_EDIT: "CANCEL_EDIT",
	CHANGE: "CHANGE",
	READ: "READ",
	RESET: "RESET"
};
const { EDIT, CANCEL_EDIT, CHANGE, READ, RESET } = FORM_ACTIONS;

const initialFormState = env => ({
	env,
	mode: READ
});

const formReducer = (state, action) => {
	switch (action.type) {
		case EDIT:
			return { ...state, orig: state.env, mode: EDIT };
		case CANCEL_EDIT:
			return { ...state, env: state.orig, mode: READ };
		case CHANGE: {
			let env = { ...state.env };
			env[action.name] = action.value;
			return { ...state, env };
		}
		case RESET:
			return initialFormState(action.env);
		default:
			throw new Error(`Unsupported action type: ${action.type}`);
	}
};

export const useFormState = (org, initialEnv) => {
	const [state, dispatch] = useReducer(
		formReducer,
		initialFormState(initialEnv)
	);

	useEffect(() => dispatch({ type: RESET, env: initialEnv }), [initialEnv]);

	return {
		...state,
		onEdit: useCallback(() => dispatch({ type: EDIT }), []),
		onCancelEdit: useCallback(() => dispatch({ type: CANCEL_EDIT }), []),
		onChange: (name, value) => dispatch({ type: CHANGE, name, value })
	};
};
