import React, { Fragment } from "react";

import { List, Divider } from "@material-ui/core";

import NodeItem from "./item";

import EmptyMessage from "../empty-message";

const NodeList = ({ org, nodes }) => {
	if (!nodes?.length) {
		return <EmptyMessage>No nodes to show.</EmptyMessage>;
	}

	return (
		<List>
			{nodes.map((node, i) => {
				const isLast = nodes.length === i + 1;

				return (
					<Fragment key={node.id}>
						<NodeItem {...{ org, node }} />
						{!isLast ? <Divider variant="inset" component="li" /> : null}
					</Fragment>
				);
			})}
		</List>
	);
};

export default NodeList;
