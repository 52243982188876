import semver from "semver";

const filterOptions = inputValue => options => {
	const _options = options.filter(({ version, preview }) =>
		semver.satisfies(version || preview, inputValue)
	);

	return _options;
};

export default filterOptions;
