import React from "react";
import Ansi from "ansi-to-react";
import { makeStyles } from "@material-ui/core/styles";
import { solarized } from "../../../solarized";
import { omit } from "lodash";

const _Ansi = props => {
	const _props = omit(props, "id");
	return <Ansi useClasses {..._props} />;
};

export const useAnsiStyles = makeStyles({
	"@global": {
		".ansi-black-fg": { color: solarized.base03 },
		".ansi-black-bg": { backgroundColor: solarized.base03 },
		".ansi-red-fg": { color: solarized.red },
		".ansi-red-bg": { backgroundColor: solarized.red },
		".ansi-green-fg": { color: solarized.green },
		".ansi-green-bg": { backgroundColor: solarized.green },
		".ansi-yellow-fg": { color: solarized.yellow },
		".ansi-yellow-bg": { backgroundColor: solarized.yellow },
		".ansi-blue-fg": { color: solarized.blue },
		".ansi-blue-bg": { backgroundColor: solarized.blue },
		".ansi-magenta-fg": { color: solarized.magenta },
		".ansi-magenta-bg": { backgroundColor: solarized.magenta },
		".ansi-cyan-fg": { color: solarized.cyan },
		".ansi-cyan-bg": { backgroundColor: solarized.cyan },
		".ansi-white-fg": { color: solarized.base3 },
		".ansi-white-bg": { backgroundColor: solarized.base2 },
		".ansi-bright-black-fg": { backgroundColor: solarized.base01 },
		".ansi-bright-red-fg": { color: solarized.red },
		".ansi-bright-green-fg": { color: solarized.green },
		".ansi-bright-yellow-fg": { color: solarized.yellow },
		".ansi-bright-blue-fg": { color: solarized.blue },
		".ansi-bright-magenta-fg": { color: solarized.magenta },
		".ansi-bright-cyan-fg": { color: solarized.cyan },
		".ansi-bright-white-fg": { color: solarized.base3 }
	}
});

const areEqual = (p, n) => p.id === n.id;
export default React.memo(_Ansi, areEqual);
